import React from 'react'
import './ClearControl.scss'
const ClearControl = ({clearCounter}) => {

    return (
        <div className="clear-control">
            <button className="standard-button blue" onClick={clearCounter}>Clear</button>
        </div>
    )

}
export default ClearControl