import React, { useState, useEffect, useContext, useRef } from 'react'
import { StoreContext } from "../../context/StoreContext"
import Utility from "../../objects/Utility"
import './LayersEffects.scss'
const LayersEffects = () => {
    const { state, actions } = useContext(StoreContext)
    const [activeEditList, setActiveEditList] = useState({})
    const [layerEffectSettings, setLayerEffectSettings] = useState({})
    const [multicolorButton, setMulticolorButton] = useState([])
    const [colorPickerOwned, setColorPickerOwned] = useState(false)
    const inputColorRef = useRef(null)

    const dropShadowInit = {
        xOffsetMin: -50, xOffsetMax: 50,
        yOffsetMin: -50, yOffsetMax: 50,
        blurMin: 0, blurMax: 20,
        opacityMin: 0, opacityMax: 100
    }


    useEffect(() => {
        if (colorPickerOwned) {
            let ele = document.getElementById(colorPickerOwned)
            let launchBox = ele.getBoundingClientRect()
            let x = launchBox.right + 70
            let y = launchBox.top
            // whats the color
            let layerKey = Number(colorPickerOwned.replace('dropShadow_', ''))
            let currentColor = layerEffectSettings[layerKey].dropShadow.color
            actions.colorPicker({ x, y, hexColor: currentColor })
        }
    }, [colorPickerOwned]) // eslint-disable-line react-hooks/exhaustive-deps

    const openColorGradientPicker = id => {
        setColorPickerOwned(id)
    }

    const createMulticolorButton = () => {

        function hslToHex(h, s, l) {
            l /= 100;
            const a = s * Math.min(l, 1 - l) / 100;
            const f = n => {
                const k = (n + h / 30) % 12;
                const color = l - a * Math.max(Math.min(k - 3, 9 - k, 1), -1);
                return Math.round(255 * color).toString(16).padStart(2, '0');   // convert to Hex and prefix "0" if needed
            };
            return `#${f(0)}${f(8)}${f(4)}`;
        }

        let paths = []
        let colors = []
        for (var x = 0; x < 360; x = x + 12) {
            let h = hslToHex(x, 81, 50)
            colors.push(h)
        }
        let arr = [0, 12, 24, 36, 48, 60, 72, 84, 96, 108, 120, 132, 144, 156, 168, 180, 192, 204, 216, 228, 240, 252, 264, 276, 288, 300, 312, 324, 336, 348]


        arr.forEach((a, index) => {
            let d = drawPieSlice(0, 0, 19, a, a + 12)
            paths.push({ d, color: colors[index] })
        })
        setMulticolorButton(paths)
    }

    const drawPieSlice = (centerX, centerY, radius, startAngle, endAngle) => {

        var startRad = startAngle * Math.PI / 180.0;
        let x1 = radius * Math.sin(startRad)
        let y1 = radius * Math.cos(startRad)

        var endRad = endAngle * Math.PI / 180.0;
        let x2 = radius * Math.sin(endRad)
        let y2 = radius * Math.cos(endRad)
        x1 = Utility.roundFloat(x1, 2)
        y1 = Utility.roundFloat(y1, 2)
        x2 = Utility.roundFloat(x2, 2)
        y2 = Utility.roundFloat(y2, 2)
        let d = 'M ' + centerX + ', ' + centerY + ' ' +
            'L ' + x1 + ',' + y1 + ','
            + x2 + ',' + y2 + ' Z'

        return d
    }

    useEffect(() => {
        if (state.colorPicker.x === -1) {
            setColorPickerOwned(false)
            return
        }
        if (colorPickerOwned === false) {
            return
        }
        let layerKeyAffected = Number(colorPickerOwned.replace('dropShadow_', ''))
        let _layerEffectSettings = { ...layerEffectSettings }
        _layerEffectSettings[layerKeyAffected].dropShadow.color = state.colorPicker.hexColor
        _layerEffectSettings[layerKeyAffected].dropShadow.tempColor = state.colorPicker.hexColor
        _layerEffectSettings[layerKeyAffected].dropShadow.colorIsValid = true
        setLayerEffectSettings(_layerEffectSettings)
    }, [state.colorPicker]) // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        manageEffectsSettings()
        createMulticolorButton()
    }, []) // eslint-disable-line react-hooks/exhaustive-deps

    const manageEffectsSettings = (incomingLayersEffects = null) => {
        let _layerEffectSettings = {}
        let _activeEditList = {}
        let stateLayersEffects = state.layersEffects
        if( incomingLayersEffects ) {
            stateLayersEffects = incomingLayersEffects
        }
        let xOffset = 10
        let yOffset = 10
        let blur = 0
        let color = '#000000'
        let opacity = 100
        let active = false
        state.layers.forEach(ly => {
            xOffset = 10
            yOffset = 10
            blur = 0
            color = '#000000'
            opacity = 100
            active = false
            let layerEffectsCurrent = stateLayersEffects.find(sle => sle.layerKey === ly.layerKey)
            if (layerEffectsCurrent && layerEffectsCurrent.dropShadow) {
                active = true
                xOffset = layerEffectsCurrent.dropShadow.xOffset
                yOffset = layerEffectsCurrent.dropShadow.yOffset
                color = Utility.rgbToHex(layerEffectsCurrent.dropShadow.color.r, layerEffectsCurrent.dropShadow.color.g, layerEffectsCurrent.dropShadow.color.b)
                blur = layerEffectsCurrent.dropShadow.blur
                opacity = Utility.roundFloat(layerEffectsCurrent.dropShadow.color.a * 100, 1)
            }
            if (ly.layerActive === 1) {
                _layerEffectSettings[ly.layerKey] = {
                    dropShadow: {
                        active: active,
                        xOffset: xOffset,
                        yOffset: yOffset,
                        blur: blur,
                        tempColor: color,
                        colorIsValid: true,
                        color: color,
                        opacity: opacity
                    }
                }
                _activeEditList[ly.layerKey] = {}
                _activeEditList[ly.layerKey].dropShadow = false
            }
        })

        setLayerEffectSettings(_layerEffectSettings)
        setActiveEditList(_activeEditList)
    }

    const toggleEffectControl = evt => {
        let splitted = evt.target.id.split('_')        // let input = layer.inputs.find(li => li.inputKey === requiredKey)
        if (splitted.length === 2) {
            let layerKey = Number(splitted[1])
            let _activeEditList = { ...activeEditList }
            _activeEditList[layerKey].dropShadow = !activeEditList[layerKey].dropShadow
            setActiveEditList(_activeEditList)
        }
    }

    const changeXoffset = evt => {
        let layerKey = Number(evt.target.id.replace('dropShadowXoffset_', ''))
        let _layerEffectSettings = { ...layerEffectSettings }
        _layerEffectSettings[layerKey].dropShadow.xOffset = Number(evt.target.value)
        setLayerEffectSettings(_layerEffectSettings)
    }
    const changeYoffset = evt => {
        let layerKey = Number(evt.target.id.replace('dropShadowYoffset_', ''))
        let _layerEffectSettings = { ...layerEffectSettings }
        _layerEffectSettings[layerKey].dropShadow.yOffset = Number(evt.target.value)
        setLayerEffectSettings(_layerEffectSettings)
    }
    const changeBlur = evt => {
        let layerKey = Number(evt.target.id.replace('dropShadowBlur_', ''))
        let _layerEffectSettings = { ...layerEffectSettings }
        _layerEffectSettings[layerKey].dropShadow.blur = Number(evt.target.value)
        setLayerEffectSettings(_layerEffectSettings)
    }
    const changeOpacity = evt => {
        let layerKey = Number(evt.target.id.replace('dropShadowOpacity_', ''))
        let _layerEffectSettings = { ...layerEffectSettings }
        _layerEffectSettings[layerKey].dropShadow.opacity = Number(evt.target.value)
        setLayerEffectSettings(_layerEffectSettings)
    }
    const changeColorText = evt => {
        let layerKey = Number(evt.target.id.replace('inputTextShadowColor_', ''))
        const selectionStart = document.getElementById('inputTextShadowColor_' + layerKey).selectionStart;
        const selectionEnd = document.getElementById('inputTextShadowColor_' + layerKey).selectionEnd;

        let _layerEffectSettings = { ...layerEffectSettings }
        _layerEffectSettings[layerKey].dropShadow.tempColor = evt.target.value
        _layerEffectSettings[layerKey].dropShadow.colorSelectionStart = selectionStart
        _layerEffectSettings[layerKey].dropShadow.colorSelectionEnd = selectionEnd

        if (Utility.validateHexColor(evt.target.value)) {
            _layerEffectSettings[layerKey].dropShadow.colorIsValid = true
            _layerEffectSettings[layerKey].dropShadow.color = evt.target.value
        }
        else {
            _layerEffectSettings[layerKey].dropShadow.colorIsValid = false
        }
        setLayerEffectSettings(_layerEffectSettings)

    }

    useEffect(() => {
        let _layersEffects = [...state.layersEffects]
        _layersEffects = [] // I'll have to change this later when I add other effects, to preserve settings for other effects.

        for (const [layerKeyString] of Object.entries(layerEffectSettings)) {
            let layerKey = Number(layerKeyString)
            if (layerEffectSettings[layerKey].dropShadow.active) {
                let dropShadow = layerEffectSettings[layerKey].dropShadow
                let stateObj = {
                    xOffset: dropShadow.xOffset,
                    yOffset: dropShadow.yOffset,
                    blur: dropShadow.blur,
                    color: Utility.hexOpacityToRgba(dropShadow.color, dropShadow.opacity)
                }
                let effectsEntryIndex = _layersEffects.findIndex(le => le.layerKey === layerKey)
                if (effectsEntryIndex === -1) {
                    _layersEffects.push({ layerKey, dropShadow: stateObj })
                }
                else {
                    _layersEffects[effectsEntryIndex] = { layerKey, dropShadow: stateObj }
                }
            }
            else {
                //drop shadow has been changed to off, or else it was never set for this layerKey.
                let layerKeyEntry = _layersEffects.findIndex(le => le.layerKey === layerKey)
                if (layerKeyEntry > -1) {
                    _layersEffects = _layersEffects.splice(layerKeyEntry, 1)
                }
            }
        }
        actions.layersEffects(_layersEffects)
    }, [layerEffectSettings]) // eslint-disable-line react-hooks/exhaustive-deps

    const onChangeShadowActive = evt => {
        ;
    }

    const onClickShadowActive = evt => {
        let splitted = evt.target.id.split('_')
        let layerKey = null
        if (splitted.length === 3) {
            layerKey = Number(splitted[1])
        }
        if (layerKey) {
            let _layerEffectSettings = { ...layerEffectSettings }
            _layerEffectSettings[layerKey].dropShadow.active = evt.target.value === 'on' ? true : false
            setLayerEffectSettings(_layerEffectSettings)
        }
    }

    return (
        <div className="layers-effects" style={{ pointerEvents: state.overlay ? "none" : "" }}>
            <div className="instructions">
                select effect to apply to layer
            </div>
            <table>
                <tbody>{state.layers.filter(ly => ly.layerActive === 1 && ly.layerKey > 1).map((ly, index) => {
                    return (<tr key={index} className="layer">
                        <td className={activeEditList[ly.layerKey] && activeEditList[ly.layerKey].dropShadow ? 'label editing' : 'label'}>{ly.layerName}

                            {Utility.emptyCheck(layerEffectSettings) === false && layerEffectSettings[ly.layerKey] ?
                                <div className="effects-container">
                                    {activeEditList[ly.layerKey] && activeEditList[ly.layerKey].dropShadow ?
                                        <span id={`dropShadow_${ly.layerKey}`} className="click-drop activated" onClick={toggleEffectControl}>
                                            shadow &#x25B2;
                                        </span>
                                        :
                                        <span id={`dropShadow_${ly.layerKey}`} 
                                        
                                        className={layerEffectSettings[ly.layerKey].dropShadow.active ? 'click-drop on' : 'click-drop' } 
                                        
                                        
                                        onClick={toggleEffectControl}>
                                            shadow &#x25BC;
                                        </span>
                                    }

                                    <div className={activeEditList[ly.layerKey] && activeEditList[ly.layerKey].dropShadow ? 'effect-control' : 'display-none'}>
                                        <div className={ layerEffectSettings[ly.layerKey].dropShadow.active ? 'effect-name' : 'effect-name off'}>shadow
                                            <span className="activate-radiogroup">
                                                <input
                                                    type="radio"
                                                    name={`dropShadowRadio_${ly.layerKey}`}
                                                    value="on"
                                                    id={`radioDropShadowActive_${ly.layerKey}_on`}
                                                    onChange={onChangeShadowActive}
                                                    onClick={onClickShadowActive}
                                                    checked={ layerEffectSettings[ly.layerKey].dropShadow.active}
                                                />
                                                <label htmlFor={`radioDropShadowActive_${ly.layerKey}_on`}>on</label>

                                                <input
                                                    type="radio"
                                                    name={`dropShadowRadio_${ly.layerKey}`}
                                                    value="off"
                                                    id={`radioDropShadowActive_${ly.layerKey}_off`}
                                                    onChange={onChangeShadowActive}
                                                    onClick={onClickShadowActive}
                                                    checked={!( layerEffectSettings[ly.layerKey].dropShadow.active)}
                                                />
                                                <label htmlFor={`radioDropShadowActive_${ly.layerKey}_off`}>off</label>
                                            </span>
                                        </div>
                                        <div className={ layerEffectSettings[ly.layerKey].dropShadow.active ? 'effect-settings' : 'effect-settings disabled'}>
                                            <div>x-offset <input type="range" min={dropShadowInit.xOffsetMin} max={dropShadowInit.xOffsetMax} className="effect-slider" id={`dropShadowXoffset_${ly.layerKey}`}
                                                step="0.1"
                                                value={layerEffectSettings[ly.layerKey].dropShadow.xOffset}
                                                onChange={changeXoffset}
                                            /><span className="range-label">{ layerEffectSettings[ly.layerKey].dropShadow.xOffset}</span>
                                            </div>
                                            <div>
                                                y-offset <input type="range" min={dropShadowInit.yOffsetMin} max={dropShadowInit.yOffsetMax} className="effect-slider" id={`dropShadowYoffset_${ly.layerKey}`}
                                                    step="0.1"
                                                    value={layerEffectSettings[ly.layerKey].dropShadow.yOffset}
                                                    onChange={changeYoffset}
                                                /><span className="range-label">{ layerEffectSettings[ly.layerKey].dropShadow.yOffset}</span>
                                            </div>
                                            <div>
                                                blur <input type="range" min={dropShadowInit.blurMin} max={dropShadowInit.blurMax} className="effect-slider" id={`dropShadowBlur_${ly.layerKey}`}
                                                    step="0.1"
                                                    value={ layerEffectSettings[ly.layerKey].dropShadow.blur}
                                                    onChange={changeBlur}
                                                /><span className="range-label">{ layerEffectSettings[ly.layerKey].dropShadow.blur}</span>
                                            </div>
                                            <div>
                                                opacity <input type="range" min={0} max={100} className="effect-slider" id={`dropShadowOpacity_${ly.layerKey}`}
                                                    step="0.1"
                                                    value={ layerEffectSettings[ly.layerKey].dropShadow.opacity}
                                                    onChange={changeOpacity}
                                                /><span className="range-label">{ layerEffectSettings[ly.layerKey].dropShadow.opacity}</span>
                                            </div>

                                            <div className="color-pick-container">
                                                <div className="color-pick">shadow color: <input
                                                    ref={inputColorRef}
                                                    id={`inputTextShadowColor_${ly.layerKey}`}
                                                    className={ layerEffectSettings[ly.layerKey].dropShadow.colorIsValid ? '' : 'invalid'}
                                                    type="text"
                                                    spellCheck="false"
                                                    value={ layerEffectSettings[ly.layerKey].dropShadow.tempColor}
                                                    onChange={changeColorText}
                                                />
                                                    <div className="color-sample" style={{ "background": layerEffectSettings[ly.layerKey].dropShadow.color }} onClick={() => openColorGradientPicker('dropShadow_' + ly.layerKey)} />
                                                    <div id={`dropShadow_${ly.layerKey}`} className="multicolor-button" onClick={() => openColorGradientPicker('dropShadow_' + ly.layerKey)}>
                                                        <svg id="colorPickerButtonDropShadow" width="28" height="28" viewBox="-14, -14, 28, 28">

                                                            {multicolorButton.map((dc, index) => {
                                                                return <path key={index} d={`${dc.d}`} fill={`${dc.color}`} />
                                                            })}
                                                            <circle cx="0" cy="0" r="14" stroke="white" strokeWidth="2px" fill="none" />
                                                            <circle cx="0" cy="0" r="13" stroke="white" strokeWidth="2px" fill="none" opacity="0.5" />
                                                            <circle cx="0" cy="0" r="17" stroke="#141450" strokeWidth="6px" fill="none" opacity="1" />

                                                        </svg></div>

                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                : null}

                        </td>
                    </tr>)
                })}</tbody>
            </table>
        </div >
    )
}
export default LayersEffects

/*
                            <td id={`control_${ly.layerKey}`} className={activeEditList.includes(ly.layerKey) ? 'display-none' : 'show-button'}>
                                {checkIfCanBeVisible(ly.layerKey) ?
                                    <button id={`dropShadow_${ly.layerKey}`} className="standard-button blue" onClick={dropShadow}>drop shadow</button>
                                    :
                                    <button className="standard-button disabled">drop shadow</button>
                                }
                            </td>
                            <td id={`settings_${ly.layerKey}`} className={activeEditList.includes(ly.layerKey) ? 'settings' : 'display-none'}>
                                settings for drop shadow
                                filter: drop-shadow( 3px 3px 2px rgba(0, 0, 0, .7));
                                x offset 
                                { Utility.emptyCheck(layerEffectSettings) === false ?
                                <input type="range" min={0} max={100} className="effect-slider" id={`xOffset_${ly.layerKey}`}
                                      step="0.1"
                                      value={layerEffectSettings[ly.layerKey].xOffset}
                                      onChange={changeXoffset}
                                /> : null}

                                </td>
                                */