import React, { useContext } from 'react'
import { StoreContext } from "../../context/StoreContext"
import './LayersDisplay.scss'
const LayersDisplay = () => {
    const { state, actions } = useContext(StoreContext)

    const layerDisplayChange = evt => {
        const layer = state.layers.find(ly => ly.layerKey === parseInt(evt.target.id))
        if (layer) {
            let updatedLayer = { ...layer, layerHidden: evt.target.checked ? 0 : 1 }
            actions.layerUpdate(updatedLayer)
        }
    }

    const baseLayerName = layerKey => {
        const layer = state.layers.find(ly => ly.layerKey === parseInt(layerKey))
        if (layer) {
            let baseName = layer.layerName
            if (layer.hasOwnProperty('parentLayerKey')) {
                let parentLayerKey = layer.parentLayerKey
                if (parentLayerKey > -1) {
                    let parentLayer = state.layers.find(ly => ly.layerKey === parentLayerKey)
                    if (parentLayer) {
                        baseName = parentLayer.layerName
                    }
                }
            }
            return baseName
        }
        return null
    }

    const customLayerSvgHasKeys = layerKey => {
        const layer = state.layers.find(ly => ly.layerKey === parseInt(layerKey))
        if (layer) {
            let requiredKey = layer.layerActiveRequiredInputKey
            let input = layer.inputs.find(li => li.inputKey === requiredKey)
            if (input && input.list && Array.isArray(input.list)) {
                if (input.list.length > 0) {
                    return true
                }
            }
        }
        return false
    }

    const checkIfCanBeVisible = layerKey => {
        const baseName = baseLayerName(layerKey)
        if (baseName === 'custom svgs' || baseName === 'custom images') {
            let hasKeys = customLayerSvgHasKeys(layerKey)
            if (hasKeys) {
                return true
            }
            return false
        }
        return true
    }

    return (
        <div className="layers-display" style={{ pointerEvents: state.overlay ? "none" : "" }}>
            <div className="instructions">
                check box to hide or show layers in menu
            </div>
            <table>
                <tbody>
                    <tr className="header">
                        <td>display</td>
                        <td>layer name</td>
                    </tr>
                    {state.layers.map((ly, index) => {
                        return (<tr key={index} className="layer">
                            <td className="control">
                                {checkIfCanBeVisible(ly.layerKey) ?
                                    <input id={ly.layerKey} type="checkbox"
                                        onChange={layerDisplayChange} checked={ly.layerHidden === 1 ? false : true} />
                                    :
                                    <input type="checkbox" disabled="disabled" />
                                }
                            </td>
                            <td className="label">{ly.layerName}</td>
                        </tr>)
                    })}
                </tbody>
            </table>
        </div>
    )
}
export default LayersDisplay