import React, { useState, useEffect, useRef, useContext } from 'react'
import { useSignal } from "@preact/signals-react"
import LayerMenuItem from '../LayerMenuItem/LayerMenuItem'
import { StoreContext } from "../../context/StoreContext"
import './LayersMenu.scss'
const LayersMenu = ({ dragSubscribe }) => {
    const { state, actions } = useContext(StoreContext)
    const signalLayerEditing = useSignal(null)
    const layerItemRefs = useRef([]);
    const signalDragOver = useSignal(null)
    const signalBeingDraggedId = useSignal(null)
    const signalDropBboxes = useSignal([])
    const signalDraggingMenuLayer = useSignal(false)
    const signalOrderedLayersVersion = useSignal([]) // might need to put this back to useState format. The signal doesnt seem to be triggering in the html template.
    const [optionsOpenLayerKey, setOptionsOpenLayerKey] = useState(false)
    const [changedLayerOrders, setChangedLayerOrders] = useState(false)

    useEffect(() => {
        if (window.dragForFirefoxConfigured) {
            return
        }
        if (/Firefox\/\d+[\d\.]*/.test(navigator.userAgent) // eslint-disable-line no-useless-escape
            && typeof window.DragEvent === 'function'
            && typeof window.addEventListener === 'function') (function () {
                // patch for Firefox bug https://bugzilla.mozilla.org/show_bug.cgi?id=505521
                var cx, cy, px, py, ox, oy, sx, sy, lx, ly;
                function update(e) {
                    cx = e.clientX; cy = e.clientY;
                    px = e.pageX; py = e.pageY;
                    ox = e.offsetX; oy = e.offsetY;
                    sx = e.screenX; sy = e.screenY;
                    lx = e.layerX; ly = e.layerY;
                }
                function assign(e) {
                    e._ffix_cx = cx; e._ffix_cy = cy;
                    e._ffix_px = px; e._ffix_py = py;
                    e._ffix_ox = ox; e._ffix_oy = oy;
                    e._ffix_sx = sx; e._ffix_sy = sy;
                    e._ffix_lx = lx; e._ffix_ly = ly;
                }
                window.addEventListener('mousemove', update, true);
                window.addEventListener('dragover', update, true);
                // bug #505521 identifies these three listeners as problematic:
                // (although tests show 'dragstart' seems to work now, keep to be compatible)
                window.addEventListener('dragstart', assign, true);
                window.addEventListener('drag', assign, true);
                window.addEventListener('dragend', assign, true);

                var me = Object.getOwnPropertyDescriptors(window.MouseEvent.prototype),
                    ue = Object.getOwnPropertyDescriptors(window.UIEvent.prototype);
                function getter(prop, repl) {
                    return function () { return (me[prop] && me[prop].get.call(this)) || Number(this[repl]) || 0 };
                }
                function layerGetter(prop, repl) {
                    return function () { return this.type === 'dragover' && ue[prop] ? ue[prop].get.call(this) : (Number(this[repl]) || 0) };
                }
                Object.defineProperties(window.DragEvent.prototype, {
                    clientX: { get: getter('clientX', '_ffix_cx') },
                    clientY: { get: getter('clientY', '_ffix_cy') },
                    pageX: { get: getter('pageX', '_ffix_px') },
                    pageY: { get: getter('pageY', '_ffix_py') },
                    offsetX: { get: getter('offsetX', '_ffix_ox') },
                    offsetY: { get: getter('offsetY', '_ffix_oy') },
                    screenX: { get: getter('screenX', '_ffix_sx') },
                    screenY: { get: getter('screenY', '_ffix_sy') },
                    x: { get: getter('x', '_ffix_cx') },
                    y: { get: getter('y', '_ffix_cy') },
                    layerX: { get: layerGetter('layerX', '_ffix_lx') },
                    layerY: { get: layerGetter('layerY', '_ffix_ly') }
                });
                window.dragForFirefoxConfigured = true
            })();
    }, [])

    const clickedOnLayerMenuItem = layerKey => {
        signalLayerEditing.value = layerKey
    }

    const registerRef = ref => {
        if (ref) {
            let id = ref.id
            if (id) {
                if (layerItemRefs.current.indexOf(ref) === -1) {
                    layerItemRefs.current.push(ref)
                    connectDragger(ref)
                    return layerItemRefs.current[layerItemRefs.current.length]
                }
            }
        }
    }

    const setupDropBboxes = () => {
        let bbox = null
        let ele = null
        let _dropBboxes = JSON.parse(JSON.stringify(signalDropBboxes.value))
        layerItemRefs.current.forEach(ref => {
            if (ref.id) {
                ele = document.getElementById(ref.id)
                if (ele) {
                    bbox = ele.getBoundingClientRect()
                    if (bbox) {
                        let drop = _dropBboxes.find(bp => bp.id === ref.id)
                        if (drop) {
                            drop.bbox = bbox
                        }
                        else {
                            _dropBboxes.push({ id: ref.id, bbox: bbox })
                        }
                    }
                }
            }
        })
        signalDropBboxes.value = _dropBboxes
    }

    const rectOverRect = (dragbox, dropbox) => {
        return (pointInsideBox({ x: dragbox.x, y: dragbox.y }, dropbox) ||
            pointInsideBox({ x: dragbox.x + dragbox.width, y: dragbox.y }, dropbox) ||
            pointInsideBox({ x: dragbox.x + dragbox.width, y: dragbox.y + dragbox.height }, dropbox) ||
            pointInsideBox({ x: dragbox.x, y: dragbox.y + dragbox.height }, dropbox)

            // ||

            // pointInsideBox({ x: dragbox.x, y: dragbox.y + (dragbox.height / 2) }, dropbox) ||
            // pointInsideBox({ x: dragbox.x + (dragbox.width / 2), y: dragbox.y }, dropbox) ||
            // pointInsideBox({ x: dragbox.x + dragbox.width, y: dragbox.y + (dragbox.height / 2) }, dropbox) ||
            // pointInsideBox({ x: dragbox.x + (dragbox.width / 2), y: dragbox.y + dragbox.height }, dropbox)
        )
    }

    const pointInsideBox = (p, box) => {
        return (p.x >= box.x && p.x <= (box.x + box.width) &&
            p.y >= box.y && p.y <= (box.y + box.height))
    }

    const checkHoverOverDropArea = dragbox => {
        let i = 0
        let dropbox = null
        for (i; i < signalDropBboxes.value.length; i++) {
            dropbox = signalDropBboxes.value[i]
            if (dropbox && dropbox.id && dropbox.bbox) {
                if (rectOverRect(dragbox, dropbox.bbox)) {
                    return dropbox.id
                }
            }
        }

        return null
    }

    const changeOrder = (fromLayerId, toLayerId) => {
        if (fromLayerId && toLayerId) {
            let droppedLayer = signalOrderedLayersVersion.value.find(ly => ly.layerKey === toLayerId)
            let draggedLayer = signalOrderedLayersVersion.value.find(ly => ly.layerKey === fromLayerId)
            if (droppedLayer && draggedLayer) {
                draggedLayer.layerOrder = droppedLayer.layerOrder + 0.5
            }

            //sort and assign proper order numbers
            let newLayersOrder = signalOrderedLayersVersion.value.sort((a, b) => a.layerOrder - b.layerOrder)
            newLayersOrder.forEach((ly, index) => {
                ly.layerOrder = index
            })
            setChangedLayerOrders(true)
            signalOrderedLayersVersion.value = [...newLayersOrder]
            //assignZindexes()
            actions.layers([...newLayersOrder])
        }
    }

    useEffect(() => {
        let _stateLayers = [...state.layers]
        if (_stateLayers.length > 0) {
            _stateLayers.sort((a, b) => a.layerOrder - b.layerOrder)
            signalOrderedLayersVersion.value = _stateLayers
        }
        if (changedLayerOrders) {
            setChangedLayerOrders(false)
            setTimeout(() => {
                actions.activeLayerValuesReset({ ...state.activeLayerValues })
            }, 100)
        }
    }, [state.layers]) // eslint-disable-line react-hooks/exhaustive-deps

    const connectDragger = ref => {
        let startX = -1
        let startY = -1
        let grabOffsetX = -1
        let grabOffsetY = -1
        let dragme = ref
        let dragmeBbox = null
        let lastDropId = -1
        let lastY = -1

        dragme.addEventListener("dragstart", (event) => {

            event.dataTransfer.clearData();
            event.dataTransfer.setData('text/plain', event.target.id)

            signalDraggingMenuLayer.value = true
            lastY = -1
            let id = dragme.id.replace('layer_menu_', '')
            id = Number(id)
            startX = -1
            startY = -1
            grabOffsetX = -1
            grabOffsetY = -1
            let dragmeEle = document.getElementById(dragme.id)
            dragmeBbox = dragmeEle.getBoundingClientRect()
            if (dragmeBbox && id) {
                signalBeingDraggedId.value = Number(id)

                startX = event.clientX || event.pageX;
                startY = event.clientY || event.pageY;

                grabOffsetX = startX - dragmeBbox.x
                grabOffsetY = startY - dragmeBbox.y
            }

            setupDropBboxes()
        });




        dragme.addEventListener("drag", (event) => {

            // drag grab point will be some point in the dragger div

            let x = event.clientX || event.pageX;
            let y = event.clientY || event.pageY;

            if (x === 0 && y === 0) {
                return
            }

            // wait till they slow down, otherwise it triggers "drop here" boxes too fast.
            if (lastY !== y) {
                if (Math.abs(lastY - y) > 20) {
                    lastY = y
                    return
                }
            }
            lastY = y
            let checkBox = {
                x: x - grabOffsetX,
                y: y - grabOffsetY,
                width: dragmeBbox.width,
                height: dragmeBbox.height
            }
            let dropId = checkHoverOverDropArea(checkBox)
            if (dropId === null) {
                return
            }
            if (dropId === lastDropId) {
                return
            }
            lastDropId = dropId
            if (dropId) {
                let overId = dropId.replace('layer_menu_', '')
                overId = Number(overId)
                if (overId) {
                    // we dont want drop to activate if it would result in no change inorder (ie the layer directly above the dragged layer).
                    let draggingLayer = signalOrderedLayersVersion.value.find(ly => ly.layerKey === signalBeingDraggedId.value)
                    let overLayer = signalOrderedLayersVersion.value.find(ly => ly.layerKey === overId)
                    if (draggingLayer.layerOrder - overLayer.layerOrder === 1) {
                        // the minus one is because of the algorithym that adds 0.5 for preparation for ordering. A little counter-intuitive.
                        signalDragOver.value = null
                    }
                    else {
                        signalDragOver.value = overId
                    }
                }
            }
            else {
                signalDragOver.value = null
            }
        });

        dragme.addEventListener("dragend", (event) => {
            signalDraggingMenuLayer.value = false
            if (signalBeingDraggedId.value && signalDragOver.value) {
                changeOrder(signalBeingDraggedId.value, signalDragOver.value)
            }
            signalBeingDraggedId.value = null
            signalDragOver.value = null
            setupDropBboxes()

        });
    }

    const optionsOpen = openLayerKey => {
        setOptionsOpenLayerKey(openLayerKey)
    }

    return (
        <div className="layers-menu" style={{ pointerEvents: state.overlay ? "none" : "" }}>
            {signalOrderedLayersVersion.value.filter(lyf => lyf.layerHidden === 0)
                .map(ly => <div
                    className={
                        signalBeingDraggedId.value === ly.layerKey ? 'layer-dragging' : (signalDragOver.value === ly.layerKey ? 'drop-active' : '')
                    }
                    key={ly.layerKey} >
                    <LayerMenuItem layer={ly}
                        registerRef={registerRef}
                        editingLayer={signalLayerEditing.value}
                        dragSubscribe={dragSubscribe}
                        clickRegister={clickedOnLayerMenuItem}
                        draggingMenuLayer={signalDraggingMenuLayer.value}
                        optionsOpen={optionsOpen}
                        activeOptionsOpen={optionsOpenLayerKey}
                    />

                </div>)}
        </div>
    )
}
export default LayersMenu