import React, { useState, useEffect, useContext, useRef } from 'react'
import Snap from 'snapsvg-cjs'
import { useSignal } from "@preact/signals-react"
import JSZip from 'jszip'
import { saveAs } from 'file-saver'
import { StoreContext } from "../../context/StoreContext"
import Utility from "../../objects/Utility"
import './ViewApp.scss'

const ViewApp = () => {
    const { state, actions } = useContext(StoreContext)
    const [resetDatabaseConfirm, setResetDatabaseConfirm] = useState(false)
    const resetDatabaseTimestamp = useSignal(false)
    const [deletingDatabase, setDeletingDatabase] = useState(false)
    const [resetDatabaseErrorMessage, setResetDatabaseErrorMessage] = useState('')
    const [areYouSureTimer, setAreYouSureTimer] = useState(null)
    const [exportDatabaseFilename, setExportDatabaseFilename] = useState('')
    const [showExportDatabaseForm, setShowExportDatabaseForm] = useState(false)
    const exportDatabaseFilenameRef = useRef(null)
    const [loadWorkspaceFileInvalid, setLoadWorkspaceFileInvalid] = useState('')
    const [loadWorkspaceErrors, setLoadWorkspaceErrors] = useState([])
    const [databaseToRestore, setDatabaseToRestore] = useState(null)
    const [paperTarget, setPaperTarget] = useState(null)

    useEffect(() => {
        return () => {
            if (areYouSureTimer) {
                clearTimeout(areYouSureTimer)
            }
        };
    }, []) // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        const _paperTarget = Snap("#svg_target");
        setPaperTarget(_paperTarget)
        return () => {
            if (paperTarget) {
                paperTarget.clear()
            }
        };
    }, []) // eslint-disable-line react-hooks/exhaustive-deps

    const clickResetDatabase = () => {
        if (deletingDatabase) {
            return
        }
        if (resetDatabaseConfirm) {
            let timestamp = Math.floor(Date.now() / 100)
            if (resetDatabaseTimestamp.value) {
                let diff = timestamp - resetDatabaseTimestamp.value
                if (diff > 3) { // 300 ms
                    resetDatabaseTimestamp.value = false
                    setDeletingDatabase(true)
                }
            }
            else {
                setResetDatabaseConfirm(false)
            }
        }
        else {
            setResetDatabaseConfirm(true)
            resetDatabaseTimestamp.value = Math.floor(Date.now() / 100)
            if (areYouSureTimer === null) {
                let sureTimer = setTimeout(() => {
                    resetDatabaseTimestamp.value = null
                    setDeletingDatabase(false)
                    setResetDatabaseConfirm(false)
                    setAreYouSureTimer(null)
                }, 5000)
                setAreYouSureTimer(sureTimer)
            }
        }
    }

    useEffect(() => {
        if (deletingDatabase) {
            // check to see if server is reachable
            fetch('https://snapcounter.app/wargalleyservice/', { mode: 'no-cors' }).then(r => {
                actions.restoreDatabase(true)
            })
                .catch(e => {
                    setResetDatabaseErrorMessage('The server is not reachable currently. Cannot reset database at this time.')
                });

        }
    }, [deletingDatabase]) // eslint-disable-line react-hooks/exhaustive-deps

    const clickExportDatabase = () => {
        setShowExportDatabaseForm(true)
    }

    useEffect(() => {
        if (showExportDatabaseForm && exportDatabaseFilenameRef.current) {
            exportDatabaseFilenameRef.current.focus()
        }
    }, [showExportDatabaseForm])

    const packageWorkspace = async () => {
        // need to get font data from dexie cause state doesnt carry it (I think it may eat up too much memory storing gigs of font data.
        // We just retrieve the font data from the dexie store when we need it for particular fonts.
        let counterSideActive = await state.dexie['counterSideActive'].toArray()
        let fonts = await state.dexie['fonts'].toArray()
        let svgs = await state.dexie['svgs'].toArray()
        let activeLayerValues = await state.dexie['activeLayerValues'].toArray()
        let layers = await state.dexie['layers'].toArray()
        let layersOpen = await state.dexie['layersOpen'].toArray()
        let savedCounters = await state.dexie['savedCounters'].toArray()
        let savedSheets = await state.dexie['savedSheets'].toArray()
        let sheetSettings = await state.dexie['sheetSettings'].toArray()
        let slots = await state.dexie['slots'].toArray()
        let layersEffects = await state.dexie['layersEffects'].toArray()
        return {
            counterSideActive,
            activeLayerValues,
            fonts,
            layers,
            layersOpen,
            savedCounters,
            savedSheets,
            sheetSettings,
            slots,
            svgs,
            layersEffects
        }
    }

    const saveFile = async (packagedWorkspace) => {
        let fileObj = {
            fileName: exportDatabaseFilename,
            app: 'snapCounter',
            type: 'workspace',
            version: 3,
            dateSaved: Utility.currentDate(),
            workspace: packagedWorkspace
        }

        var zip = new JSZip();
        zip.file(exportDatabaseFilename + ".json", JSON.stringify(fileObj));
        zip.generateAsync({ type: "blob", compression: "DEFLATE" })
            .then(function (content) {
                saveAs(content, exportDatabaseFilename + '.zip');
                setExportDatabaseFilename('')
                setShowExportDatabaseForm(false)
            }).catch(function () {
                // setSaveResult({ state: false, message: 'Could not save file.' })
            })

    }

    const exportDatabase = async () => {
        if (exportDatabaseFilename.length > 1 && exportDatabaseFilename.length < 256) {
            let workspace = await packageWorkspace()
            saveFile(workspace)
        }
    }

    const onChangeExportDatabaseFilename = evt => {
        if (evt.target.value.length < 256) {
            setExportDatabaseFilename(evt.target.value)
        }
    }

    const appendDateToText = () => {
        let datetext = Utility.currentDateAndTime(false)
        let newName = exportDatabaseFilename + datetext
        if (newName.length < 256) {
            setExportDatabaseFilename(newName)
        }
    }


    const onChangeLoadFromFile = evt => {
        let file = evt.target.files[0]
        if (!file) {
            return // happens if the user brings up the file selection dialog then closes it.
        }
        JSZip.loadAsync(file).then((zip) => {
            Object.keys(zip.files).forEach((filename) => {
                zip.files[filename].async('string').then(async (fileData) => {
                    let data = null
                    try {
                        data = JSON.parse(fileData)
                    }
                    catch (e) {
                        setLoadWorkspaceFileInvalid('the file could not be loaded. Is it a zip file?')
                        // setRestoreResult({ state: false, message: 'error loading file' })
                        return
                    }
                    if (data.app === undefined || !data.app.startsWith('snapCounter')) {
                        //setRestoreResult({ state: false, message: 'this file does not appear to be a snapCounter file' })
                        setLoadWorkspaceFileInvalid('this file is not SnapCounter database file.')
                        return
                    }
                    if (!data.type || data.type !== 'workspace') {
                        setLoadWorkspaceFileInvalid('this file is not a database workspace file.')
                        return
                    }
                    if (data.app === 'snapCounter' && data.type === 'workspace' && data.version === 3) {
                        console.log('version 3')
                        loadDatabaseBackup(data.workspace)
                    }
                    else {
                        if (data.app === 'snapCounter' && data.type === 'workspace') {
                            let fixedUpWorkspace = ver2toVer3workspace(JSON.parse(JSON.stringify(data.workspace)))

                            console.log('fixedUpWorkspace:', fixedUpWorkspace)
                            loadDatabaseBackup(fixedUpWorkspace)
                        }
                        else {
                            console.error('unknown file format')
                            setLoadWorkspaceFileInvalid('this file is not a database workspace file.')
                        }
                    }
                })
            })
        })
    }

    const ver2toVer3workspace = workspace => {
        console.log('ver2toVer3workspace got workspace:', workspace)

        // we are only concerned in duplicating the incoming states.
        // layersEffects need to be added in as empty array.
        // sheetSettings.side needs to be added.
        // activeCounterSide needs to be set up.
        // layersOpen needs to be set to empty array.
        // don't forget the opacity for the line layer. It may or may not be there.
        // Slots need to be converted to the package format for any active slots.
        // SavedCounters and SavedSheets need to be redone.

        /////////////////////////////////////////////////////////////////////////////////////////
        // incoming custom svgs need to be adjusted
        /////////////////////////////////////////////////////////////////////////////////////////
        let standardSvgs = state.svgs.filter( svg => svg.svgKey <= 2267 ) // national flags were added
        let incomingNewSvgs = workspace.svgs.filter( svg => svg.svgKey > 2267 )
        // dont mess with the standard svgs in state. They were inserted differently than in v2.

        // adjust the code to the new format
        incomingNewSvgs.forEach(nsvg => {
            nsvg.svgCode = nsvg.svgCode.replace(/\bwidth="(\d+(\.\d*)?|\.\d+)"/, 'width="100%"');
            nsvg.svgCode = nsvg.svgCode.replace(/\bheight="(\d+(\.\d*)?|\.\d+)"/, 'height="100%"');
        })

        // let newSvgKey = 3000
        // let changeIncomingSvgKeysFromTo = []
        // incomingNewSvgs.forEach( nsvg => {
        //     changeIncomingSvgKeysFromTo.push({from: nsvg.svgKey, to: newSvgKey})
        //     newSvgKey++
        // })
        // console.log('incomingNewSvgs:', incomingNewSvgs)
        let incomingSvgs = [...standardSvgs, ...incomingNewSvgs]
        incomingSvgs = JSON.parse(JSON.stringify(incomingSvgs))

        let svgs = JSON.parse(JSON.stringify(state.svgs))
        svgs.forEach(svg => {
            if (incomingSvgs.find(isvg => isvg.svgKey === svg.svgKey)) {
                svg.delete = true
            }
        })
        svgs = svgs.filter(svg => svg.delete)
        svgs = [...svgs, ...incomingSvgs]

        /////////////////////////////////////////////////////////////////////////////////////////
        // incoming layers
        /////////////////////////////////////////////////////////////////////////////////////////
        let layers = JSON.parse(JSON.stringify(workspace.layers))
        // remove spacers from incoming layers
        layers.forEach(ly => {
            ly.inputs = ly.inputs.filter(ly => ly.inputKey !== 5 && ly.inputKey !== 59 && ly.inputKey !== 72)
            ly.layerInputKeys = ly.layerInputKeys.filter(inputKey => inputKey !== 5 && inputKey !== 59 && inputKey !== 72)
        })

        // add the new opacity setting for line(s) layers.
        let lineLayers = layers.filter(ly => {
            let useKey = ly.layerKey
            if (ly.parentLayerKey > -1) {
                useKey = ly.parentLayerKey
            }
            let layerType = Utility.originalLayerNameLayerKey(state.layers, useKey)
            return layerType === 'line'
        })

        // add national flags layer
        let nationalFlagsLayer = state.layers.find( sl => sl.layerName === 'national flags' )
        if( nationalFlagsLayer ) {
            layers.push(nationalFlagsLayer)
        }


        // add opacity control
        let lineLayer = state.layers.find(slr => slr.layerName === 'line')
        let opacitySettings = lineLayer.inputs.find(inp => inp.named === 'opacity')
        lineLayers.forEach(llr => {
            if (llr.layerInputKeys.includes(55) === false) {
                llr.layerInputKeys.push(55) // the opacity control
                llr.inputs.push(opacitySettings)
            }
        })


        /////////////////////////////////////////////////////////////////////////////////////////
        // incoming activeLayerValues
        /////////////////////////////////////////////////////////////////////////////////////////
        let activeLayerValues = JSON.parse(JSON.stringify(workspace.activeLayerValues))
        // remove spacers from activeLayerValues
        for (const [key] of Object.entries(Utility.safeJsonParse(workspace.activeLayerValues))) {
            // remove spacer inputs (not used in SnapCounter version 3). 
            if (key.endsWith('_5') || key.endsWith('_59') || key.endsWith('_72')) {
                delete activeLayerValues[key]
            }
        }

        // add counterSideActive
        let counterSideActive = { index: 0, active: "front", front: JSON.stringify(activeLayerValues), rear: JSON.stringify(activeLayerValues) }

        // add sheetSideActive
        let sheetSideActive = { index: 0, active: "front", front: "", rear: "" }

        // add sheetSettings.sheetSide
        let sheetSettings = JSON.parse(JSON.stringify(workspace.sheetSettings))
        sheetSettings.sheetSide = 'front'
        sheetSettings.printableArea = [207.44, 270.94]

        // there may be new state fonts we dont want being removed, so add in incoming fonts to state.
        let incomingFonts = []
        if (workspace.fonts && Array.isArray(workspace.fonts)) {
            incomingFonts = workspace.fonts
        }
        let fonts = JSON.parse(JSON.stringify(state.fonts))
        incomingFonts.forEach(ifnt => {
            if (!fonts.find(fnt => fnt.fontFamily === ifnt.fontFamily)) {
                fonts.push(ifnt)
            }
        })

        let slots = JSON.parse(JSON.stringify(workspace.slots))


        slots.forEach(slot => {
            if (slot.counterState) {
                // remove spacers from slots activeLayerValues
                let activeLayerValues = JSON.parse(JSON.stringify(slot.counterState.activeLayerValues))
                for (const [key] of Object.entries(Utility.safeJsonParse(slot.counterState.activeLayerValues))) {
                    // remove spacer inputs (not used in SnapCounter version 3). 
                    if (key.endsWith('_5') || key.endsWith('_59') || key.endsWith('_72')) {
                        delete activeLayerValues[key]
                    }
                }
                slot.counterState.activeLayerValues = activeLayerValues

                // remove spacers from slots layers inputs and
                slot.counterState.layers.forEach(ly => {
                    ly.inputs = ly.inputs.filter(inp => inp.inputKey !== 5 && inp.inputKey !== 59 && inp.inputKey !== 72)
                    ly.layerInputKeys = ly.layerInputKeys.filter(inputKey => inputKey !== 5 && inputKey !== 59 && inputKey !== 72)
                })

                // add the new opacity setting for line(s) layers.
                let lineLayers = slot.counterState.layers.filter(ly => {
                    let useKey = ly.layerKey
                    if (ly.parentLayerKey > -1) {
                        useKey = ly.parentLayerKey
                    }
                    let layerType = Utility.originalLayerNameLayerKey(state.layers, useKey)
                    return layerType === 'line'
                })
                // add opacity control
                lineLayers.forEach(llr => {
                    if (llr.layerInputKeys.includes(55) === false) {
                        llr.layerInputKeys.push(55) // the opacity control
                        llr.inputs.push(opacitySettings)
                    }
                })

                // extract the customSvgs and split them up into image and svgs, and use just the keys to setup the keys field.
                // we dont store the whole svgs anymore in each slot.
                // create new (temp) arrays to store the values for later transport
                slot.counterState.customImageSvgKeys = []
                slot.counterState.customSvgKeys = []
                slot.counterState.customSvgs.forEach(csvg => {
                    let svgKey = csvg.svgKey
                    if (csvg.svgCode.includes('svgxlink:href') && csvg.svgCode.includes('<image')) {
                        slot.counterState.customImageSvgKeys.push(svgKey)
                    }
                    else {
                        slot.counterState.customSvgKeys.push(svgKey)
                    }
                })

                // create holding array for font familys
                slot.counterState.fontFamilies = extractFontsUsed(slot.svg)
            }
        })

        const combineDirectSvgs = svgs => {
            let combinedSvgs = '<svg id="" xmlns="http://www.w3.org/2000/svg" width="1000" height="1000">'
            svgs.forEach(svg => {
                if (svg) {
                    let modifiedSvg = svg
                    if (modifiedSvg.includes("-120, -120, 240, 240")) {
                        modifiedSvg = modifiedSvg.replace("-120, -120, 240, 240", "-100, -100, 200, 200")
                    }
                    if (modifiedSvg.includes("0, 0, 240, 240")) {
                        let matrixIndex = modifiedSvg.indexOf("<g transform")
                        if (matrixIndex > 0) {
                            // a transform over the transform - easier than trying to compute the correct numbers for the existing transform.
                            modifiedSvg = modifiedSvg.replace("<g transform", '<g transform="matrix(1.2, 0, 0, 1.2, -24, -24)"><g transform')
                        }
                        let lastSvgTag = modifiedSvg.lastIndexOf('</svg>')
                        modifiedSvg = modifiedSvg.substring(0, lastSvgTag) + '</g></svg>'
                    }
                    combinedSvgs += modifiedSvg
                }
            })
            combinedSvgs += '</svg>'
        
            let hash = Utility.cyrb53(combinedSvgs, 'snapcounter_sheet_v3')
            combinedSvgs = combinedSvgs.replace('id=""', 'id="combined_' + hash + '"')
        
            return combinedSvgs
        }

        slots.forEach(slot => {
            // the old version sheet had somewhat different positioning values, so we need to update the
            // old slot's svg to get it to be positioned correctly.
            if (slot.counterState) {
                let layerSvgs = slot.counterState.layers.map(ly => ly.svg)
                slot.counterState.combinedSvg = combineDirectSvgs(layerSvgs)
                slot.counterState.layers.forEach(ly => delete ly.svg)
            }
        })

        slots.forEach(slot => {
            if (slot.counterState) {
                slot.counterState.frontSvg = slot.counterState.combinedSvg
                slot.counterState.rearSvg = slot.counterState.combinedSvg
                slot.counterState.package = {}
                slot.counterState.package.counterSideActive = { active: 'front', front: JSON.stringify(slot.counterState.activeLayerValues), rear: JSON.stringify(slot.counterState.activeLayerValues) }
                slot.counterState.package.customImageSvgKeys = [...slot.counterState.customImageSvgKeys]
                slot.counterState.package.customSvgKeys = [...slot.counterState.customSvgKeys]
                slot.counterState.package.fonts = [...slot.counterState.fontFamilies]
                slot.counterState.package.layers = [...slot.counterState.layers]
                slot.counterState.package.layersEffects = []
                slot.rearNumber = 0
                slot.rearPosition = { col: 0, row: 0 }
                delete slot.counterState.activeLayerValues
                delete slot.counterState.customImageSvgKeys
                delete slot.counterState.customSvgKeys
                delete slot.counterState.customSvgs
                delete slot.counterState.fontFamilies
                delete slot.counterState.layers
                delete slot.svg
                delete slot.counterState.combinedSvg
            }
        })

        workspace.counterSideActive = [counterSideActive]
        workspace.activeLayerValues = stateActiveLayerValuesToDexie(activeLayerValues)
        workspace.fonts = fonts
        workspace.layers = layers
        workspace.savedCounters = []
        workspace.savedSheets = []
        workspace.sheetSettings = [sheetSettings]
        workspace.sheetSideActive = sheetSideActive
        workspace.slots = slots
        workspace.svgs = svgs
        workspace.layersEffects = []
        workspace.layersOpen = []

        return workspace
    }

    const extractFontsUsed = (svg) => {
        let fonts = []
        let checkForFontsText = svg.replaceAll('&quot;', '"')
        checkForFontsText = checkForFontsText.replaceAll('font-family: ', 'font-family:')
        let foundAt = 0
        let exitAt = 1000
        while (foundAt > -1) {
            foundAt = checkForFontsText.indexOf('font-family:', foundAt + 12)
            if (foundAt > -1) {
                let endQuote = checkForFontsText.indexOf('"', foundAt + 12)
                let endSemiColon = checkForFontsText.indexOf(';', foundAt + 12)
                if (endSemiColon < endQuote && endSemiColon > 0) {
                    endQuote = endSemiColon
                }
                if (endQuote > -1) {
                    let fontFamilyText = checkForFontsText.substring(foundAt + 12, endQuote)
                    if (!fonts.find(font => font === fontFamilyText.trim())) {
                        fonts.push(fontFamilyText.trim())
                    }
                }
            }
            exitAt--
            if (exitAt === 0) {
                break
            }
        }

        return fonts
    }

    const stateActiveLayerValuesToDexie = (data) => {
        if (data) {
            let dexieCompatible = []
            for (const [combinedKey, value] of Object.entries(data)) {
                dexieCompatible.push({ lik: combinedKey, value })
            }
            return dexieCompatible
        }
    }

    const loadDatabaseBackup = stores => {
        let abort = false
        let storesData = [
            { store: "counterSideActive", data: null, count: 0, 'required': true },
            { store: "activeLayerValues", data: null, count: 0, 'required': true },
            { store: "fonts", data: null, count: 0, 'required': true },
            { store: "layers", data: null, count: 0, 'required': true },
            { store: "savedCounters", data: null, count: 0, 'required': false },
            { store: "savedSheets", data: null, count: 0, 'required': false },
            { store: "sheetSettings", data: null, count: 0, 'required': true },
            { store: "slots", data: null, count: 0, 'required': true },
            { store: "svgs", data: null, count: 0, 'required': true },
            { store: "layersEffects", data: null, count: 0, 'required': false }]
        for (const [store, data] of Object.entries(stores)) {
            let entry = storesData.find(sd => sd.store === store)
            if (entry) {
                if (entry.required && data.length === 0) {
                    console.error('file is bad, required data for', store, ' is empty')
                    abort = true
                    break
                }
                entry.data = data
                entry.count = data.length
            }
        }
        if (!abort) {
            storesData = storesData.filter(sd => sd.count > 0)
            actions.importDatabase(storesData)
        }
    }

    const onClickLoadFile = evt => {
        setLoadWorkspaceFileInvalid(false)
        setLoadWorkspaceErrors([])
    }

    const onClickExecuteImport = async () => {
        if (databaseToRestore) {
            actions.importDatabase(databaseToRestore.workspace)
        }
    }

    const onClickCancelImport = () => {
        setDatabaseToRestore(null)
    }

    return (
        <div className="view-app">


            <div className="entry export">
                <div><button className={showExportDatabaseForm ? 'standard-button disabled' : 'standard-button blue'} onClick={showExportDatabaseForm ? null : clickExportDatabase}>export database</button></div>
                <div className="info">
                    <div className="title">Export entire app database to a file</div>
                    <div className="export-container">
                        <div className={showExportDatabaseForm ? 'comments hide' : 'comments'}>
                            <p>
                                You can save the database, basically the "workspace" of the app, to an external file. This file can then be
                                imported later to restore the workspace.
                            </p>Export
                            <p>
                                This gives you a backup of the current workspace that lets you put everything back the way it was.
                                The file can also be used to reproduce the same workspace on a different instance of the app,
                                such as on a different browser, different computer, or sent to a colleague who can load your
                                workspace into their app instance.
                            </p>
                            <p>
                                This has nothing to do with counters or sheets that were saved to files. Those aren't affected.
                            </p>
                        </div>
                        <div className={showExportDatabaseForm ? 'export-database-form' : 'display-none'}>
                            export database filename: <input ref={exportDatabaseFilenameRef} value={exportDatabaseFilename} onChange={onChangeExportDatabaseFilename} type="text" />
                            <button className={exportDatabaseFilename.length > 1 ? 'standard-button blue' : 'standard-button disabled'} onClick={exportDatabaseFilename.length > 1 ? exportDatabase : null}>submit</button>
                            <button className="standard-button yellow" onClick={() => setShowExportDatabaseForm(false)}>cancel</button>
                            <div className="append-date" onClick={appendDateToText}>click to append date to end of filename</div>
                        </div>
                    </div>
                </div>
            </div>



            <div className="entry import">

                {databaseToRestore ?
                    <div className="import-ready">
                        <p>Import is ready to install:</p>
                        <div className="import-info-box">
                            <p>import name: <span>{databaseToRestore.fileName}</span></p>
                            <p>date created: <span>{databaseToRestore.dateSaved}</span></p>
                            <div className="action-buttons">
                                <button className="standard-button blue" onClick={onClickExecuteImport}>execute import</button>
                                <button className="standard-button yellow" onClick={onClickCancelImport}>cancel</button>
                            </div>
                        </div>
                    </div>
                    :
                    <div className="import-form">
                        <input type="file" id="sheet_file" accept=".zip" onClick={onClickLoadFile} onChange={onChangeLoadFromFile} />
                        {loadWorkspaceFileInvalid ?
                            <div className="error">{loadWorkspaceFileInvalid}</div>
                            : ''
                        }
                        {loadWorkspaceErrors.length > 0 ?
                            <div className="error">
                                <div>There are problems:</div>
                                {loadWorkspaceErrors.map((err, index) => <div key={index}>{err.table}: {err.error}</div>)}
                            </div>
                            : ''}
                    </div>


                }


                <div className="entry-import-form">
                    <div className="info">
                        <div className="title">Import database from file</div>
                        <div className="comments">
                            <p>
                                You can restore the workspace of this app by importing a database file
                                that you previously saved.
                            </p>
                            <p>
                                This action will remove any customizations you have made to the app as it exists now,
                                including any custom svgs or images, any counters or sheets that are currently
                                saved in the app, and it will replace your sheet settings.
                            </p>
                        </div>
                    </div>
                </div>
            </div>





            <div className="entry reset">

                <div className="entry-reset-buttons">

                    {resetDatabaseErrorMessage ?
                        <div className="error">{resetDatabaseErrorMessage}</div>
                        :
                        <button className={resetDatabaseConfirm ? 'standard-button red' : 'standard-button yellow'} onClick={clickResetDatabase}>
                            <div className="text-options">
                                <span className={resetDatabaseConfirm ? 'disappear' : ''}>reset database</span>
                                <span className={resetDatabaseConfirm ? 'reset-confirm' : 'reset-confirm disappear'}>are you sure?</span>
                            </div>
                        </button>

                    }
                </div>



                <div className="info">
                    <div className="title">Reset database to default settings</div>
                    <div className="comments">
                        <p>
                            If the database on this app has a problem, is corrupted somehow, or if you just want to erase all changes
                            you made to it, you can reset the database to its original state.
                        </p>
                        <p>
                            This will remove all saved counters and sheets that have been saved in the app. I will also all remove
                            any duplicated menu layers, and all custom svgs and images. The sheet settings will also be reverted to defaults.
                        </p>
                        <p>
                            This will not affect any saved counters or saved sheets you may have saved as files to your computer system.
                        </p>
                    </div>
                </div>
            </div>

            <svg id="paperTarget" />

        </div>
    );
}
export default ViewApp
