import React, { useState, useEffect, useContext } from 'react'
import stringComparison from 'string-comparison'
//import { levenshtein } from "string-comparison"
import { useSignal } from "@preact/signals-react"
import JSZip from 'jszip'
import { saveAs } from 'file-saver'
import Snap from 'snapsvg-cjs'
import { StoreContext } from "../../context/StoreContext"
import Utility from "../../objects/Utility"
import './SheetOperations.scss'

const SheetOperations = ({ sheetParameters, changeSheetMenuOperationsOpened, changeGuideType, changePrintableArea }) => {
    const controller = new AbortController();
    const { state, actions } = useContext(StoreContext)
    const [sheetOperationsOpen, setSheetOperationsOpen] = useState(true)
    const [saveOption, setSaveOption] = useState('app')
    const [saveName, setSaveName] = useState('')
    const [saveActionsResult, setSaveActionsResult] = useState('')
    const sgLastSentGuideChange = useSignal(false)
    const [printableAreaMM_width, setPrintableAreaMM_width] = useState(sheetParameters.printableAreaMM[0])
    const [printableAreaMM_height, setPrintableAreaMM_height] = useState(sheetParameters.printableAreaMM[1])
    const [buildSheetPaper, setBuildSheetPaper] = useState(null)
    const [savedSheetsMetadata, setSavedSheetsMetadata] = useState([])
    const [selectedSheetHash, setSelectedSheetHash] = useState('')
    const [loadFrom, setLoadFrom] = useState('app')
    const [loadSheetErrorMessage, setLoadSheetErrorMessage] = useState('')

    useEffect(() => {
        let _buildSheetPaper = Snap("#build_sheet")
        if (_buildSheetPaper) {
            setBuildSheetPaper(_buildSheetPaper)
        }
        return () => {
            controller.abort();
        };

    }, []) // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        changeSheetMenuOperationsOpened(sheetOperationsOpen)
    }, [sheetOperationsOpen]) // eslint-disable-line react-hooks/exhaustive-deps

    const onSaveName = evt => {
        let value = evt.target.value
        if (value === '') {
            setSaveName('')
            return
        }
        let regexResult = value.match(/[-_)( a-zA-Z0-9]+$/)
        if ((regexResult === null) || (regexResult && regexResult.index > 0)) {
            return
        }
        setSaveName(value)
    }

    const saveSheet = () => {
        // if saving a sheet in app, dont include the custom svgs data or fonts data, cause that would
        // take too much memory. For saving as an external file, do add the data.
        if (saveOption === 'app') {
            let preparedSvg = prepareSheetSvg()
            let hash = Utility.cyrb53(preparedSvg, 'snapcounter_sheet_v3')
            if (state.savedSheets[hash]) {
                setSaveActionsResult('Sheet previously saved!')
                return
            }
            let compressedSvg = Utility.compressString(preparedSvg)

            let occupiedSlots = state.slots.filter(sl => sl.counterState !== null)
            let saveSheetObj = {}
            saveSheetObj[hash] = {
                hash: hash,
                name: saveName,
                slots: occupiedSlots,
                sheetSettings: { ...state.sheetSettings },
                compressedSvg: compressedSvg,
                dateSaved: Utility.currentDate()
            }
            actions.savedSheets(saveSheetObj)
            setSaveActionsResult('Sheet has been saved in app')

        }
        if (saveOption === 'file') {
            // get svg of whole sheet created
            let preparedSvg = prepareSheetSvg()
            let hash = Utility.cyrb53(preparedSvg, 'snapcounter_sheet_v3')
            let compressedSvg = Utility.compressString(preparedSvg)

            // get the fonts data from the slots
            let occupiedSlots = state.slots.filter(sl => sl.counterState !== null)
            const fontsData = assembleFontsData(occupiedSlots)

            // get the custom images/svgs used
            const customSvgsData = assembleCustomSvgsData(occupiedSlots)


            const saveSheetData = {
                version: 3,
                type: 'sheet',
                hash: hash,
                name: saveName,
                slots: occupiedSlots,
                sheetSettings: { ...state.sheetSettings },
                compressedSvg: compressedSvg,
                fontsData,
                customSvgsData,
                dateSaved: Utility.currentDate()
            }
            var zip = new JSZip();
            zip.file(saveName + ".json", JSON.stringify(saveSheetData));
            zip.generateAsync({ type: "blob", compression: "DEFLATE" })
                .then(function (content) {
                    // see FileSaver.js
                    saveAs(content, saveName + '.scz');
                });

            setSaveActionsResult('Sheet has been saved to file')

        }

        if (saveOption === 'svg') {
            let occupiedSlots = state.slots.filter(sl => sl.counterState !== null)
            const fontsData = assembleFontsData(occupiedSlots)
            const preparedSvg = prepareSheetSvg(fontsData)
            const blob = new Blob([preparedSvg], { type: 'image/svg+xml' });
            saveAs(blob, saveName + '.svg');
        }

        if (saveOption === 'png') {

            const getImageDataURL = (image, type = 'png') => {
                if (type === 'jpg') type = 'jpeg';
                // const { width, height } = image;
                let width = 0
                let height = 0
                let printableWidthInches = sheetParameters.printableAreaMM[0] * 0.0393701
                let printableHeightInches = sheetParameters.printableAreaMM[1] * 0.0393701
                if (sheetParameters.height / sheetParameters.width) {
                    width = (sheetParameters.width) * ((printableWidthInches * 300) / sheetParameters.width)
                    height = (sheetParameters.height) * ((printableWidthInches * 300) / sheetParameters.width)
                }
                else {
                    width = (sheetParameters.width) * (((printableHeightInches * 300) * 300) / sheetParameters.height)
                    height = (sheetParameters.height) * ((printableHeightInches * 300) / sheetParameters.height)
                }
                const canvas = document.createElement('canvas');

                const context = canvas.getContext('2d');
                canvas.width = width;
                canvas.height = height;

                if (type === 'jpeg') {
                    context.fillStyle = 'white';
                    context.fillRect(0, 0, width, height);
                }

                context.drawImage(image, 0, 0, width, height);

                return canvas.toDataURL(`image/${type}`);
            }

            const convertSvg = (svgString, type = 'png') => new Promise((res, rej) => {
                const image = new Image();
                image.onload = () => res(getImageDataURL(image, type));
                image.src = `data:image/svg+xml,${encodeURIComponent(svgString)}`;
            });

            let occupiedSlots = state.slots.filter(sl => sl.counterState !== null)
            const fontsData = assembleFontsData(occupiedSlots)
            let source = prepareSheetSvg(fontsData)
            convertSvg(source, 'png').then(dataURL => {
                saveAs(dataURL, saveName + '.png');
            })
        }
    }

    const assembleCustomSvgsData = occupiedSlots => {
        let customSvgsData = []
        occupiedSlots.forEach(os => {
            os.counterState.package.customImageSvgKeys.forEach(iSvgKey => {
                if (!customSvgsData.find(csvgd => csvgd.svgKey === iSvgKey)) {
                    let foundSvg = state.svgs.find(ssvg => ssvg.svgKey === iSvgKey)
                    if (foundSvg) {
                        foundSvg.type = 'image'
                        customSvgsData.push(foundSvg)
                    }
                }
            })
            os.counterState.package.customSvgKeys.forEach(iSvgKey => {
                if (!customSvgsData.find(csvgd => csvgd.svgKey === iSvgKey)) {
                    let foundSvg = state.svgs.find(ssvg => ssvg.svgKey === iSvgKey)
                    if (foundSvg) {
                        foundSvg.type = 'svg'
                        customSvgsData.push(foundSvg)
                    }
                }
            })
        })

        return customSvgsData
    }

    const detectFontsInALV = (alv) => {
        let fontInputKeys = []
        let fontFamilies = []

        // find layers that have fonts in them
        state.layers.forEach(ly => {
            let fontInput = ly.inputs.find(inp => inp.named === 'font')
            if (fontInput) {
                if (!fontInputKeys.includes(fontInput.inputKey)) {
                    fontInputKeys.push(fontInput.inputKey)
                }
            }
        })
        let objectKeys = Object.keys(alv)
        objectKeys.forEach(key => {
            let splitKey = key.split('_')
            if (splitKey.length === 2) {
                let inputKey = Number(splitKey[1])
                if (fontInputKeys.includes(inputKey)) {
                    if (!fontFamilies.includes(alv[key])) {
                        fontFamilies.push(alv[key])
                    }
                }
            }
        })

        return fontFamilies
    }

    const assembleFontsData = occupiedSlots => {
        let fontsUsed = []
        let fontData = []
        occupiedSlots.forEach(os => {
            if (os.counterState.package.fonts.length > 0) {
                fontsUsed = fontsUsed.concat(os.counterState.package.fonts)
            }
        })
        if (fontsUsed.length > 0) {
            fontsUsed = [...(new Set(fontsUsed))]
        }

        fontsUsed.forEach(fontFamily => {
            let fd = state.fonts.find(st => st.fontFamily === fontFamily)
            if (fd) {
                fontData.push(fd)
            }
        })

        return fontData
    }

    const insertFontsToDef = (paper, fontsData) => {
        if (!fontsData || !Array.isArray(fontsData) || fontsData.length === 0) {
            return
        }
        let styleString = '<defs><style id="fontsStyle" type="text/css">'
        fontsData.forEach(fontData => {

            styleString += "@font-face { font-family: '" + fontData.fontFamily + "'; "
            styleString += 'font-weight: ' + fontData.fontWeight + '; '
            if (fontData.fontSrc.includes("format('woff2')") === false) {
                styleString += 'src: ' + fontData.fontSrc + " format('woff2');"
            }
            else {
                styleString += 'src: ' + fontData.fontSrc + ';'
            }
            styleString += '}'
            styleString = styleString.replaceAll(';;', ';')

        })
        styleString += '</style></defs>'
        let snapFont = Snap.parse(styleString)
        paper.append(snapFont)

        const svg = paper.toString()
        return svg
    }

    useEffect(() => {
        let arr = []
        if (state.savedSheets) {
            for (const [key, value] of Object.entries(state.savedSheets)) {
                arr.push({ hash: key, name: value.name })
            }
            setSavedSheetsMetadata(arr)
        }
    }, [state.savedSheets])

    useEffect(() => {
        setPrintableAreaMM_width(sheetParameters.printableAreaMM[0])
        setPrintableAreaMM_height(sheetParameters.printableAreaMM[1])
    }, [sheetParameters])

    const prepareSheetSvg = (fontsData = []) => {
        const originalSvgSource = state.sheetPaper.toString()

        buildSheetPaper.attr({ width: sheetParameters.width, height: sheetParameters.height });
        let parsed = Snap.parse(originalSvgSource)

        if (fontsData.length > 0) {
            insertFontsToDef(buildSheetPaper, fontsData)
        }
        buildSheetPaper.append(parsed)

        let holderElements = buildSheetPaper.selectAll(`[data-type="holder"]`)
        holderElements.forEach(holder => {
            if (holder) {
                holder.remove()
            }
        })
        let holderTexts = buildSheetPaper.selectAll(`[data-type="holderText"]`);
        holderTexts.forEach(holderText => {
            if (holderText) {
                holderText.remove()
            }
        })
        let pageMargins = buildSheetPaper.selectAll(`[data-type="pageMargin"]`);
        pageMargins.forEach(pageMargin => {
            if (pageMargin) {
                pageMargin.remove()
            }
        })

        let preparedSvg = buildSheetPaper.toString()
        preparedSvg = preparedSvg.replaceAll('<defs></defs>', '')
        preparedSvg = preparedSvg.replaceAll('style="pointer-events: visiblepainted;"', '')
        buildSheetPaper.clear()
        return preparedSvg
    }

    const saveActionsOk = () => {
        setSaveActionsResult('')
    }

    const onChangeGuideType = guideType => {
        if (sgLastSentGuideChange.value !== guideType) {
            sgLastSentGuideChange.value = guideType
            changeGuideType(guideType)
        }
    }

    const onChangePrintableArea = evt => {
        let id = evt.target.id
        let value = evt.target.value
        let index = -1
        if (id.includes('width')) {
            index = 0
        }
        if (id.includes('height')) {
            index = 1
        }
        if (index > -1) {
            if (Utility.isNumeric(value)) {
                value = Utility.roundFloat(value, 3)
                if (value >= 100 && value <= 400) {
                    let printableAreaMM = [...sheetParameters.printableAreaMM]
                    printableAreaMM[index] = value
                    changePrintableArea(printableAreaMM)
                }
            }
            if (index === 0) {
                setPrintableAreaMM_width(value)
            }
            if (index === 1) {
                setPrintableAreaMM_height(value)
            }
        }
    }

    const resetPrintableArea = () => {
        changePrintableArea([207.44, 270.94])
        setPrintableAreaMM_width(207.44)
        setPrintableAreaMM_height(270.94)
    }

    const printableAreaIsAtDefaults = () => {
        return printableAreaMM_width === 207.44 && printableAreaMM_height === 270.94
    }

    const validPrintableAreaWidth = () => {
        if (printableAreaMM_width === '') {
            return false
        }
        if (Utility.isNumeric(printableAreaMM_width)) {
            let v = Math.round(printableAreaMM_width)
            if (v < 100 || v > 500) {
                return false
            }
            return true
        }

        return false
    }

    const validPrintableAreaHeight = () => {
        if (printableAreaMM_height === '') {
            return false
        }
        if (Utility.isNumeric(printableAreaMM_height)) {
            let v = Math.round(printableAreaMM_height)
            if (v < 100 || v > 500) {
                return false
            }
            return true
        }

        return false
    }

    const loadSheetFromApp = evt => {
        let hash = evt.target.value
        if (hash) {
            let sheet = state.savedSheets[hash]
            if (sheet && sheet.sheetSettings && sheet.slots) {
                let sheetSettings = sheet.sheetSettings
                let slots = sheet.slots
                actions.loadSheet({ sheetSettings, slots })
                setSelectedSheetHash(hash)
            }
        }
    }

    const onLoadFrom = from => {
        setLoadFrom(from)
    }

    const clickClearSheet = () => {
        let slots = [...state.slots]
        slots.forEach(sl => sl.counterState = null)
        actions.slots(slots)
    }

    // on loading a sheet from file, we dont worry about dupe layers, since they will be handled if the user selects "edit"
    // and the file gets sent to CounterArea.js, which will figure things out. But we do need to install fonts and
    // svgs that are incoming, since the system needs to know whats fonts and svgs it needs to check to prevent deletion,
    // and we can't just have multiple copies of the same svgs and fonts sitting in a sheet, eating up memory.
    const loadSheetFromFile = evt => {
        let file = evt.target.files[0]

        JSZip.loadAsync(file)
            .then((zip) => {
                Object.keys(zip.files).forEach((filename) => {
                    zip.files[filename].async('string').then(async (fileData) => {
                        let data = null
                        data = JSON.parse(fileData)

                        // get the state images and svgs lists
                        let stateImagesLayer = state.layers.find(sl => sl.layerName === 'custom images')
                        let stateSvgsLayer = state.layers.find(sl => sl.layerName === 'custom svgs')

                        if( !stateImagesLayer || !stateSvgsLayer ) {
                            console.error('cannot find custom layers in state.')
                            return
                        }

                        let input = stateImagesLayer.inputs.find(li => li.named === 'svgKey')
                        let stateImagesLayerList = JSON.parse(JSON.stringify(input.list))

                        input = stateSvgsLayer.inputs.find(li => li.named === 'svgKey')
                        let stateSvgsLayerList = JSON.parse(JSON.stringify(input.list))

                        if (data.version === 3) {
                            if (data.type !== 'sheet') {
                                actions.addErrorMessages([{
                                    comment: "File error",
                                    lineItems: ['this file is a counter file, not a sheet file']
                                }])
                            }
                            else {
                                // check for incoming fonts
                                let fontsData = data.fontsData
                                let installFonts = []
                                fontsData.forEach(fd => {
                                    if (!state.fonts.find(sf => sf.fontFamily === fd.fontFamily)) {
                                        installFonts.push(fd)
                                    }
                                })
                                if (installFonts.length > 0) {
                                    let fonts = [...state.fonts, ...installFonts]
                                    actions.fonts(fonts)
                                }

                                // check for unknown incoming custom svgs
                                let customSvgsData = data.customSvgsData
                                let changeCustomSvgKeysFromTo = []
                                let installSvgs = []
                                customSvgsData.forEach(isvg => {
                                    if (state.svgs.find(ss => ss.svgKey === isvg.svgKey && ss.svgName === isvg.svgName)) {
                                        ; //found with same key and name, nothing to do.
                                    }
                                    else {
                                        let found = state.svgs.find(ss => ss.svgName === isvg.svgName)
                                        if (found) {
                                            changeCustomSvgKeysFromTo.push({ from: isvg.svgKey, to: found.svgKey })
                                        }
                                        else {
                                            // else we need to install it.
                                            installSvgs.push(isvg)
                                        }
                                    }
                                })

                                let useKey = state.svgs.reduce((a, b) => Number(a.svgKey) > Number(b.svgKey) ? a : b).svgKey
                                for (let i = 0; i < installSvgs.length; i++) {
                                    let svg = installSvgs[i]
                                    let svgType = 'svg'
                                    if (svg.svgCode.includes('svgxlink:href') && svg.svgCode.includes('<image')) {
                                        svgType = 'image'
                                    }
                                    // get next svgKey
                                    if (useKey < 3000) {
                                        useKey = 3000
                                    }
                                    else {
                                        useKey++
                                    }
                                    let fromKey = svg.svgKey
                                    let toKey = useKey
                                    svg.svgKey = toKey
                                    if (fromKey !== toKey) {
                                        changeCustomSvgKeysFromTo.push({ from: fromKey, to: toKey, type: svgType })
                                    }
                                }

                                changeCustomSvgKeysFromTo.forEach(ccs => {
                                    if (ccs.type === 'image') {
                                        if (stateImagesLayerList.includes(ccs.to) === false) {
                                            stateImagesLayerList.push(ccs.to)
                                        }
                                    }
                                    if (ccs.type === 'svg') {
                                        if (stateSvgsLayerList.includes(ccs.to) === false) {
                                            stateSvgsLayerList.push(ccs.to)
                                        }
                                    }
                                })

                                installSvgs.forEach( isvg => {
                                    if (isvg.type === 'image') {
                                        if (stateImagesLayerList.includes(isvg.svgKey) === false) {
                                            stateImagesLayerList.push(isvg.svgKey)
                                        }
                                    }
                                    if (isvg.type === 'svg') {
                                        if (stateSvgsLayerList.includes(isvg.svgKey) === false) {
                                            stateSvgsLayerList.push(isvg.svgKey)
                                        }
                                    }
                                })

                                if (installSvgs.length > 0) {
                                    installSvgs.forEach( isvg => delete isvg.type )
                                    actions.svgs([...state.svgs, ...installSvgs])
                                }
                                let stateLayers = JSON.parse(JSON.stringify(state.layers))
                                stateLayers.forEach(sLayer => {
                                    if (stateImagesLayerList.length > 0) {
                                        if (Utility.originalLayerNameByLayer(state.layers, sLayer) === 'custom images') {
                                            let input = sLayer.inputs.find(li => li.named === 'svgKey')
                                            if (input) {
                                                input.list = stateImagesLayerList
                                                sLayer.layerHidden = 0
                                            }
                                        }
                                    }
                                    if (stateSvgsLayerList.length > 0) {
                                        if (Utility.originalLayerNameByLayer(state.layers, sLayer) === 'custom svgs') {
                                            let input = sLayer.inputs.find(li => li.named === 'svgKey')
                                            if (input) {
                                                input.list = stateSvgsLayerList
                                                sLayer.layerHidden = 0
                                            }
                                        }
                                    }
                                })

                                if (!data.sheetSettings.sheetSide) {
                                    let firstCounter = data.slots.find(slot => slot.counterState !== null)
                                    if (firstCounter) {
                                        data.sheetSettings.sheetSide = firstCounter.counterState.package.counterSideActive.active
                                    }
                                    else {
                                        data.sheetSettings.sheetSide = 'front'
                                    }
                                }

                                let stateLayersAvailable = stateLayers.map(sl => {
                                    return {
                                        layerType: Utility.originalLayerNameLayerKey(stateLayers, sl.layerKey),
                                        layerKey: sl.layerKey,
                                        parentLayerKey: sl.parentLayerKey,
                                        assigned: false
                                    }
                                })

                                // get all the layers and dupe layers we need
                                data.slots.forEach( slot => {
                                    let layers = slot.counterState.package.layers
                                    let incomingLayers = layers.filter(ly => ly.layerActive === 1)

                                    // setup our special matching structure
                                    let incomingLayersToAssign = incomingLayers.map(sl => {
                                        let useKey = sl.parentLayerKey === -1 ? sl.layerKey : sl.parentLayerKey
                                        return {
                                            layerType: Utility.originalLayerNameLayerKey(stateLayers, useKey),
                                            layerKey: sl.layerKey,
                                            parentLayerKey: sl.parentLayerKey,
                                            layerOrder: sl.layerOrder, // extra info we need to help setup state sorta the way the imported file's state was
                                            layerName: sl.layerName, // extra info
                                            assigned: false
                                        }
                                    })

                                    stateLayersAvailable.forEach( sla => sla.assigned = false )

                                    let changedLayerKeys = []
                                    incomingLayersToAssign.forEach(ily => {
                                        let found = false
                                        if (ily.parentLayerKey === -1) { // try to match to a parent
                                            found = stateLayersAvailable.find(sla => sla.layerType === ily.layerType && sla.parentLayerKey === -1 && sla.assigned === false)
                                        }
                                        else {
                                            found = stateLayersAvailable.find(sla => sla.layerType === ily.layerType && sla.parentLayerKey > -1 && sla.assigned === false)
                                        }
                                        if (found) {
                                            // setting assigned to true for both
                                            ily.assigned = true
                                            found.assigned = true
                                            if (ily.layerKey !== found.layerKey) {
                                                changedLayerKeys.push({ from: ily.layerKey, to: found.layerKey, type: found.layerType, created: false })
                                            }
                                        }
                                    })
                            
                                    let layersToCreate = incomingLayersToAssign.filter(ily => ily.assigned === false)
                                     let { newLayers, finalChangedLayerKeys } = createDuplicateLayers(stateLayers, layersToCreate, changedLayerKeys)
                                     // add in new layers we will have available
                                     stateLayers = [...stateLayers, ...newLayers]
 
                                     // we need to process the layer key changes for active layer values of this slot and layers
                                     let newFrontAlv = JSON.parse( slot.counterState.package.counterSideActive.front)
                                     let newRearAlv = JSON.parse( slot.counterState.package.counterSideActive.rear)
                                     newFrontAlv = processAlvLayerKeyReplacements(finalChangedLayerKeys, newFrontAlv)
                                     newRearAlv = processAlvLayerKeyReplacements(finalChangedLayerKeys, newRearAlv)
                                     slot.counterState.package.counterSideActive.front = JSON.stringify(newFrontAlv)
                                     slot.counterState.package.counterSideActive.rear = JSON.stringify(newRearAlv)

                                     slot.counterState.package.layers.forEach( layer => {
                                         let found = finalChangedLayerKeys.find( fclk => fclk.from === found.layerKey )
                                         if( found ) {
                                             layer.layerKeyNew = found.to
                                         }
                                     })
                                     slot.counterState.package.layers.forEach( layer => {
                                        if( layer.layerKeyNew ) {
                                            layer.layerKey = layer.layerKeyNew
                                            delete layer.layerKeyNew
                                        }
                                    })

                                })

                                // we need to go through each slot and change its svgKey and imageKey arrays for any changes,
                                // and we need to go into the activeLayerValues, and layer lists, and update them too.

                                data.slots.forEach(slot => {
                                    changeCustomSvgKeysFromTo.forEach(fromToType => {
                                        if (fromToType.type === 'image') {
                                            if (slot.counterState.package.customImageSvgKeys.includes(fromToType.from)) {
                                                slot.counterState.package.customImageSvgKeys = slot.counterState.package.customImageSvgKeys.filter(svgKey => svgKey !== fromToType.from)
                                                slot.counterState.package.customImageSvgKeys.push('new_' + fromToType.to)
                                            }
                                        }
                                        if (fromToType.type === 'svg') {
                                            if (slot.counterState.package.customSvgKeys.includes(fromToType.from)) {
                                                slot.counterState.package.customSvgKeys = slot.counterState.package.customImageSvgKeys.filter(svgKey => svgKey !== fromToType.from)
                                                slot.counterState.package.customSvgKeys.push('new_' + fromToType.to)
                                            }
                                        }
                                    })
                                    slot.counterState.package.customImageSvgKeys.forEach(svgKey => {
                                        if (typeof svgKey === 'string') {
                                            svgKey = Number(svgKey.replace('new_', ''))
                                        }
                                    })
                                    slot.counterState.package.customSvgKeys.forEach(svgKey => {
                                        if (typeof svgKey === 'string') {
                                            svgKey = Number(svgKey.replace('new_', ''))
                                        }
                                    })

                                    // for each slot
                                    slot.counterState.package.counterSideActive = processSvgChangesCounterSideActive(stateLayers, slot.counterState.package.counterSideActive, changeCustomSvgKeysFromTo)
                                    slot.counterState.package.layers = processNewSvgListsIntoLayers(slot.counterState.package.layers, stateImagesLayerList, stateSvgsLayerList)
                                })

                                actions.layers(stateLayers)
                                delete data.compressedSvg
                                delete data.hash
                                actions.loadSheet({
                                    sheetSettings: data.sheetSettings,
                                    slots: data.slots
                                })

                            }


                        }
                        else {
                            if (data.type === 'counter') {
                                actions.addErrorMessages([{
                                    comment: "File error",
                                    lineItems: ['this file is a counter file, not a sheet']
                                }])

                            }
                            else {
                                if (data.sheetSettings && data.slots && data.type === 'sheet') {
                                    let updated = handleIncomingOldVersionSheet(data)
                                    delete updated.compressedSvg
                                    delete updated.hash
                                    actions.loadSheet({
                                        sheetSettings: data.sheetSettings,
                                        slots: updated.slots
                                    })

                                }
                                else {
                                    actions.addErrorMessages([{
                                        comment: "File error",
                                        lineItems: ['this file is a not a sheet file']
                                    }])
                                }
                            }
                        }
                    })
                })
            })

    }

    const processAlvLayerKeyReplacements = (fromTos, alv) => {
        let newAlv = JSON.parse(JSON.stringify(alv))
        fromTos.forEach(fromTo => {
            let from = fromTo.from
            let to = fromTo.to
            for (const [key, value] of Object.entries(alv)) {
                if (key.startsWith(from + '_')) {
                    let inputKey = key.split('_')[1]
                    newAlv[to + 'new_' + inputKey] = value
                    delete newAlv[key]
                }
            }
        })
        for (const [key, value] of Object.entries(newAlv)) {
            if (key.includes('new')) {
                let fixedKey = key.replace('new', '')
                newAlv[fixedKey] = value
                delete newAlv[key]
            }
        }

        return newAlv
    }

    const createDuplicateLayers = (stateLayers, dupeTheseLayers, changedLayerKeys) => {
        let _changedLayerKeys = JSON.parse(JSON.stringify(changedLayerKeys))
        let newLayers = []
        let stateLayersWithType = JSON.parse(JSON.stringify(stateLayers))
        stateLayersWithType.forEach(sl => {
            let useKey = sl.parentLayerKey === -1 ? sl.layerKey : sl.parentLayerKey
            sl.layerType = Utility.originalLayerNameLayerKey(state.layers, useKey)
        })
        let nextLayerKey = Math.max(...stateLayers.map(ly => parseInt(ly.layerKey)), 0)
        nextLayerKey++
        // { layerType: 'common symbols', layerKey: 25, parentLayerKey: 5, layerOrder: 10, assigned: false }
        dupeTheseLayers.forEach(dLayer => {
            let parentTemplate = stateLayersWithType.find(sl => sl.layerType === dLayer.layerType)
            if (parentTemplate) {
                let newDupeLayer = JSON.parse(JSON.stringify(parentTemplate))
                delete newDupeLayer.layerType
                newDupeLayer.parentLayerKey = newDupeLayer.layerKey
                newDupeLayer.layerKey = nextLayerKey
                nextLayerKey++
                newDupeLayer.layerOrder = dLayer.layerOrder + 0.5

                // check name to avoid dupe names
                let incNum = 2
                let dupeName = dLayer.layerName
                while (stateLayers.find(sl => sl.layerName === dupeName)) { // eslint-disable-line no-loop-func
                    dupeName = dLayer.layerName + incNum
                    incNum++
                    if (incNum > 100) {
                        break
                    }
                }
                newDupeLayer.layerName = dupeName
                if (dLayer.layerKey !== newDupeLayer.layerKey) {
                    _changedLayerKeys.push({ from: dLayer.layerKey, to: newDupeLayer.layerKey, type: dLayer.layerType, created: true })
                }
                newLayers.push(newDupeLayer)
            }
        })

        return { newLayers, finalChangedLayerKeys: _changedLayerKeys }
    }

    const processSvgChangesCounterSideActive = (stateLayers, counterSideActive, changeSvgKeys) => {
        let newFrontAlv = JSON.parse(counterSideActive.front)
        let newRearAlv = JSON.parse(counterSideActive.rear)
        stateLayers.forEach(ly => {
            if (ly.layerActiveRequiredInputKey) {
                let svgInputKey = ly.layerActiveRequiredInputKey
                if (newFrontAlv[ly.layerKey + '_' + svgInputKey]) {
                    let currentSvgKey = newFrontAlv[ly.layerKey + '_' + svgInputKey]
                    let found = changeSvgKeys.find(csk => csk.from === currentSvgKey)
                    if (found) {
                        newFrontAlv['new_' + ly.layerKey + '_' + svgInputKey] = found.to
                        delete newFrontAlv[ly.layerKey + '_' + svgInputKey]
                    }
                }
                if (newRearAlv[ly.layerKey + '_' + svgInputKey]) {
                    let currentSvgKey = newRearAlv[ly.layerKey + '_' + svgInputKey]
                    let found = changeSvgKeys.find(csk => csk.from === currentSvgKey)
                    if (found) {
                        newRearAlv['new_' + ly.layerKey + '_' + svgInputKey] = found.to
                        delete newRearAlv[ly.layerKey + '_' + svgInputKey]
                    }
                }
            }
        })
        for (const [key, value] of Object.entries(newFrontAlv)) {
            if (key.startsWith('new_')) {
                let fixedKey = key.replace('new_', '')
                newFrontAlv[fixedKey] = value
                delete newFrontAlv[key]
            }
        }
        for (const [key, value] of Object.entries(newRearAlv)) {
            if (key.startsWith('new_')) {
                let fixedKey = key.replace('new_', '')
                newRearAlv[fixedKey] = value
                delete newRearAlv[key]
            }
        }
        counterSideActive.front = JSON.stringify(newFrontAlv)
        counterSideActive.rear = JSON.stringify(newRearAlv)

        return counterSideActive
    }

    const processNewSvgListsIntoLayers = (layers, stateImagesLayerList, stateSvgsLayerList) => {
        let _layers = JSON.parse(JSON.stringify(layers))
        _layers.forEach(sLayer => {
            if (stateImagesLayerList.length > 0) {
                if (Utility.originalLayerNameByLayer(state.layers, sLayer) === 'custom images') {
                    let input = sLayer.inputs.find(li => li.named === 'svgKey')
                    if (input) {
                        input.list = stateImagesLayerList
                        sLayer.layerHidden = 0
                    }
                }
            }
            if (stateSvgsLayerList.length > 0) {
                if (Utility.originalLayerNameByLayer(state.layers, sLayer) === 'custom svgs') {
                    let input = sLayer.inputs.find(li => li.named === 'svgKey')
                    if (input) {
                        input.list = stateSvgsLayerList
                        sLayer.layerHidden = 0
                    }
                }
            }
        })

        return _layers
    }

    const updateActiveLayerValues = (activeLayerValues, changeSvgKeys, requiredInputKey) => {
        const updatedActiveLayerValues = { ...activeLayerValues };
        for (const [key, value] of Object.entries(activeLayerValues)) {
            const splitKey = key.split('_');
            if (splitKey.length === 2) {
                const inputKey = Number(splitKey[1]);
                if (inputKey === requiredInputKey) {
                    const found = changeSvgKeys.find(fromTo => fromTo.from === value);
                    if (found) {
                        updatedActiveLayerValues[key] = found.to;
                    }
                }
            }
        }
        return updatedActiveLayerValues;
    };
    
    const handleIncomingOldVersionSheet = data => {
        let updatedStateLayers = JSON.parse(JSON.stringify(state.layers));
        let layerDupeTypesAvailable = Utility.layerTypesInventory(state.layers, true);
        let slots = data.slots;
        let nextLayerKey = state.layers.reduce((a, b) => Number(a.layerKey) > Number(b.layerKey) ? a : b).layerKey;
        let dupeLayersNeeded = {};
        let activeSlots = slots.filter(slot => slot.counterState !== null);
    
        activeSlots.forEach(slot => {
            let slotActiveLayerValues = JSON.parse(JSON.stringify(slot.counterState.activeLayerValues));
            slotActiveLayerValues = removeSpacersActiveLayerValues(slotActiveLayerValues);
            slot.originalSlotLayers = JSON.parse(JSON.stringify(slot.counterState.layers));
            let slotLayers = JSON.parse(JSON.stringify(slot.counterState.layers)).filter(lr => lr.layerActive === 1);
            slotLayers = removeSpacersLayers(slotLayers);
    
            let layerDupeTypesAvailableCopy = JSON.parse(JSON.stringify(layerDupeTypesAvailable));
            let incomingDupeLayersTypes = Utility.layerTypesInventory(slotLayers, true).filter(idl => idl.layerKeys.length > 0);
            let changeLayerKeys = [];
    
            incomingDupeLayersTypes.forEach(idl => {
                let foundTypeEntry = layerDupeTypesAvailableCopy.find(lta => lta.type === idl.type);
                if (foundTypeEntry) {
                    while (idl.layerKeys.length > 0 && foundTypeEntry.layerKeys.length > 0) {
                        let matchedToLayerKey = foundTypeEntry.layerKeys.shift();
                        let fromLayerKey = idl.layerKeys.shift();
                        changeLayerKeys.push({ from: fromLayerKey, to: matchedToLayerKey });
                    }
                }
            });
    
            layerDupeTypesAvailableCopy = layerDupeTypesAvailableCopy.filter(ldt => ldt.layerKeys.length > 0);
            incomingDupeLayersTypes = incomingDupeLayersTypes.filter(idl => idl.layerKeys.length > 0);
            slot.needsAssignmentLayers = incomingDupeLayersTypes;
    
            incomingDupeLayersTypes.forEach(dl => {
                let type = dl.type;
                let amount = dl.layerKeys.length;
                dupeLayersNeeded[type] = Math.max(dupeLayersNeeded[type] || 0, amount);
            });
    
            changeLayerKeys.forEach(fromTo => {
                slotLayers.forEach(idl => {
                    if (idl.layerKey === fromTo.from) {
                        idl.layerKey = 'new_' + fromTo.to;
                    }
                });
                for (const [key, value] of Object.entries(slotActiveLayerValues)) {
                    if (key.startsWith(fromTo.from + '_')) {
                        let inputKey = key.split('_')[1];
                        let newKey = 'new_' + fromTo.to + '_' + inputKey;
                        slotActiveLayerValues[newKey] = value;
                        delete slotActiveLayerValues[key];
                    }
                }
            });
    
            for (const [key, value] of Object.entries(slotActiveLayerValues)) {
                if (key.startsWith('new_')) {
                    let fixedKey = key.replace('new_', '');
                    slotActiveLayerValues[fixedKey] = value;
                    delete slotActiveLayerValues[key];
                }
            }
    
            slot.counterState.layers = slotLayers;
            slot.counterState.activeLayerValues = slotActiveLayerValues;
        });
    
        let createdLayers = [];
    
        for (const [type, amount] of Object.entries(dupeLayersNeeded)) {
            for (let i = 0; i < amount; i++) {
                nextLayerKey++;
                let allLayers = [...state.layers, ...createdLayers];
                let existingLayer = state.layers.find(sl => sl.layerName === type);
                let layerOrder = existingLayer.layerOrder + 0.5;
                let dupeLayer = createDuplicateLayer(type, nextLayerKey, layerOrder, allLayers);
                createdLayers.push(dupeLayer);
            }
        }
    
        activeSlots.forEach(slot => {
            let changeLayerKeysToCreated = [];
            let createdLayersForSlot = JSON.parse(JSON.stringify(createdLayers));
            slot.needsAssignmentLayers.forEach(nal => {
                let neededType = nal.type;
                nal.layerKeys.forEach(fromLayerKey => {
                    let createdLayer = createdLayersForSlot.find(cl => cl.layerName.replace(/[0-9]/g, '') === neededType);
                    if (createdLayer) {
                        createdLayer = JSON.parse(JSON.stringify(createdLayer));
                        createdLayersForSlot = createdLayersForSlot.filter(clfs => clfs.layerKey !== createdLayer.layerKey);
                        let toLayerKey = createdLayer.layerKey;
                        changeLayerKeysToCreated.push({ from: fromLayerKey, to: toLayerKey });
                    }
                });
            });
    
            let slotActiveLayerValues = slot.counterState.activeLayerValues;
            changeLayerKeysToCreated.forEach(fromTo => {
                slot.counterState.layers.forEach(idl => {
                    if (idl.layerKey === fromTo.from) {
                        idl.layerKey = 'new_' + fromTo.to;
                    }
                });
                for (const [key, value] of Object.entries(slotActiveLayerValues)) {
                    if (key.startsWith(fromTo.from + '_')) {
                        let inputKey = key.split('_')[1];
                        let newKey = 'new_' + fromTo.to + '_' + inputKey;
                        slotActiveLayerValues[newKey] = value;
                        delete slotActiveLayerValues[key];
                    }
                }
            });
    
            slot.counterState.layers.forEach(idl => {
                if (typeof idl.layerKey === 'string' && idl.layerKey.startsWith('new_')) {
                    idl.layerKey = Number(idl.layerKey.replace('new_', ''));
                }
            });
    
            for (const [key, value] of Object.entries(slotActiveLayerValues)) {
                if (key.startsWith('new_')) {
                    let fixedKey = key.replace('new_', '');
                    slotActiveLayerValues[fixedKey] = value;
                    delete slotActiveLayerValues[key];
                }
            }
    
            slot.counterState.activeLayerValues = slotActiveLayerValues;
        });
    
        let customIncomingSvgsDetected = [];
        let customIncomingImagesDetected = [];
        let changeSvgKeys = [];
        let useKey = state.svgs.reduce((a, b) => Number(a.svgKey) > Number(b.svgKey) ? a : b).svgKey;
        useKey = useKey < 3000 ? 3000 : useKey + 1;
        let cleanedStateSvgs = state.svgs.filter(ssvg => ssvg.svgKey > useKey).map(csvg => ({
            ...csvg,
            svgCode: Utility.stripXmlTags(csvg.svgCode)
        }));
    
        slots.forEach(slot => {
            let slotCustomImagesLayer = slot.originalSlotLayers.find(ly => ly.layerName === 'custom images');
            let slotCustomSvgsLayer = slot.originalSlotLayers.find(ly => ly.layerName === 'custom svgs');
            let imagesInput = slotCustomImagesLayer.inputs.find(li => li.named === 'svgKey');
            let svgsInput = slotCustomSvgsLayer.inputs.find(li => li.named === 'svgKey');
            let incomingSvgs = slot.counterState.customSvgs;
    
            incomingSvgs.forEach(svg => {
                if (imagesInput.list.includes(svg.svgKey) && !customIncomingImagesDetected.find(imagesSvg => imagesSvg.svgKey === svg.svgKey)) {
                    customIncomingImagesDetected.push(svg);
                }
                if (svgsInput.list.includes(svg.svgKey) && !customIncomingSvgsDetected.find(svgsSvg => svgsSvg.svgKey === svg.svgKey)) {
                    customIncomingSvgsDetected.push(svg);
                }
            });
        });
    
        let deleteList = [];
        cleanedStateSvgs.forEach(cssvg => {
            customIncomingImagesDetected.forEach((incomingImageSvg, c) => {
                let stateCheck = Utility.stripXmlTags(cssvg.svgCode.replaceAll(cssvg.uniquePrepend, '')).replace(/[\r\n]+/gm, " ");
                let incomingCheck = Utility.stripXmlTags(incomingImageSvg.svgCode.replaceAll(incomingImageSvg.uniquePrepend, '')).replace(/[\r\n]+/gm, " ");
                let rawQ = stringComparison.cosine.similarity(stateCheck, incomingCheck);
                if (rawQ > 0.95 && (Utility.extractNativeId(incomingCheck) === Utility.extractNativeId(stateCheck) || incomingImageSvg.svgName === cssvg.svgName)) {
                    if (cssvg.svgKey !== incomingImageSvg.svgKey) {
                        changeSvgKeys.push({ from: incomingImageSvg.svgKey, to: cssvg.svgKey, type: 'image' });
                    }
                    deleteList.push(incomingImageSvg.svgKey);
                }
            });
            customIncomingImagesDetected = customIncomingImagesDetected.filter(iid => !deleteList.includes(iid.svgKey));
    
            customIncomingSvgsDetected.forEach((incomingSvg, c) => {
                let stateCheck = Utility.stripXmlTags(cssvg.svgCode.replaceAll(cssvg.uniquePrepend, '')).replace(/[\r\n]+/gm, " ");
                let incomingCheck = Utility.stripXmlTags(incomingSvg.svgCode.replaceAll(incomingSvg.uniquePrepend, '')).replace(/[\r\n]+/gm, " ");
                let rawQ = stringComparison.cosine.similarity(stateCheck, incomingCheck);
                if (rawQ > 0.95 && (Utility.extractNativeId(incomingCheck) === Utility.extractNativeId(stateCheck) || incomingSvg.svgName === cssvg.svgName)) {
                    if (cssvg.svgKey !== incomingSvg.svgKey) {
                        changeSvgKeys.push({ from: incomingSvg.svgKey, to: cssvg.svgKey, type: 'svg' });
                    }
                    deleteList.push(incomingSvg.svgKey);
                }
            });
            customIncomingSvgsDetected = customIncomingSvgsDetected.filter(iid => !deleteList.includes(iid.svgKey));
        });
    
        let customImagesLayer = state.layers.find(ly => ly.layerName === 'custom images');
        let customSvgsLayer = state.layers.find(ly => ly.layerName === 'custom svgs');
        let imagesRequiredInputKey = customImagesLayer.layerActiveRequiredInputKey;
        let svgsRequiredInputKey = customSvgsLayer.layerActiveRequiredInputKey;
    
        activeSlots.forEach(slot => {
            slot.counterState.activeLayerValues = updateActiveLayerValues(slot.counterState.activeLayerValues, changeSvgKeys, imagesRequiredInputKey);
            slot.counterState.activeLayerValues = updateActiveLayerValues(slot.counterState.activeLayerValues, changeSvgKeys, svgsRequiredInputKey);
        });
    
        let nextSvgKey = Math.max(...state.svgs.map(svg => parseInt(svg.svgKey)), 0);
        let importSvgs = [];
        changeSvgKeys = [];
        customIncomingImagesDetected.forEach(svg => {
            let xlinkStart = svg.svgCode.indexOf('xlink:href');
            if (xlinkStart > -1) {
                let xlinkEnd = svg.svgCode.indexOf('></image></svg>');
                if (xlinkEnd > xlinkStart) {
                    let newImageSrc = svg.svgCode.substring(xlinkStart, xlinkEnd);
                    newImageSrc = '<image width="100%" height="100%" ' + newImageSrc + '/>';
                    svg.svgCode = newImageSrc;
                }
            }
            nextSvgKey++;
            changeSvgKeys.push({ from: svg.svgKey, to: nextSvgKey, type: 'image' });
            svg.svgKey = nextSvgKey;
            importSvgs.push(svg);
        });
    
        customIncomingSvgsDetected.forEach(svg => {
            svg.svgCode = svg.svgCode.replace(/\bwidth="(\d+(\.\d*)?|\.\d+)"/, 'width="100%"');
            svg.svgCode = svg.svgCode.replace(/\bheight="(\d+(\.\d*)?|\.\d+)"/, 'height="100%"');
            nextSvgKey++;
            changeSvgKeys.push({ from: svg.svgKey, to: nextSvgKey, type: 'svg' });
            svg.svgKey = nextSvgKey;
            importSvgs.push(svg);
        });
    
        activeSlots.forEach(slot => {
            slot.counterState.activeLayerValues = updateActiveLayerValues(slot.counterState.activeLayerValues, changeSvgKeys, imagesRequiredInputKey);
            slot.counterState.activeLayerValues = updateActiveLayerValues(slot.counterState.activeLayerValues, changeSvgKeys, svgsRequiredInputKey);
        });
    
        let stateCustomImagesLayer = updatedStateLayers.find(ly => ly.layerName === 'custom images');
        let stateCustomSvgsLayer = updatedStateLayers.find(ly => ly.layerName === 'custom svgs');
        let imagesList = [];
        let svgsList = [];
        if (stateCustomImagesLayer) {
            let input = stateCustomImagesLayer.inputs.find(li => li.named === 'svgKey');
            if (input && input.list && Array.isArray(input.list)) {
                changeSvgKeys.forEach(fromTo => {
                    if (fromTo.type === 'image' && !input.list.includes(fromTo.to)) {
                        input.list.push(fromTo.to);
                    }
                });
                imagesList = [...input.list];
            }
        }
        if (stateCustomSvgsLayer) {
            let input = stateCustomSvgsLayer.inputs.find(li => li.named === 'svgKey');
            if (input && input.list && Array.isArray(input.list)) {
                changeSvgKeys.forEach(fromTo => {
                    if (fromTo.type === 'svg' && !input.list.includes(fromTo.to)) {
                        input.list.push(fromTo.to);
                    }
                });
                svgsList = [...input.list];
            }
        }
    
        let customImagesLayers = Utility.getDupeCustomImagesLayers(updatedStateLayers);
        let customSvgsLayers = Utility.getDupeCustomSvgsLayers(updatedStateLayers);
        if (imagesList.length > 0) {
            customImagesLayers.forEach(cil => {
                let input = cil.inputs.find(li => li.named === 'svgKey');
                if (input && input.list) {
                    input.list = imagesList;
                }
            });
            let imagesLayer = updatedStateLayers.find(usl => usl.layerName === 'custom images');
            if (imagesLayer) {
                imagesLayer.layerHidden = 0;
            }
        }
        if (svgsList.length > 0) {
            customSvgsLayers.forEach(csl => {
                let input = csl.inputs.find(li => li.named === 'svgKey');
                if (input && input.list) {
                    input.list = svgsList;
                }
            });
            let svgsLayer = updatedStateLayers.find(usl => usl.layerName === 'custom svgs');
            if (svgsLayer) {
                svgsLayer.layerHidden = 0;
            }
        }
        if (importSvgs.length > 0) {
            actions.svgs([...state.svgs, ...importSvgs]);
        }
    
        data.slots.forEach(slot => {
            let layerSvgs = slot.counterState.layers.map(ly => ly.svg);
            slot.counterState.combinedSvg = combineDirectSvgs(layerSvgs);
            slot.counterState.layers.forEach(ly => delete ly.svg);
        });
    
        actions.layers([...updatedStateLayers, ...createdLayers]);
    
        data.slots.forEach(slot => {
            let customImageSvgKeys = [];
            let customSvgKeys = [];
            for (const [key, value] of Object.entries(slot.counterState.activeLayerValues)) {
                let splitKey = key.split('_');
                if (Number(splitKey[1]) === imagesRequiredInputKey && !customImageSvgKeys.includes(value)) {
                    customImageSvgKeys.push(value);
                }
                if (Number(splitKey[1]) === svgsRequiredInputKey && !customSvgKeys.includes(value)) {
                    customSvgKeys.push(value);
                }
            }
    
            let fonts = detectFontsInALV(slot.counterState.activeLayerValues);
    
            slot.counterState = {
                frontSvg: slot.counterState.combinedSvg,
                rearSvg: slot.counterState.combinedSvg,
                package: {
                    counterSideActive: {
                        active: "front",
                        front: JSON.stringify(slot.counterState.activeLayerValues),
                        rear: JSON.stringify(slot.counterState.activeLayerValues)
                    },
                    customImageSvgKeys: customImageSvgKeys,
                    customSvgKeys: customSvgKeys,
                    fonts: fonts,
                    layers: slot.counterState.layers,
                    layersEffects: []
                }
            };
            slot.rearNumber = 0;
            slot.rearPosition = { col: 0, row: 0 };
            delete slot.svg;
            delete slot.needsAssignmentLayers;
            delete slot.updatedSvg;
            delete slot.originalSlotLayers;
        });
    
        let newSheetSettings = data.sheetSettings;
        newSheetSettings.sheetSide = 'front';
        let updated = {
            compressedSvg: null,
            customSvgsData: [],
            dateSaved: data.dateSaved,
            fontsData: [],
            hash: null,
            name: data.name,
            sheetSettings: newSheetSettings,
            slots: data.slots,
            type: "sheet",
            version: 3
        };
    
        return updated;
    };

    const createDuplicateLayer = (dupeType, layerKey, layerOrder, newlayers) => {
        // get the layer we are duping from.
        let layers = JSON.parse(JSON.stringify(newlayers))
        let layer = layers.find(sl => sl.layerName === dupeType)
        if (layer) {
            let newLayer = { ...layer } // create a new layer object to avoid mutating the original one
            newLayer.layerOrder = layerOrder + 0.5 // old system has one less layer
            newLayer.parentLayerKey = layer.layerKey
            newLayer.layerKey = layerKey
            newLayer.layerHidden = 0
            newLayer.layerActive = 0 // the layer is for any slots that the user clicks edit on. They wont be on before that.
    
            // check name to avoid dupe names
            let incNum = 2
            let fixedName = newLayer.layerName
            while ( newlayers.find(sl => sl.layerName === fixedName)) { // eslint-disable-line no-loop-func
                fixedName = newLayer.layerName + incNum
                incNum++
                if (incNum > 100) {
                    break
                }
                 
            }
            newLayer.layerName = fixedName
    
            return newLayer
        }
    
        return null
    }

    const combineDirectSvgs = svgs => {
        let combinedSvgs = '<svg id="" xmlns="http://www.w3.org/2000/svg" width="1000" height="1000">';
        svgs.forEach(svg => {
            if (svg) {
                if (svg.includes("-120, -120, 240, 240")) {
                    svg = svg.replace("-120, -120, 240, 240", "-100, -100, 200, 200");
                }
                if (svg.includes("0, 0, 240, 240")) {
                    const matrixIndex = svg.indexOf("<g transform");
                    if (matrixIndex > 0) {
                        svg = svg.replace("<g transform", '<g transform="matrix(1.2, 0, 0, 1.2, -24, -24)"><g transform');
                    }
                    const lastSvgTag = svg.lastIndexOf('</svg>');
                    svg = `${svg.substring(0, lastSvgTag)}</g></svg>`;
                }
            }
            combinedSvgs += svg;
        });
        combinedSvgs += '</svg>';
    
        const hash = Utility.cyrb53(combinedSvgs, 'snapcounter_sheet_v3');
        combinedSvgs = combinedSvgs.replace('id=""', `id="combined_${hash}"`);
    
        return combinedSvgs;
    };

    const removeSpacersActiveLayerValues = _alv => {
        let alv = { ..._alv }
        for (const [key] of Object.entries(alv)) {
            if (key.endsWith('_5') || key.endsWith('_59') || key.endsWith('_72')) {
                delete alv[key] // remove the useless deprecated 'spacer' entry
            }
        }

        return alv
    }

    const removeSpacersLayers = _layers => {
        let layers = [..._layers]
        // remove spacers from layer.inputs
        layers.forEach(ly => {
            ly.inputs = ly.inputs.filter(inp => inp.inputKey !== 5 && inp.inputKey !== 59 && inp.inputKey !== 72)
            ly.layerInputKeys = ly.layerInputKeys.filter(inputKey => inputKey !== 5 && inputKey !== 59 && inputKey !== 72)
        })

        return layers
    }

    return (
        <div>
            <div className={sheetOperationsOpen ? 'sheet-operations opened' : 'sheet-operations'}>

                <div className={sheetOperationsOpen ? 'controls-opener opened' : 'controls-opener closed'}>
                    <div className="title">sheet operations</div>
                    <button className={sheetOperationsOpen ? 'standard-button yellow closed' : 'standard-button yellow'} onClick={() => setSheetOperationsOpen(true)}>open</button>
                    <button className={sheetOperationsOpen ? 'standard-button red' : 'standard-button red closed'} onClick={() => setSheetOperationsOpen(false)}>close</button>
                </div>

                <div className={sheetOperationsOpen ? 'controls' : 'display-none'}>

                    <div className="controls-container">
                        <div className="options">
                            <div className="title">
                                <div className="popupable" data-popup="Save sheet"
                                    onClick={actions.popupInfoActive}>save sheet</div>
                            </div>
                            <div className="">
                                <input type="checkbox" onChange={() => setSaveOption('app')} id="in-app" checked={saveOption === 'app' ? 'checked' : ''} /> <label htmlFor="in-app">in app</label>
                                <input type="checkbox" onChange={() => setSaveOption('file')} id="to-file" checked={saveOption === 'file' ? 'checked' : ''} /> <label htmlFor="to-file">to file</label>
                            </div>
                        </div>
                        <div className="options">
                            <div className="title">
                                export sheet:
                            </div>
                            <div className="">
                                <input type="checkbox" onChange={() => setSaveOption('svg')} id="to-svg" checked={saveOption === 'svg' ? 'checked' : ''} /> <label htmlFor="to-svg">to svg</label>
                                <input type="checkbox" onChange={() => setSaveOption('png')} id="to-png" checked={saveOption === 'png' ? 'checked' : ''} /> <label htmlFor="to-png">to png</label>
                            </div>
                        </div>

                        <div className="actions">
                            <div className={saveActionsResult ? 'display-none' : ''}>
                                name: <input id="saveActionsSaveName" type="text" onChange={onSaveName} value={saveName} />
                                <button className={saveName ? 'standard-button blue' : 'standard-button disabled'}
                                    onClick={saveName ? saveSheet : null}
                                >submit</button>
                            </div>
                            <div className={saveActionsResult ? 'saved' : 'display-none'}>
                                <span>{saveActionsResult}</span>
                                <button className="standard-button yellow"
                                    onClick={saveActionsOk}
                                >ok</button>
                            </div>
                        </div>
                    </div>




                    <div className="controls-container load">
                        <div className="title-with-button">
                            <div className="popupable" data-popup="Load sheet"
                                onClick={actions.popupInfoActive}>load sheet</div>

                            <button className="standard-button yellow" onClick={clickClearSheet}>clear sheet</button>

                        </div>

                        {loadSheetErrorMessage === '' ?

                            <div className="load-from">

                                <span>load from:</span>
                                <input type="radio"
                                    onChange={() => onLoadFrom('app')}
                                    id="loadFrom_app"
                                    checked={loadFrom === 'app'}
                                    value="app"
                                    onClick={() => onLoadFrom('app')}
                                /> <label htmlFor="loadFrom_app">app</label>

                                <input type="radio"
                                    onChange={() => onLoadFrom('file')}
                                    id="loadFrom_file"
                                    checked={loadFrom === 'file'}
                                    value="file"
                                    onClick={() => onLoadFrom('file')}
                                /> <label htmlFor="loadFrom_file">file</label>

                                <div className="selects">
                                    {loadFrom === 'app' ?
                                        <div >
                                            <select id="loadSheet" value={selectedSheetHash} onChange={loadSheetFromApp}>
                                                <option key="none" value="">choose sheet</option>
                                                {savedSheetsMetadata ?
                                                    savedSheetsMetadata.map((ss, index) => <option key={index} value={ss.hash}>{ss.name}</option>)
                                                    : ''}
                                            </select>
                                        </div>
                                        : <div>
                                            <div className="load-from-file">
                                                <input type="file" id="sheet_file" accept=".scz" onChange={loadSheetFromFile} />
                                            </div>
                                        </div>}
                                </div>

                            </div>
                            :
                            <div className="error">
                                <div>{loadSheetErrorMessage}</div>
                                <button className="standard-button green" onClick={() => setLoadSheetErrorMessage('')}>Ok</button>
                            </div>
                        }

                    </div>

                    <div className="controls-container">
                        <div className="title">
                            <div className="popupable" data-popup="Cutting guides"
                                onClick={actions.popupInfoActive}>cutting guides</div>
                        </div>
                        <div className="options cutting-guides">



                            <input type="radio"
                                onChange={() => onChangeGuideType('none')}
                                id="cuttingGuide_none"
                                checked={sheetParameters.guideType === 'none'}
                                value="none"
                                onClick={() => onChangeGuideType('none')}
                            /> <label htmlFor="cuttingGuide_none">none</label>

                            <input type="radio"
                                onChange={() => onChangeGuideType('edges')}
                                id="cuttingGuide_edges"
                                checked={sheetParameters.guideType === 'edges'}
                                value="edges"
                                onClick={() => onChangeGuideType('edges')}
                            /> <label htmlFor="cuttingGuide_edges">edges</label>


                        </div>
                        <div className="options cutting-guides">

                            <input type="radio"
                                onChange={() => onChangeGuideType('corners')}
                                id="cuttingGuide_corners"
                                checked={sheetParameters.guideType === 'corners'}
                                value="corners"
                                onClick={() => onChangeGuideType('corners')}
                            /> <label htmlFor="cuttingGuide_corners">corners</label>

                            <input type="radio"
                                onChange={() => onChangeGuideType('lines')}
                                id="cuttingGuide_lines"
                                checked={sheetParameters.guideType === 'lines'}
                                value="lines"
                                onClick={() => onChangeGuideType('lines')}
                            /> <label htmlFor="cuttingGuide_lines">lines</label>


                        </div>
                    </div>






                    <div className="controls-container">
                        <div className="title-with-button">
                            <div className="popupable" data-popup="Printable area" onClick={evt => actions.popupInfoActive(evt)}>printable area</div>
                            <button className={printableAreaIsAtDefaults() ? 'standard-button disabled' : 'standard-button blue'} onClick={resetPrintableArea}>reset</button>
                        </div>
                        <div className="printable-area-settings">
                            <div><div>width:</div>  <input type="text" id="printableArea_width" className={validPrintableAreaWidth() ? '' : 'warning'} onChange={onChangePrintableArea} value={printableAreaMM_width} /> mm</div>
                            <div><div>length:</div> <input type="text" id="printableArea_height" className={validPrintableAreaHeight() ? '' : 'warning'} onChange={onChangePrintableArea} value={printableAreaMM_height} /> mm</div>
                        </div>
                    </div>











                </div>
            </div>
            <div className="temp-sheet">
                <svg id="build_sheet" width="1200" height="1553" />
            </div>
        </div>
    );
}
export default SheetOperations
/*

<g xmlns="http://www.w3.org/2000/svg" data-number="0" id="sheet_number_0" transform="matrix(0.075,0,0,0.075,-7.5,-20.7375)">
    <svg xmlns="http://www.w3.org/2000/svg" id="l1lvi8r3y_drawLayer1" class="counter-svg" viewBox="-120, -120, 240, 240"><defs/><g id="l1lvi8r3y_layerGroup_1"><rect x="-100" y="-100" width="200" height="200" fill="#11ff33" style="stroke-width: 0;"/></g></svg>
    <svg xmlns="http://www.w3.org/2000/svg" id="l2l89oqjz_drawLayer2" class="counter-svg" viewBox="-120, -120, 240, 240"><defs><mask id="l2l89oqjz_maskSlx4z80kd1nq"><g><rect x="-50" y="-42.5" width="100" height="85" rx="0" ry="0" stroke="#ffffff" fill="#ffffff" style="stroke-width: 2; opacity: 1;"/><rect x="-40" y="42.5" width="80" height="30" fill="#ffffff" stroke="#ffffff" style=""/></g></mask></defs><g mask="url('#l2l89oqjz_maskSlx4z80kd1nq')" id="l2l89oqjz_layerGroup_2" style="pointer-events: visiblepainted;"><rect x="-50" y="-42.5" width="100" height="85" rx="0" ry="0" stroke="#000000" fill="#ffffff" style="stroke-width: 2;"/><g><line x1="-49.238" x2="49.238" y1="41.85" y2="-41.85" stroke="#000000" style="stroke-width: 2; stroke-linecap: round;"/></g><rect x="-50" y="-42.5" width="100" height="85" rx="0" ry="0" stroke="#000000" fill="none" style="stroke-width: 2;"/></g></svg>
</g>


<svg id="" xmlns="http://www.w3.org/2000/svg" width="1000" height="1000">
   <svg xmlns="http://www.w3.org/2000/svg" width="100%" height="100%" id="pbljz7_svgLayer1" viewBox="-100, -100, 200, 200"><defs/><g><rect x="-100" y="-100" width="200" height="200" fill="#11ff33" style="stroke-width: 0;"/></g></svg>
   <svg xmlns="http://www.w3.org/2000/svg" width="100%" height="100%" id="pypylk_svgLayer3" viewBox="-100, -100, 200, 200"><defs/><g style="pointer-events: visiblepainted;"><ellipse cx="-24.37" cy="0" rx="10" ry="10" stroke="none" fill="#000000" style=""/><ellipse cx="0" cy="0" rx="10" ry="10" stroke="none" fill="#000000" style=""/><ellipse cx="24.38" cy="0" rx="10" ry="10" stroke="none" fill="#000000" style=""/></g></svg>
   <svg xmlns="http://www.w3.org/2000/svg" width="100%" height="100%" id="pz7mey_svgLayer23" viewBox="-100, -100, 200, 200"><defs/><g transform="matrix(1,0,0,1,7.4,-37.6)" style="pointer-events: visiblepainted;"><ellipse cx="0" cy="0" rx="8" ry="8" stroke="#000000" fill="none" style="stroke-width: 3.5;"/><line x1="-8" x2="8" y1="8" y2="-8" stroke="#000000" style="stroke-width: 3.5; stroke-linecap: round;"/></g></svg>
</svg>


*/