import React, { useEffect, useState, useContext } from 'react'
import { StoreContext } from "../../context/StoreContext"
import Snap from 'snapsvg-cjs'
import './GridDisplay.scss'
const GridDisplay = ({ showingGrid, usingGridColor, threeDeeActive }) => {
    const [paper, setPaper] = useState(null)
    const [zIndex, setZindex] = useState(0)
    const { state } = useContext(StoreContext)

    useEffect(() => {
        let _paper = Snap("#grid_display_svg");
        _paper.attr({ id: "grid_display_svg", viewBox: "-120, -120, 240, 240" });
        setPaper(_paper)
    }, [])

    useEffect(() => {
        if (paper && showingGrid) {
            // get max z-index 
            adjustZindex()
            drawOverlay()
        }
        else {
            if (paper) {
                paper.clear()
            }
        }
    }, [showingGrid]) // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        adjustZindex()
    }, [state.layers]) // eslint-disable-line react-hooks/exhaustive-deps

    const adjustZindex = () => {
        if (state.layers.length > 0) {
            let maxZindex = Math.max.apply(null, state.layers.map(ly => ly.layerOrder))
            if (maxZindex) {
                setZindex(maxZindex + 1)
            }
            else {
                setZindex(100)
            }
        }
    }

    useEffect(() => {
        if (showingGrid) {
            if (paper) {
                paper.clear()
                drawOverlay()
            }
        }
    }, [usingGridColor]) // eslint-disable-line react-hooks/exhaustive-deps

    const drawOverlay = () => {
        // default coloring is the light theme.
        let colorX = ['#fcf', '#fae']
        let colorY = ['#fcf', '#fae']
        let circleBiggerColor = '#f71'
        let circleSmallerColor = '#5ff'
        let textColor = '#fff'
        if (usingGridColor === 'dark') {
            colorX = ['#000', '#229']
            colorY = ['#600', '#022']
            circleBiggerColor = '#840'
            circleSmallerColor = '#02a'
            textColor = '#aaa'
        }

        for (var x = -100; x <= 100; x += 5) {
            if (x % 10) {
                paper.line(x, -100, x, 100).attr({ "strokeWidth": 0.7, stroke: colorX[0], opacity: 0.4 })
            }
            else {
                if (x === 0) {
                    paper.line(x, -100, x, 100).attr({ "strokeWidth": 0.8, stroke: colorX[0], opacity: 0.8 })
                }
                else {
                    paper.line(x, -100, x, 100).attr({ "strokeWidth": 0.5, stroke: colorX[1], opacity: 0.6 })
                }
               
                paper.text(x - 0.1, -106.5, x ? x : '0').attr({ "textAnchor": "middle", "dominant-baseline": "central", "fontSize": "5", "fontWeight": "normal", "fontFamily": "sans-serif", stroke: "none", fill: textColor })
            }
        }

        for (var y = -100; y <= 100; y += 5) {
            if (y % 10) {
                paper.line(-100, y, 100, y).attr({ "strokeWidth": 0.7, stroke: colorY[0], opacity: 0.6 })
            }
            else {
                if (y === 0) {
                    paper.line(-100, y, 100, y).attr({ "strokeWidth": 0.8, stroke: colorY[1], opacity: 1 })
                }
                else {
                    paper.line(-100, y, 100, y).attr({ "strokeWidth": 0.5, stroke: colorY[1], opacity: 0.7 })
                }
               
                paper.text(-105, y - 0.2, y ? y : '0').attr({ "textAnchor": "end", "dominant-baseline": "central", "fontSize": "5", "fontWeight": "normal", "fontFamily": "sans-serif", stroke: "none", fill: textColor })
            }
        }

        paper.circle(0, 0, 10).attr({ "strokeWidth": 0.4, stroke: circleBiggerColor, fill: "none" })
        paper.circle(0, 0, 5).attr({ "strokeWidth": 0.4, stroke: circleSmallerColor, fill: "none" })
    }


    return (
        <div className={showingGrid && threeDeeActive === false ? 'grid-display' : 'grid-display display-none'} style={{ zIndex: zIndex }}>
            <svg id="grid_display_svg" className="grid-display-svg" width="100%" height="100%" />
        </div>
    );
}

export default GridDisplay