import Utility from '../objects/Utility'
import Snap from 'snapsvg-cjs'

const CustomSvgs = {

    draw: (g, settings, svgs, parentPaper) => {
        let scaleWidth = 0
        let scaleHeight = 0
        let xpos = 0
        let ypos = 0
        let opacity = ''
        let rotation = 0
        let svgKey = ''
        let derivedValues = {}

        settings.forEach(setting => {
            switch (setting.name) {
                case 'xy position':
                    if (Array.isArray(setting.value) === false || setting.value.length !== 2) {
                        setting.value = [1, 1]
                    }
                    if (isNaN(setting.value[0])) {
                        setting.value[0] = 1
                    }
                    if (isNaN(setting.value[1])) {
                        setting.value[1] = 1
                    }
                    derivedValues.xpos = Utility.roundFloat(setting.value[0], 2)
                    derivedValues.ypos = Utility.roundFloat(setting.value[1], 2)
                    break
                case 'dimensions':
                    if (!setting.value || Array.isArray(setting.value) === false) {
                        derivedValues.width = 55
                        derivedValues.height = 55
                    }
                    else {
                        derivedValues.width = Utility.roundFloat(setting.value[0], 1)
                        derivedValues.height = Utility.roundFloat(setting.value[1], 1)
                    }
                    break
                case 'svgKey': derivedValues.svgKey = parseInt(setting.value)
                    derivedValues.type = setting.comment
                    break
                case 'opacity': derivedValues.opacity = Utility.roundFloat(setting.value / 100, 3)
                    break
                case 'rotation': derivedValues.rotation = Utility.roundFloat(setting.value, 2)
                    break
                case 'spacer': break
                default: console.warn('we seem to have missed one: ', setting.name)
            }
        })
        if (derivedValues.width === undefined || derivedValues.height === undefined) {
            derivedValues.width = 66
            derivedValues.height = 66
        }
        scaleWidth = derivedValues.width
        scaleHeight = derivedValues.height
        xpos = derivedValues.xpos
        ypos = derivedValues.ypos
        rotation = derivedValues.rotation
        svgKey = derivedValues.svgKey
        let svgObj = svgs.find(svg => svg.svgKey === svgKey)
        if (!svgObj) {
            console.warn('could not find custom svgs svg key : ', svgKey)
            return
        }

        let svgCode = svgObj.svgCode
        let { alteredString, prepend } = CustomSvgs.alterIds(svgCode)
        svgCode = alteredString
        svgObj.uniquePrepend = prepend

        // legacy
        if (svgObj.viewBox) {
            svgCode = '<svg viewBox="' + svgObj.viewBox + '">' + svgCode + '</svg>'
        }

        opacity = derivedValues.opacity
        let tadjusts = [0, 0]
        if (svgCode) {
            tadjusts = CustomSvgs.drawSvg(g, svgCode)
        }

        if (opacity < 1) {
            g.attr({ "opacity": opacity })
        }

        CustomSvgs.applyScale(g, scaleWidth, scaleHeight, derivedValues.type)
        CustomSvgs.applyRotation(g, rotation)
        CustomSvgs.applyTranslation(g, xpos + tadjusts[0], ypos + tadjusts[1])

    },

    alterIds: str => {
        let idsArray = []
        let alteredStr = str
        let randomStr = Utility.randomString(5) + '_'
        function findIds(str) {
            str = str.replace(/\s\s+/g, ' ');
            str = str.replace(/id =/ig, 'id=');
            str = str.replace(/id= \"/ig, 'id=\"'); // eslint-disable-line no-useless-escape
            const strPattern = "id\=\"\\S+\""; // eslint-disable-line no-useless-escape
            const pattern = new RegExp(strPattern, 'g');
            let matches;
            while ((matches = pattern.exec(str)) !== null) {
                idsArray.push(matches[0])
            }
        }
        findIds(alteredStr)
        idsArray = idsArray.map(id => id.replaceAll('"', ''))
        idsArray = idsArray.map(hr => hr.replace('id=', ''))
        idsArray = idsArray.map(hr => hr.replace('>', ''))
        let uniqueIds = [...new Set(idsArray)]
        uniqueIds.forEach(id => {
            alteredStr = alteredStr.replaceAll(id, randomStr + id)
        })

        return { alteredString: alteredStr, prepend: randomStr }
    },

    drawSvg: (g, svgString) => {
        let parsed = Snap.parse(svgString)
        // g.attr({ viewBox: viewBox })
        g.append(parsed)
        //let measureG = g.getBBox()
        //let adjustX = 20 + ((200 - measureG.width) / 2) - measureG.x
        //let adjustY = 20 + ((200 - measureG.height) / 2) - measureG.y
        return [0, 0]//[adjustX, adjustY]
    },

    applyScale: (g, width, height, type) => {
        let measureG = g.getBBox()
        let currentMatrix = g.transform().localMatrix;
        let addMatrix = new Snap.Matrix()
        if (type !== 'National Flags') {
            addMatrix.scale(width / 120, height / 120, measureG.cx, measureG.cy); //scale object
        }
        else {
            addMatrix.scale(width / 240, height / 180, 120, 120); //scale object
        }
        addMatrix.add(currentMatrix)
        g.transform(addMatrix)
    },

    applyRotation: (g, rotation) => {
        let measureG = g.getBBox()
        //g.circle(measureG.cx, measureG.cy, 10).attr({fill: "red", opacity: 0.5})
        let currentMatrix = g.transform().localMatrix;
        let addMatrix = new Snap.Matrix()
        // this is right on the left top box - addMatrix.rotate(rotation, measureG.cx - (measureG.width / 2), measureG.cy - (measureG.height / 2))
        addMatrix.rotate(rotation, measureG.cx, measureG.cy)

        addMatrix.add(currentMatrix)
        g.transform(addMatrix)
    },

    applyTranslation: (g, xpos, ypos) => {
        let currentMatrix = g.transform().localMatrix;
        let addMatrix = new Snap.Matrix()
        addMatrix.translate(xpos, ypos)
        addMatrix.add(currentMatrix)
        g.transform(addMatrix)
    },
}

export default CustomSvgs