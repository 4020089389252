import React, { useState, useEffect } from 'react'
import './PinControl.scss'

const PinControl = ({changePinned, hide}) => {
    const [pinned, setPinned] = useState(false)

    const onPinClick = () => {
        setPinned(!pinned)
    }

    useEffect(() => {
        changePinned(pinned)
    }, [pinned]) // eslint-disable-line react-hooks/exhaustive-deps

    return (
        <div id="pinControl" onClick={onPinClick} className={hide ? 'display-none' : pinned ? 'pin-control pinned' : 'pin-control'}>

            <svg width="100%" height="100%" viewBox="0 0 300 330">
                <defs>
                    <linearGradient id="gradient1" gradientTransform="rotate(30)">
                        <stop offset="5%" stopColor="#aaf" />
                        <stop offset="95%" stopColor="#77a" />
                    </linearGradient>
                    <linearGradient id="gradient2" gradientTransform="rotate(50)">
                        <stop offset="5%" stopColor="#aaf" />
                        <stop offset="95%" stopColor="#77a" />
                    </linearGradient>
                    <linearGradient id="gradient3" gradientTransform="rotate(10)">
                        <stop offset="25%" stopColor="#ddf" />
                        <stop offset="75%" stopColor="#77a" />
                    </linearGradient>
                    <linearGradient id="gradient4" gradientTransform="rotate(50)">
                        <stop offset="5%" stopColor="#aaf" />
                        <stop offset="95%" stopColor="#77a" />
                    </linearGradient>
                    <linearGradient id="gradientPaper" gradientTransform="rotate(90)">
                        <stop offset="5%" stopColor="#ddd" />
                        <stop offset="95%" stopColor="#fff" />
                    </linearGradient>
                    <linearGradient id="gradientPinShadow" gradientTransform="rotate(10)">
                        <stop offset="5%" stopColor="#aaa" />
                        <stop offset="55%" stopColor="#ddd" />
                        <stop offset="60%" stopColor="#d9d9d9" />
                        <stop offset="65%" stopColor="#eee" />
                        <stop offset="66%" stopColor="transparent" />
                    </linearGradient>
                </defs>

                <path d={`
                                        M 120 10
                                        L 120 200
                                        185 200
                                        185 10 z`} fill="url('#gradient4')" />
                <path d={`
                                        M 85 10
                                        220 10
                                        
                                        220 50
                                        180 55
                                        120 55
                                        85 50
                                        
                                        z`} fill="url('#gradient2')" />

                <path d={`
                                        M 145 180
                                        155 180
                                        155 300
                                        150  330
                                        145 300
                                        145 180
                                        
                                        
                                        z`} fill="url('#gradient3')" />
                {pinned ?
                    <path d={`
                                       M 50 230
                                         145 230
                                         145 270
                                         155 270
                                         155 230
                                         260 230
                                         290 330
                                         0 330 z`} fill="url('#gradientPaper')" />
                    : ''}
                <path d={`
                                        M50 200 
                                        C50 120
                                        250 120
                                        250 200 z`} fill="url('#gradient4')" />

                {pinned ?
                    <ellipse cx="183" cy="262" rx="40" ry="8"
                        transform="rotate(10 120 280)" fill="url('#gradientPinShadow')"
                    />
                    : ''}

            </svg> 


        </div> 
    );
}
export default PinControl
