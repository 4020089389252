import React, { useState, useEffect, useContext } from 'react'
import { useSignal } from "@preact/signals-react"
import Utility from "../../objects/Utility";
import { StoreContext } from "../../context/StoreContext"
import './InputXyPosition.scss'
const InputXyPosition = ({ layerKey, input, setValue, useValue = [0, 0], dragActive }) => {
    const { state } = useContext(StoreContext)
    // first is x, second is y, the second set are for coordinating line drags. so we can 
    //update both ends of the line at the same time.
    const signalXyVal = useSignal([Utility.roundFloat(useValue[0], 1), Utility.roundFloat(useValue[1], 1)]);
    const signalXyValText = useSignal([Utility.roundFloat(useValue[0], 1), Utility.roundFloat(useValue[1], 1)])
    const [xyValue, setXyValue] = useState(signalXyVal.v)
    const [minRange] = useState(-100)
    const [maxRange] = useState(100)
    const [textInputStyle] = useState('text-input-style')
    const [warningTextInputWidth, setWarningTextInputWidth] = useState('')
    const [warningTextInputHeight, setWarningTextInputHeight] = useState('')
    const [waiting, setWaiting] = useState(null)

    useEffect(() => {
        return () => {
            if (waiting) {
                clearTimeout(waiting)
            }
        };
    }, []) // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        if (useValue[0] === null || useValue[1] === null) {
            return
        }
        if (useValue !== null && useValue !== undefined) {
            if (xyValue[0] !== useValue[0] || xyValue[1] !== useValue[1]) {
                signalXyVal.value = useValue
                signalXyValText.value = useValue
                setXyValue(useValue)
            }
        }
    }, [useValue]) // eslint-disable-line react-hooks/exhaustive-deps

    const changeXyValText = evt => {
        let value = evt.target.value

        if (Utility.isNumeric(value) === false && value !== '' && value !== '-' && value !== '.') {
            evt.target.id === 'input_text_width_' + layerKey + '_' + input.inputKey ? setWarningTextInputWidth('warning') : setWarningTextInputHeight('warning')
        }
        else {
            if (Utility.isNumeric(value) &&
                (Utility.roundFloat(value) > 100 || Utility.roundFloat(value) < -100)
            ) {
                evt.target.id === 'input_text_width_' + layerKey + '_' + input.inputKey ? setWarningTextInputWidth('warning') : setWarningTextInputHeight('warning')
            }
            else {
                evt.target.id === 'input_text_width_' + layerKey + '_' + input.inputKey ? setWarningTextInputWidth('') : setWarningTextInputHeight('')
            }
        }

        if (Utility.isNumeric(value) && !value.includes('.')) {
            value = Utility.roundFloat(evt.target.value, 1)
            if (value < -100) {
                value = -100
                evt.target.id === 'input_text_width_' + layerKey + '_' + input.inputKey ? setWarningTextInputWidth('') : setWarningTextInputHeight('')

            }
            if (value > 100) {
                value = 100
                evt.target.id === 'input_text_width_' + layerKey + '_' + input.inputKey ? setWarningTextInputWidth('') : setWarningTextInputHeight('')

            }
        }

        if (evt.target.id === 'input_text_width_' + layerKey + '_' + input.inputKey) {
            signalXyValText.value = [value, signalXyVal.value[1]]
        }
        if (evt.target.id === 'input_text_height_' + layerKey + '_' + input.inputKey) {
            signalXyValText.value = [signalXyVal.value[0], value]
        }

        if (Utility.isNumeric(value)) {
            // set the sliders to the right value.
            changeXyVal(evt)
        }
    }


    const changeXyVal = evt => {
        let value = evt.target.value
        if (Utility.isNumeric(evt.target.value) === false) {
            return
        }
        value = Utility.roundFloat(evt.target.value, 1)
        if (value < -100) {
            value = -100
        }
        if (value > 100) {
            value = 100
        }
        if (evt.target.id === 'input_x_' + layerKey + '_' + input.inputKey || evt.target.id === 'input_text_width_' + layerKey + '_' + input.inputKey) {
           signalXyVal.value = [value, signalXyVal.value[1]]
        }
        if (evt.target.id === 'input_y_' + layerKey + '_' + input.inputKey || evt.target.id === 'input_text_height_' + layerKey + '_' + input.inputKey) {
            signalXyVal.value = [signalXyVal.value[0], value]
        }

        if (waiting) {
            return
        }
        setValue(input.inputKey, signalXyVal.value)

        let _waiting = setTimeout(() => {
            // setValue(input.inputKey, signalXyVal.value)
            // setXyValue(signalXyVal.value)
            // changeXyValText(signalXyVal.value)
            // signalXyValText.value = signalChange.v
            setValue(input.inputKey, signalXyVal.value)
            signalXyValText.value = signalXyVal.value
            setWaiting(null)
        }, 100)
        setWaiting(_waiting)
        setWarningTextInputHeight('')
    }

    useEffect(() => {
        if (state.mouseDblClick) {
            if (state.mouseDblClick.target && state.mouseDblClick.target.id) {
                if (state.mouseDblClick.target.id === 'xy_' + layerKey + '_' + input.inputKey
                    ||
                    state.mouseDblClick.target.id === 'xy_' + layerKey + '_' + input.inputKey + '_x'
                    ||
                    state.mouseDblClick.target.id === 'xy_' + layerKey + '_' + input.inputKey + '_y'
                ) {
                    let foundLayer = state.layers.find(ly => ly.layerKey === layerKey)
                    if (foundLayer) {
                        let foundInput = foundLayer.inputs.find(inp => inp.inputKey === input.inputKey)
                        if (foundInput) {
                            let xyVal_default = foundInput.defaultFloatArrayValue
                            if (xyVal_default !== null) {
                                if (typeof xyVal_default === 'string') {
                                    xyVal_default = Utility.convertPostgresArrayToArray(xyVal_default)
                                }
                                if (xyVal_default && Array.isArray(xyVal_default) && xyVal_default.length === 2) {
                                    if (state.mouseDblClick.target.id.endsWith('_x')) {
                                        setValue(input.inputKey, [xyVal_default[0], signalXyVal.value[1]])
                                        signalXyValText.value = [xyVal_default[0], signalXyVal.value[1]]
                                        setWarningTextInputWidth('')
                                    }
                                    else {
                                        if (state.mouseDblClick.target.id.endsWith('_y')) {
                                            setValue(input.inputKey, [signalXyVal.value[0], xyVal_default[1]])
                                            signalXyValText.value = [signalXyVal.value[0], xyVal_default[1]]
                                            setWarningTextInputHeight('')
                                        }
                                        else {
                                            setValue(input.inputKey, [xyVal_default[0], xyVal_default[1]])
                                            signalXyValText.value = [xyVal_default[0], xyVal_default[1]]
                                            setWarningTextInputWidth('')
                                            setWarningTextInputHeight('')
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }, [state.mouseDblClick]) // eslint-disable-line react-hooks/exhaustive-deps

    return (
        <div id={`input_xy_position_${input.inputKey}`} className="default-input-container input-xy-position">
            <div id={`xy_${layerKey}_${input.inputKey}`} className="named">
                {input.comment && input.comment.includes('combat') ? 'combat ' : ''}
                {input.comment && input.comment.includes('movement') ? 'movement ' : ''}
                {input.comment && input.comment.includes('separator') ? 'separator ' : ''}
                {input.named}</div>
            <div className="coordinate-container">
                <span id={`xy_${layerKey}_${input.inputKey}_x`}>x:</span>
                <input type="range" min={minRange} max={maxRange} className="slider" id={`input_x_${layerKey}_${input.inputKey}`}
                    value={xyValue[0]}
                    step="0.1"
                    onChange={changeXyVal}
                />
                <div><input type="text" className={`${textInputStyle} ${warningTextInputWidth}`} onChange={changeXyValText} id={`input_text_width_${layerKey}_${input.inputKey}`} value={signalXyValText.value[0]} /></div>
            </div>
            <div className="coordinate-container">
                <span id={`xy_${layerKey}_${input.inputKey}_y`}>y:</span>
                <input type="range" min={minRange} max={maxRange} className="slider" id={`input_y_${layerKey}_${input.inputKey}`}
                    value={xyValue[1]}
                    step="0.1"
                    onChange={changeXyVal}
                />
                <div><input type="text" className={`${textInputStyle} ${warningTextInputHeight}`} onChange={changeXyValText} id={`input_text_height_${layerKey}_${input.inputKey}`} value={signalXyValText.value[1]} /></div>
            </div>
        </div>
    )
}

export default InputXyPosition