import React, { useState, useEffect, useContext, useRef } from 'react'
import Utility from "../../objects/Utility";
import { StoreContext } from "../../context/StoreContext"
import './InputFill.scss'
const InputFill = ({ layerKey, input, setValue, useValue }) => {
    const { state, actions } = useContext(StoreContext)
    const [colorData, setColorData] = useState(null)
    const [solidFillColorText, setSolidFillColorText] = useState('')
    const [colorPickerOwned, setColorPickerOwned] = useState(false)
    const [colorGradientPickerOwned, setColorGradientPickerOwned] = useState(-1)
    const launchSolidFillColorRef = useRef(null)
    const [multicolorButton, setMulticolorButton] = useState([])

    const [colorsArray, setColorsArray] = useState([
        { name: 'start', value: '#000000', launchColorPickerRef: useRef(null), isValid: true },
        { name: 'middle', value: '#000000', launchColorPickerRef: useRef(null), isValid: true },
        { name: 'end', value: '#000000', launchColorPickerRef: useRef(null), isValid: true }])

    // the popup color selector lives on the home page. Theres only one. The layer menus that have InputFill or InputColor have the color selector get 
    // passed an ownership value (true or false for a solid color choice, or a number from -1 to 2 to indicate which gradient color stop it is for. 
    // When a selection on the color popup is made, the
    // choice is passed to state, which ends up triggering a useEffect in the layerMenus, and if their ownership values are above -1, it means its meant
    // for that specific layer menu's InputFill option. Since the user can enter invalid values into the text inputs of colors, that has to be handled
    // locally so the user can finish typing in a valid hex value. Invalid hex values are not sent to state.

    useEffect(() => {
        setupMultiColorButton()
    }, []) // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        if (colorData) {
            if (colorData.fillColor !== solidFillColorText) {
                setSolidFillColorText(colorData.fillColor)
            }

            let _colorsArray = [...colorsArray]
            if (colorData.colorStart !== _colorsArray[0].value ||
                colorData.colorMid !== _colorsArray[1].value ||
                colorData.colorEnd !== _colorsArray[2].value) {
                _colorsArray[0].value = colorData.colorStart
                _colorsArray[1].value = colorData.colorMid
                _colorsArray[2].value = colorData.colorEnd
                setColorsArray(_colorsArray)
            }
        }

    }, [colorData]) // eslint-disable-line react-hooks/exhaustive-deps

    const setupMultiColorButton = () => {

        function hslToHex(h, s, l) {
            l /= 100;
            const a = s * Math.min(l, 1 - l) / 100;
            const f = n => {
                const k = (n + h / 30) % 12;
                const color = l - a * Math.max(Math.min(k - 3, 9 - k, 1), -1);
                return Math.round(255 * color).toString(16).padStart(2, '0');   // convert to Hex and prefix "0" if needed
            };
            return `#${f(0)}${f(8)}${f(4)}`;
        }

        let paths = []
        let colors = []
        for (var x = 0; x < 360; x = x + 12) {
            let h = hslToHex(x, 81, 50)
            colors.push(h)
        }
        let arr = [0, 12, 24, 36, 48, 60, 72, 84, 96, 108, 120, 132, 144, 156, 168, 180, 192, 204, 216, 228, 240, 252, 264, 276, 288, 300, 312, 324, 336, 348]


        arr.forEach((a, index) => {
            let d = drawPieSlice(0, 0, 19, a, a + 12)
            paths.push({ d, color: colors[index] })
        })
        setMulticolorButton(paths)
    }

    const drawPieSlice = (centerX, centerY, radius, startAngle, endAngle) => {

        var startRad = startAngle * Math.PI / 180.0;
        let x1 = radius * Math.sin(startRad)
        let y1 = radius * Math.cos(startRad)

        var endRad = endAngle * Math.PI / 180.0;
        let x2 = radius * Math.sin(endRad)
        let y2 = radius * Math.cos(endRad)

        x1 = Utility.roundFloat(x1, 2)
        y1 = Utility.roundFloat(y1, 2)
        x2 = Utility.roundFloat(x2, 2)
        y2 = Utility.roundFloat(y2, 2)

        let d = 'M ' + centerX + ', ' + centerY + ' ' +
            'L ' + x1 + ',' + y1 + ','
            + x2 + ',' + y2 + ' Z'

        return d
    }

    const colorDataArrayConversion = (toString, data = colorData) => {
        if (!data) {
            return
        }
        let _data = JSON.parse(JSON.stringify(data))
        if (toString) {
            if (Array.isArray(_data.xyPositionStart)) {
                _data.xyPositionStart = _data.xyPositionStart.join(',')
                _data.xyPositionEnd = _data.xyPositionEnd.join(',')
            }
        }
        else {
            if (typeof _data.xyPositionStart === 'string') {
                _data.xyPositionStart = _data.xyPositionStart.split(',').map(str => Number(str))
                _data.xyPositionEnd = _data.xyPositionEnd.split(',').map(str => Number(str))
            }
        }

        return _data
    }

    const sendToSetValue = data => {
        if (data) {
            let dataStringArrays = colorDataArrayConversion(true, data)
            setValue(input.inputKey, JSON.stringify(dataStringArrays))
        }
    }

    const changeFillType = type => {
        if (colorData.fillType !== type) {
            let _colorData = { ...colorData }
            _colorData.fillType = type
            sendToSetValue(_colorData)
        }
    }

    const doSetColorData = data => {
        if (data) {
            let _data = colorDataArrayConversion(false, data)
            setColorData(_data)
        }
    }

    useEffect(() => {
        if (!useValue) {
            return
        }

        if (colorData === null) {
            // set the two comma delimited positon start an ends to arrays
            doSetColorData(useValue)
        }
        else {
            let _colorDataStringArrays = colorDataArrayConversion(true)
            if (JSON.stringify(useValue) !== JSON.stringify(_colorDataStringArrays)) {
                doSetColorData(useValue)
            }
        }

    }, [useValue]) // eslint-disable-line react-hooks/exhaustive-deps

    const changeSolidFillColorText = evt => {
        setSolidFillColorText(evt.target.value)
    }

    useEffect(() => {
        if (solidFillColorText === 'transparent' || solidFillColorText === 'none' || Utility.validateHexColor(solidFillColorText)) {
            if (colorData.fillColor !== solidFillColorText) {
                let _colorData = { ...colorData }
                _colorData.fillColor = solidFillColorText
                sendToSetValue(_colorData)
            }
        }
    }, [solidFillColorText]) // eslint-disable-line react-hooks/exhaustive-deps

    const openColorPicker = () => {
        setColorPickerOwned(true)
    }

    useEffect(() => {
        if (colorPickerOwned) {
            let launchColorPickerRefClientRef = launchSolidFillColorRef.current.getBoundingClientRect()
            let x = launchColorPickerRefClientRef.left + launchColorPickerRefClientRef.width + 10 // the 10 is to give in some spacing
            let y = launchColorPickerRefClientRef.top
            let hexColor = colorData.fillColor
            actions.colorPicker({ x, y, hexColor })
        }
    }, [colorPickerOwned]) // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        if (state.colorPicker.x === -1) {
            setColorPickerOwned(false)
            setColorGradientPickerOwned(-1)
            return
        }
        if (colorPickerOwned === false && colorGradientPickerOwned === -1) {
            return
        }

        let _colorData = { ...colorData }
        let validHexValue = Utility.validateHexColor(state.colorPicker.hexColor)

        if (colorPickerOwned) { // if owned by solid fill input
            if (validHexValue) {
                if (_colorData.fillColor !== state.colorPicker.hexColor) {
                    _colorData.fillColor = state.colorPicker.hexColor
                    sendToSetValue(_colorData)
                }
                else {
                    setSolidFillColorText(state.colorPicker.hexColor)
                }
            }
            else {
                setSolidFillColorText(state.colorPicker.hexColor)
            }
        }

        if (colorGradientPickerOwned > -1) {
            if (validHexValue) {
                if (colorGradientPickerOwned === 0) {
                    _colorData.colorStart = state.colorPicker.hexColor
                }
                if (colorGradientPickerOwned === 1) {
                    _colorData.colorMid = state.colorPicker.hexColor
                }
                if (colorGradientPickerOwned === 2) {
                    _colorData.colorEnd = state.colorPicker.hexColor
                }
                if (JSON.stringify(colorData) !== JSON.stringify(_colorData)) {
                    sendToSetValue(_colorData) // setting the value in the parent component will cause it to resend the same data back to this component, which sets the local colorsArray.
                }
            }
            else {
                if (colorsArray[colorGradientPickerOwned].value !== state.colorPicker.hexColor) {
                    let _colorsArray = [...colorsArray]
                    _colorsArray[colorGradientPickerOwned].value = state.colorPicker.hexColor
                    setColorsArray(_colorsArray)
                }
            }
        }
    }, [state.colorPicker]) // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        if (colorGradientPickerOwned > -1) {
            let ref = colorsArray[colorGradientPickerOwned].launchColorPickerRef.current
            let bbox = ref.getBoundingClientRect()
            let x = bbox.left + bbox.width + 10 // the 10 is to give in some spacing
            let y = bbox.top
            let hexColor = colorsArray[colorGradientPickerOwned].value
            actions.colorPicker({ x, y, hexColor })
        }
    }, [colorGradientPickerOwned]) // eslint-disable-line react-hooks/exhaustive-deps

    const openColorGradientPicker = colorIndex => {
        setColorGradientPickerOwned(colorIndex)
    }

    const changeGradientDirection = evt => {
        let gradientDirection = evt.target.value
        if (colorData.gradientDirection !== gradientDirection) {
            let _colorData = { ...colorData }
            _colorData.gradientDirection = gradientDirection
            sendToSetValue(_colorData)
        }
    }

    const clickGradientDirection = evt => {
        let gradientDirection = evt.target.value
        if (colorData.gradientDirection !== gradientDirection) {
            let _colorData = { ...colorData }
            _colorData.gradientDirection = gradientDirection
            sendToSetValue(_colorData)
        }
    }

    const changeXyValStart = evt => {
        let startXy = null
        if (evt.target.id === 'input_x_start') {
            startXy = [Utility.roundFloat(evt.target.value, 3), colorData.xyPositionStart[1]] // [nnn,nnn]
        }
        if (evt.target.id === 'input_y_start') {
            startXy = [colorData.xyPositionStart[0], Utility.roundFloat(evt.target.value, 3)]
        }
        let _colorData = { ...colorData }
        _colorData.xyPositionStart = startXy
        sendToSetValue(_colorData)
    }

    const changeXyValEnd = evt => {
        let posXy = null
        if (evt.target.id === 'input_x_end') {
            posXy = [Utility.roundFloat(evt.target.value, 3), colorData.xyPositionEnd[1]] // [nnn,nnn]
        }
        if (evt.target.id === 'input_y_end') {
            posXy = [colorData.xyPositionEnd[0], Utility.roundFloat(evt.target.value, 3)]
        }
        let _colorData = { ...colorData }
        _colorData.xyPositionEnd = posXy
        sendToSetValue(_colorData)
    }

    const changeColorStopsText = evt => {
        if (evt.target.id) {
            let colorIndex = evt.target.id.split('_').pop()
            let typedValue = evt.target.value
            let _colorData = { ...colorData }
            let _colorsArray = [...colorsArray]
            _colorsArray[colorIndex].value = typedValue
            if (Utility.validateHexColor(typedValue)) {
                if (_colorData.colorStart !== _colorsArray[0].value ||
                    _colorData.colorMid !== _colorsArray[1].value ||
                    _colorData.colorEnd !== _colorsArray[2].value) {
                    _colorData.colorStart = _colorsArray[0].value
                    _colorData.colorMid = _colorsArray[1].value
                    _colorData.colorEnd = _colorsArray[2].value
                    sendToSetValue(_colorData)
                }
            }
            else {
                setColorsArray(_colorsArray)
            }
        }
    }

    return (colorData ?
        <div id={`inputContainer_${layerKey}_${input.inputKey}`} className="default-input-container input-fill">
            <div className="named">
                fill color
            </div>
            <div className="input-fill-tabs">
                <div className="input-fill-tabs-selection">
                    <div className="tabs">
                        <div className={colorData.fillType === 'solid' ? 'tab active' : 'tab inactive'} onClick={() => changeFillType('solid')} >solid</div>
                        <div className={colorData.fillType === 'gradient' ? 'tab active' : 'tab inactive'} onClick={() => changeFillType('gradient')} >gradient</div>
                    </div>
                </div>


                <div className="tabbed-content">
                    {colorData.fillType === 'solid' ?
                        <div className="solid-settings">
                            <div id={`solid-color_${input.inputKey}_${layerKey}`} className="input-color solid">
                                <div className="solid-fill">solid fill:</div>
                                <div><input id={`inputFill${layerKey}${input.inputKey}`} type="text" spellCheck="false" className={solidFillColorText === 'transparent' || solidFillColorText === 'none' || Utility.validateHexColor(solidFillColorText) ? 'input-color-pick' : 'input-color-pick invalid'} value={solidFillColorText} onChange={changeSolidFillColorText} /></div>
                                <div className="color-sample" style={{ "background": colorData.fillColor }} onClick={openColorPicker} />
                                <div ref={launchSolidFillColorRef}
                                    className="multicolor-button"
                                    onClick={openColorPicker}>
                                    <svg id={`colorPickerButton${input.inputKey}_${layerKey}`} width="28" height="28" viewBox="-14, -14, 28, 28">
                                        {multicolorButton.map((dc, index) => {
                                            return <path key={index} d={`${dc.d}`} fill={`${dc.color}`} />
                                        })}
                                        <circle cx="0" cy="0" r="14" stroke="white" strokeWidth="2px" fill="none" />
                                        <circle cx="0" cy="0" r="13" stroke="white" strokeWidth="2px" fill="none" opacity="0.5" />
                                    </svg>
                                </div>
                            </div>
                        </div>
                        :
                        <div className="gradient-settings">

                            <div className={colorData.fillType === 'gradient' ? '' : 'display-none'}>
                                <div className="radio-choice-gradient-direction">
                                    <div className="direction-named">direction:</div>
                                    <div className="radio-options">
                                        <div>
                                            <input type="radio"
                                                id={`inputGradientDirection_${layerKey}_${input.inputKey}_linear`}
                                                name={`inputGradientDirection_${layerKey}_${input.inputKey}_linear`}
                                                value='linear'
                                                checked={colorData.gradientDirection === 'linear'}
                                                onChange={changeGradientDirection}
                                                onClick={clickGradientDirection}
                                            />
                                            <label htmlFor={`inputGradientDirection_${layerKey}_${input.inputKey}_linear`} >linear</label>
                                        </div>
                                        <div>
                                            <input type="radio"
                                                id={`inputGradientDirection_${layerKey}_${input.inputKey}_radial`}
                                                name={`inputGradientDirection_${layerKey}_${input.inputKey}_radial`}
                                                value='radial'
                                                checked={colorData.gradientDirection === 'radial'}
                                                onChange={changeGradientDirection}
                                                onClick={clickGradientDirection}
                                            />
                                            <label htmlFor={`inputGradientDirection_${layerKey}_${input.inputKey}_radial`} >radial</label>
                                        </div>
                                    </div>
                                </div>
                                <div id={`input_xy_position_start_${layerKey}_${input.inputKey}`} className="input-xy-position_gradient">
                                    <div id={`xy_start_${layerKey}_${input.inputKey}_gradient`} className="gradient-named">gradient start position</div>
                                    <div className="coordinate-container">
                                        <span id={`xy_start_${layerKey}_${input.inputKey}_gradient_x`}>x:</span>
                                        <input type="range" min="0" max="1" className="slider" id="input_x_start"
                                            value={colorData.xyPositionStart[0]}
                                            step="0.01"
                                            onChange={changeXyValStart}
                                        />
                                        <div className="coordinate-readout">{colorData.xyPositionStart[0]}</div>
                                    </div>
                                    <div className="coordinate-container">
                                        <span id={`xy_start_${layerKey}_${input.inputKey}_gradient_y`}>y:</span>
                                        <input type="range" min="0" max="1" className="slider" id="input_y_start"
                                            value={colorData.xyPositionStart[1]}
                                            step="0.01"
                                            onChange={changeXyValStart}
                                        />
                                        <div className="coordinate-readout">{colorData.xyPositionStart[1]}</div>
                                    </div>
                                </div>

                                <div id={`input_xy_position_end_${layerKey}_${input.inputKey}`} className="input-xy-position_gradient">
                                    <div id={`xy_end_${layerKey}_${input.inputKey}_gradient`} className="gradient-named">gradient end position</div>
                                    <div className="coordinate-container">
                                        <span id={`xy_end_${layerKey}_${input.inputKey}_gradient_x`}>{colorData.gradientDirection === 'linear' ? 'x' : 'r'}:</span>
                                        <input type="range" min="0" max="1" className="slider" id="input_x_end"
                                            value={colorData.xyPositionEnd[0]}
                                            step="0.01"
                                            onChange={changeXyValEnd}
                                        />
                                        <div className="coordinate-readout">{colorData.xyPositionEnd[0]}</div>
                                    </div>
                                    <div className={colorData.gradientDirection === 'linear' ? 'coordinate-container' : 'coordinate-container disabled'}>
                                        <span id={`xy_end_${layerKey}_${input.inputKey}_gradient_y`}>y:</span>
                                        <input type="range" min="0" max="1" className={colorData.gradientDirection === 'linear' ? 'slider' : 'slider disabled'} id="input_y_end"
                                            value={colorData.xyPositionEnd[1]}
                                            step="0.01"
                                            onChange={changeXyValEnd}
                                            disabled={colorData.gradientDirection === 'radial' ? true : false}
                                        />
                                        <div className="coordinate-readout">{colorData.xyPositionEnd[1]}</div>
                                    </div>
                                </div>

                                <div className="input-colors">
                                    {colorsArray.map((clr, index) =>
                                        <div key={index} id={`input_${clr.name}_${layerKey}_${input.inputKey}`} className="input-color">
                                            <div className="order-named">{clr.name}:</div>

                                            <div><input type="text" spellCheck="false"
                                                className={clr.value === 'transparent' || clr.value === 'none' || Utility.validateHexColor(clr.value) ? 'input-color-pick' : 'input-color-pick invalid'}
                                                id={`color_${clr.name}_${layerKey}_${input.inputKey}_textColor_${index}`}
                                                value={clr.value} onChange={changeColorStopsText} /></div>

                                            <div id={`color_${clr.name}_${layerKey}_${input.inputKey}_colorBox`} className="color-sample" style={{ "background": clr.value }} onClick={() => openColorGradientPicker(index)} />
                                            <div id={`color_${clr.name}_${layerKey}_${input.inputKey}_colorWheel`}
                                                ref={clr.launchColorPickerRef}

                                                className="multicolor-button"

                                                onClick={() => openColorGradientPicker(index)}>
                                                <svg id={`colorPickerButton_${layerKey}_${input.inputKey}`} width="28" height="28" viewBox="-14, -14, 28, 28">
                                                    {multicolorButton.map((dc, index) => {
                                                        return <path key={index} d={`${dc.d}`} fill={`${dc.color}`} />
                                                    })}
                                                    <circle cx="0" cy="0" r="14" stroke="white" strokeWidth="2px" fill="none" />
                                                    <circle cx="0" cy="0" r="13" stroke="white" strokeWidth="2px" fill="none" opacity="0.5" />
                                                </svg>

                                            </div>
                                        </div>
                                    )}
                                </div>
                            </div>
                        </div>
                    }
                </div>
            </div>
        </div>
        : ''
    )
}

export default InputFill