import React, {useContext} from 'react'
import { StoreContext } from "../../context/StoreContext"
import { useSignal } from "@preact/signals-react"
import './GridColorControl.scss'
const GridColorControl = ({gridColor, gridState}) => {
    const { state } = useContext(StoreContext)
    const signalGridColor = useSignal('light')
    
    const onChangeGridColor = evt => {
        signalGridColor.value = evt.target.checked ? 'dark' : 'light'
        gridColor(signalGridColor.value)
    }

    return (
        <div className={gridState ? 'grid-color-control active' : 'grid-color-control'} style={{pointerEvents: state.overlay ? "none" : ""}}>
            <div className="label">grid color</div>
            <div className={ signalGridColor.value === 'light' ? 'light-text selected' : 'light-text'}>light</div>
            <div className="slider-switch">
                <label className="switch">
                    <input id="gridChangeGridColorCheckbox" type="checkbox"
                        onChange={onChangeGridColor}
                    />
                    <span className="slider round"></span>
                </label>
            </div>
            <div className={ signalGridColor.value === 'dark' ? 'dark-text selected' : 'dark-text'}>dark</div>
        </div>
    )
}

export default GridColorControl