import React, { useState, useEffect, useContext } from 'react'
import Utility from "../../objects/Utility";
import { StoreContext } from "../../context/StoreContext"
import './LayerActivateButton.scss'
const LayerActivateButton = ({ layer, layerCanBeActive }) => {
    const { state, actions } = useContext(StoreContext)
    const [layerActiveValue, setLayerActiveValue] = useState('off')

    const activateLayer = () => {
        let newLayers = state.layers.filter(ly => ly.layerKey !== layer.layerKey)
        let updatedLayer = { ...layer, layerActive: 1 }
        actions.layers([...newLayers, updatedLayer])
    }

    const deactivateLayer = () => {
        if (layer.layerKey === 0) {
            return // cant deactivate base layer
        }

        let newLayers = state.layers.filter(ly => ly.layerKey !== layer.layerKey)
        let updatedLayer = { ...layer, layerActive: 0 }
        actions.layers([...newLayers, updatedLayer])

        if (Utility.layerIsInActiveLayerValues(state.activeLayerValues, layer.layerKey)) {
            actions.activeLayerValuesRemove(layer.layerKey)
        }
    }

    useEffect(() => {
        let _layer = state.layers.find(ly => ly.layerKey === layer.layerKey)
        if (_layer) {
            let status = 'off'
            if (_layer.layerActive === 1) {
                status = 'current-active'
            }
            if (_layer.layerActive === 0) {
                status = 'off'
                // check if the other side of the counter is using this layer...
                let ralv = state.counterSideActive.active === 'front' ? state.counterSideActive.rear : state.counterSideActive.front
                if (typeof ralv === 'string') {
                    ralv = JSON.parse(ralv)
                }
                for (const [key] of Object.entries(ralv)) {
                    if (key.startsWith(layer.layerKey + '_')) {
                        status = 'reverse-active'
                        break
                    }
                }
            }

            setLayerActiveValue(status)
        }
    }, [state.layers]) // eslint-disable-line react-hooks/exhaustive-deps

    // user may deselect a required value on a layer that is currently active.
    useEffect(() => {
        if (layer && !layerCanBeActive) {
            if (layer.layerActive === 1) {
                deactivateLayer()
            }
        }
    }, [layerCanBeActive]) // eslint-disable-line react-hooks/exhaustive-deps

    const onClickActivateButton = () => {
        if (layer.layerActive === 1) {
            deactivateLayer()
        }

        if (layer.layerActive === 0) {
            if (layerCanBeActive) {
                activateLayer()
            }
            else {
                return
            }
        }
    }

    return (
        <div className="layer-activate-button">
            <div className={`activate-button ${layer.layerKey === 1 ? 'activate-button-always-active' : ''} ${layerActiveValue} ${layerCanBeActive ? '' : 'not-allowed'}`} onClick={onClickActivateButton} />
        </div>
    )
}

export default LayerActivateButton