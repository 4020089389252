import React, { useContext, useCallback, useMemo } from 'react'
import { StoreContext } from "../../context/StoreContext"
import './MenuItems.scss'

const MenuItems = () => {
    const { state: { topMenuView }, actions } = useContext(StoreContext)
    const menus = useMemo(() => ['App', 'SVGs', 'Images', 'Fonts', 'Saved Counters', 'Saved Sheets'], [])

    const menuClick = useCallback((evt) => {
        const topMenuSelected = evt.currentTarget.id
        if (topMenuSelected) {
            if (topMenuView === topMenuSelected) {
                actions.topMenuView(null)
            } else {
                const sheetDisplayEle = document.getElementById('sheetDisplayContainer')
                if (sheetDisplayEle) {
                    const sheetBbox = sheetDisplayEle.getBoundingClientRect()
                    actions.lastSheetDisplayWidth(sheetBbox.width)
                }
                actions.topMenuView(topMenuSelected)
            }
        } else {
            actions.topMenuView(null)
        }
    }, [topMenuView, actions])

    return (
        <div className="menu-items">
            {menus.map((menu, index) => (
                <div
                    className={topMenuView === menu.toLowerCase() ? 'active' : ''}
                    key={index}
                    id={menu.toLowerCase()}
                    onClick={menuClick}
                >
                    {menu}
                </div>
            ))}
            <span className="app-title">SnapCounter v3</span>
        </div>
    )
}

export default MenuItems