import React, { useState, useEffect, useContext } from 'react'
import Snap from 'snapsvg-cjs'
import { StoreContext } from "../../context/StoreContext"
import Utility from "../../objects/Utility"
import './SvgButton.scss'
const SvgButton = ({ svgKey, selectButton, selected, largeButton, layerKey, hoveringOn }) => {
    const { state } = useContext(StoreContext)
    const [svgData, setSvgData] = useState(null)
    const [displayName, setDisplayName] = useState('')
    const [specialClass, setSpecialClass] = useState('')
    const [layerIsActive, setLayerIsActive] = useState(false)

    useEffect(() => {
        let layer = state.layers.find(ly => ly.layerKey === layerKey )
        if( layer ) {
            setLayerIsActive(layer.layerActive)
        }
    }, [state.layers]) // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        if( Utility.originalLayerNameLayerKey(state.layers, layerKey) === 'national flags' ) {
           setSpecialClass('national-flags')
        }
        let _svgData = state.svgs.find(sd => sd.svgKey === svgKey)
        // patch for bad svg string
        if (_svgData && _svgData.svgKey === 731) {
            let svgCode = _svgData.svgCode
            svgCode = svgCode.replace('/>g', '/><g')
            _svgData.svgCode = svgCode
        }
        if (_svgData) {
            setSvgData(_svgData)
        }
    }, []) // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        if (svgData) {
            determineDisplayName()
            let svgString = svgData.svgCode

            // // create new ids
            if (svgData.uniquePrepend && typeof (svgData.uniquePrepend) === 'string') {
               // change the ids to avoid clashes
            //    const prependText = 'p' + Utility.randomString(5) + '_'
            //    svgString = svgString.replaceAll(svgData.uniquePrepend, prependText)
                let altered = alterIds(svgString)
                svgString = altered.alteredString
            }
            else {
                let svgId = Utility.extractNativeId(svgString)
                if (svgId) {
                    let newSvgId = Utility.randomString(5) + '_' + svgId
                    svgString = svgString.replace(' id="' + svgId + '" ', ' id="' + newSvgId + '" ')
                }
                else {
                    svgString = svgString.replace('<svg ', '<svg id="' + Utility.randomString(5) + '_" ')
                }
            }
            displaySvg(svgString)
        }
    }, [svgData]) // eslint-disable-line react-hooks/exhaustive-deps

    const alterIds = str => {
        let idsArray = []
        let alteredStr = str
        let randomStr = Utility.randomString(5) + '_'
        function findIds(str) {
            str = str.replace(/\s\s+/g, ' ');
            str = str.replace(/id =/ig, 'id=');
            str = str.replace(/id= \"/ig, 'id=\"'); // eslint-disable-line no-useless-escape
            const strPattern = "id\=\"\\S+\""; // eslint-disable-line no-useless-escape
            const pattern = new RegExp(strPattern, 'g');
            let matches;
            while ((matches = pattern.exec(str)) !== null) {
                idsArray.push(matches[0])
            }
        }
        findIds(alteredStr)
        idsArray = idsArray.map(id => id.replaceAll('"', ''))
        idsArray = idsArray.map(hr => hr.replace('id=', ''))
        idsArray = idsArray.map(hr => hr.replace('>', ''))
        let uniqueIds = [...new Set(idsArray)]
        uniqueIds.forEach(id => {
            alteredStr = alteredStr.replaceAll(id, randomStr + id)
        })

        return { alteredString: alteredStr, prepend: randomStr }
    }

    const determineDisplayName = () => {
        let _displayName = svgData.svgName
        _displayName = _displayName.replace('misc unit symbol ', '')
        _displayName = _displayName.replace('nato symbol ', '')
        _displayName = _displayName.replace('common symbol ', '')
        _displayName = _displayName.replace('common symbols ', '')
        _displayName = _displayName.replace('ww2 vehicles ', '')
        setDisplayName(_displayName)
    }

    const layerOriginalName = () => {
        return Utility.originalLayerNameLayerKey(state.layers, layerKey)
    }

    const displaySvg = (svgString) => {
        if( Utility.originalLayerNameLayerKey(state.layers, layerKey) === 'ww2 vehicles' ) {
            svgString = svgString.replace('-120 -120 240 240', '-80 -80 160 160')
        }
        if( layerOriginalName() === 'national flags' ) {
            svgString = svgString.replace('-120 -120 240 240', '-80 -80 160 160')
        }
        const paperTarget = Snap("#svg_button_" + layerKey + '_' + svgKey)
        if (svgData.viewBox) {
            paperTarget.attr({ viewBox: svgData.viewBox })
        }

        let parsed = Snap.parse(svgString)
        paperTarget.append(parsed)
    }

    const _selectButton = key => {
        selectButton(key)
    }

    const hover = hovering => {
        hoveringOn(hovering ? displayName : '')
    }

    return <div className={largeButton ? 'svg-button large-button' : 'svg-button'}
        onMouseOver={() => hover(true)} onMouseOut={() => hover(false)}>
        <div className={selected && layerIsActive ? `svg-code selected ${specialClass}` : `svg-code ${specialClass}`} onClick={() => _selectButton(svgKey)}>
            <svg id={`svg_button_${layerKey}_${svgKey}`} />
        </div>
        <div className={selected && layerIsActive ? `svg-name selected ${specialClass}` : `svg-name ${specialClass}`}>{displayName}</div>
    </div>

}
export default SvgButton