import React, { useState, useEffect, useContext } from 'react'
import { useSignal } from "@preact/signals-react"
import Snap from 'snapsvg-cjs'
import { StoreContext } from "../../context/StoreContext"
import Utility from "../../objects/Utility"
import './ViewSavedSheets.scss'

const ViewSavedSheets = ({ mode, close, optionalInfo }) => {
    const { state, actions } = useContext(StoreContext)
    const [savedSheets, setSavedSheets] = useState([])
    const [successMessage, setSuccessMessage] = useState({ hash: null, message: { message: '', color: '' } })
    const signalDeleteSheetHash = useSignal(null)
    const signalDeleteSheetTimer = useSignal(null)
    const [startupCheckDone, setStartupCheckDone] = useState(false)

    useEffect(() => {
        setStartupCheckDone(false)
    }, [])

    const changeName = evt => {
        let hash = evt.target.id
        hash = hash.replace('key_', '')
        hash = Number(hash)
        let updatedSheets = savedSheets.map(sc => {
            if (sc.hash === hash) {
                return { ...sc, newName: evt.target.value, message: { message: "", color: "" } }
            }
            return sc
        })
        setSavedSheets(updatedSheets)
    }

    const submitRename = hash => {
        let savedSheet = savedSheets.find(sc => sc.hash === hash)
        let newName = savedSheet.newName
        let origName = savedSheet.name

        let testNewName = newName.toLowerCase().replace(/\s\s+/g, ' ')
        let testOrigName = origName.toLowerCase().replace(/\s\s+/g, ' ')
        if (testNewName === testOrigName) {
            let updatedSheets = savedSheets.map(sc => {
                if (sc.hash === hash) {
                    return { ...sc, message: { message: "The new name should be different", color: "red" } }
                }
                return sc
            })
            setSavedSheets(updatedSheets)
            return
        }

        let regexResult = testNewName.match(/[-_)( a-zA-Z0-9]+$/)
        if (regexResult === null || (regexResult && regexResult.index > 0)) {
            let updatedSheets = savedSheets.map(sc => {
                if (sc.hash === hash) {
                    return { ...sc, message: { message: "Only alpha-numeric, spaces, and - _ ) ('", color: "red" } }
                }
                return sc
            })
            setSavedSheets(updatedSheets)
            return
        }

        let _stateSavedSheet = state.savedSheets[hash]
        _stateSavedSheet.name = newName.replace(/\s\s+/g, ' ')
        if (_stateSavedSheet.name.length > 1) {
            setSuccessMessage({ hash, message: { message: "name has been updated", color: "green" } })
            actions.savedSheets({ ...state.savedSheets })
        }
    }

    useEffect(() => {
        if (!Utility.emptyCheck(state.savedSheets)) {
            let _savedSheets = []
            // convert to array
            for (const [key, value] of Object.entries(state.savedSheets)) { // eslint-disable-line no-unused-vars
                _savedSheets.push(value)
            }
            let savedSheetsAddedFields = _savedSheets.map(sc => {
                let message = { message: "", color: "" }
                if (savedSheets.length > 0) {
                    let hash = sc.hash
                    // retrieve any success message
                    if (successMessage.hash === hash) {
                        message = successMessage.message
                    }
                }
                return { ...sc, message, newName: sc.name }
            })
            setSavedSheets(savedSheetsAddedFields)
        }
        else {
            setSavedSheets([])
        }

    }, [state.savedSheets]) // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        setStartupCheckDone(true)
        if (savedSheets.length === 1) {
            optionalInfo('There is 1 saved sheet')
        }
        else {
            optionalInfo('There are ' + savedSheets.length + ' saved sheets')
        }
        savedSheets.forEach(sc => {
            let { hash, compressedSvg } = sc
            let svg = Utility.decompressString(compressedSvg)
            svg = svg.replace('id="temp_sheet"', 'id="build_sheet"')
            svg = svg.replace('id="build_sheet"', 'id="build_sheet_' + hash + '"')
            svg = svg.replace('id="sheetDisplay"', 'id="sheetDisplay_' + hash + '"')
            svg = svg.replaceAll('class="sheet-display-svg"', '')
            sc.compressedSvg = Utility.compressString(svg)

            function memorySizeOf(obj) {
                var bytes = 0;

                function sizeOf(obj) {
                    if (obj !== null && obj !== undefined) {
                        switch (typeof obj) {
                            case 'number':
                                bytes += 8;
                                break;
                            case 'string':
                                bytes += obj.length * 2;
                                break;
                            case 'boolean':
                                bytes += 4;
                                break;
                            case 'object':
                                var objClass = Object.prototype.toString.call(obj).slice(8, -1);
                                if (objClass === 'Object' || objClass === 'Array') {
                                    for (var key in obj) {
                                        if (!obj.hasOwnProperty(key)) continue;
                                        sizeOf(obj[key]);
                                    }
                                } else bytes += obj.toString().length * 2;
                                break;
                            default:
                                bytes += 0
                        }
                    }
                    return bytes;
                };

                function formatByteSize(bytes) {
                    if (bytes < 1024) return bytes + " bytes";
                    else if (bytes < 1048576) return (bytes / 1024).toFixed(3) + " KB";
                    else if (bytes < 1073741824) return (bytes / 1048576).toFixed(3) + " MB";
                    else return (bytes / 1073741824).toFixed(3) + " GB";
                };

                return formatByteSize(sizeOf(obj));
            };

            sc.approxSize = memorySizeOf(sc);
        })
        savedSheets.forEach(sc => {
            let { hash, compressedSvg } = sc
            let svg = Utility.decompressString(compressedSvg)
            let altered = alterIds(svg)
            svg = altered.alteredString
            let newPrepend = altered.prepend

            const svgId = 'viewSavedSheet_' + hash
            const paperTarget = Snap("#" + svgId);
            paperTarget.clear()


            let parsed = Snap.parse(svg)
            if (parsed) {
                paperTarget.append(parsed)

                let measureG = paperTarget.getBBox()
                let measuredWidth = measureG.width
                let measuredHeight = measureG.height
                let buildSheet = paperTarget.select('#' + newPrepend + 'build_sheet_' + hash)
                if (buildSheet) {
                    buildSheet.attr({ viewBox: "0 0 " + (measuredWidth) + " " + (measuredHeight) })

                    // attach white paper background
                    // edit - turns out Firefox's print dialog box was considering a blackground with a fille
                    // of white as transparent - so it was replacing it with black, giving the user the
                    // notion that if they hit print, it would print lots of black for empty slots (even though
                    // it would actually not print that black. Found that if I use a "pattern" with white strokes, 
                    // it looks white in Firefox's dialog.
                    var p = paperTarget.path("M10-5-10,15M15,0,0,15M0-5-20,15").attr({
                        fill: "#ffffff",
                        stroke: "#ffffff",
                        strokeWidth: 5
                    }).pattern(0, 0, 10, 10),
                        fakeWhiteBackground = paperTarget.rect(0, 0, "100%", "100%");
                    fakeWhiteBackground.attr({
                        fill: p,
                        "data-type": "fakeWhiteBackground"
                    });

                    let firstGroup = paperTarget.select('#' + newPrepend + 'sheetDisplay_' + hash)
                    if (firstGroup) {
                        firstGroup.prepend(fakeWhiteBackground)
                    }
                    paperTarget.attr({ width: "100%", height: "100%" })
                    buildSheet.attr({ width: "100%", height: "100%" })

                    let svgContainer = document.getElementById('svg_container_' + sc.hash)
                    if (svgContainer) {
                        let buildSheetEle = document.getElementById("build_sheet_" + sc.hash)
                        if (buildSheetEle) {
                            let bboxbuildSheetEle = buildSheetEle.getBoundingClientRect();
                            svgContainer.style.width = bboxbuildSheetEle.width + 'px'
                            svgContainer.style.height = bboxbuildSheetEle.height + 'px'
                        }
                    }
                    else {
                        console.warn('no svgContainer')
                    }
                }
                else {
                    console.warn('no buildsheet ref!: ', svg)
                }
            }
        })
    }, [savedSheets]) // eslint-disable-line react-hooks/exhaustive-deps

    const alterIds = str => {
        let idsArray = []
        let alteredStr = str
        let randomStr = 'b' + Utility.randomString(4) + '_'
        function findIds(str) {
            str = str.replace(/\s\s+/g, ' ');
            str = str.replace(/id =/ig, 'id=');
            str = str.replace(/id= \"/ig, 'id=\"'); // eslint-disable-line no-useless-escape
            const strPattern = "id\=\"\\S+\""; // eslint-disable-line no-useless-escape
            const pattern = new RegExp(strPattern, 'g');
            let matches;
            while ((matches = pattern.exec(str)) !== null) {
                idsArray.push(matches[0])
            }
        }
        findIds(alteredStr)
        idsArray = idsArray.map(id => id.replaceAll('"', ''))
        idsArray = idsArray.map(hr => hr.replace('id=', ''))
        idsArray = idsArray.map(hr => hr.replace('>', ''))
        let uniqueIds = [...new Set(idsArray)]
        uniqueIds.forEach(id => {
            // alteredStr = alteredStr.replaceAll('id="' + id + '"', 'id="' + randomStr + id + '"')
            // alteredStr = alteredStr.replaceAll('clip-path="url(#' + id, 'clip-path="url(#' + randomStr + id)
            alteredStr = alteredStr.replaceAll(id, randomStr + id)
        })

        return { alteredString: alteredStr, prepend: randomStr }
    }

    const deleteConfirmSavedSheet = evt => {
        let hash = Number(evt.target.id.replace('delete_', ''))
        signalDeleteSheetHash.value = hash
        let timerId = setTimeout(() => {
            signalDeleteSheetTimer.value = null
        }, 1000)
        signalDeleteSheetTimer.value = timerId
    }

    const deleteSavedSheet = evt => {
        if (signalDeleteSheetTimer.value) {
            return
        }
        if (!signalDeleteSheetHash.value) {
            return
        }
        actions.savedSheetsRemove(signalDeleteSheetHash.value)
        signalDeleteSheetHash.value = null
    }


    const onFocusRename = evt => {
        let hash = evt.target.id.replace('key_', '')
        hash = Number(hash)
        let savedSheet = savedSheets.find(sc => sc.hash === hash)
        if (savedSheet) {
            if (savedSheet.message.message) {
                savedSheet.message = { message: "", color: "" }
                setSavedSheets(savedSheets)
            }
        }
    }

    const loadSheet = hash => {
        actions.topMenuView(null)
        let sheet = state.savedSheets[hash]
        if (sheet && sheet.sheetSettings && sheet.slots) {
            let sheetSettings = sheet.sheetSettings
            let slots = sheet.slots
            actions.loadSheet({ sheetSettings, slots })
        }
        else {
            console.warn('could not find saved sheet with hash', hash)
        }
    }

    const printSheet = hash => {
        let sheet = savedSheets.find(ss => ss.hash === hash)
        if (sheet && sheet.sheetSettings && sheet.sheetSettings.printableArea) {

            if (navigator.userAgent.toLowerCase().includes('firefox')) {
                printSheetFirefox(sheet)
                return
            }


            let printableArea = sheet.sheetSettings.printableArea
            if (Array.isArray(printableArea) && printableArea.length === 2) {
                let width = printableArea[0] * 0.0393701
                let height = printableArea[1] * 0.0393701
                let printWidth = Math.round(width * 300)
                let printHeight = Math.round(height * 300)
                const svgId = 'viewSavedSheet_' + hash
                const paperTarget = Snap("#" + svgId);
                let code = paperTarget.toString()



                var win = ''
                //     if (navigator.userAgent.toLowerCase().includes('firefox')) {

                let ratio = 800 / printWidth // I forget why I was doing this.
                printHeight *= ratio
                win = window.open('', '', 'width=' + printWidth + " ,height=" + printHeight)
                win.document.write(code)
                win.print()
                win.close()
            }
        }
    }

    const printSheetFirefox = sheet => {
        let sheetSettings = sheet.sheetSettings
        let printableArea = sheetSettings.printableArea
        const svgId = 'viewSavedSheet_' + sheet.hash
        const sheetPaper = Snap("#" + svgId);
        let width = printableArea[0] * 0.0393701 * 300
        let height = printableArea[1] * 0.0393701 * 300
        width = Math.round(width)
        height = Math.round(height)
        let image = new Image();
        let win = window.open('', '', 'width=' + width + " ,height=" + height)
        const getImageDataURL = (image, type = 'png') => {
            const canvas = document.createElement('canvas');
            const context = canvas.getContext('2d');
            canvas.width = width;
            canvas.height = height;
            context.drawImage(image, 0, 0, width, height);
            return canvas.toDataURL(`image/${type}`);
        }


        const convertSvg = (svgString, type = 'png') => new Promise((res, rej) => {
            image = new Image();
            image.onload = () => res(getImageDataURL(image, type));
            image.src = `data:image/svg+xml,${encodeURIComponent(svgString)}`;

        });

        let source = prepareSheetSvg(sheetPaper)
        convertSvg(source, 'png').then(dataURL => {
            win.document.write('<img width="' + width + '" height="' + height + '" src="' + dataURL + '" />');
            win.print()
            win.close()
        })
    }

    const prepareSheetSvg = (sheetPaper, width, height) => {
        let buildSheetPaper = Snap("#build_sheet")
        const originalSvgSource = sheetPaper.toString()

        buildSheetPaper.attr({ width: width, height: height });
        let parsed = Snap.parse(originalSvgSource)

        buildSheetPaper.append(parsed)

        let fakeWhiteBackgroundElements = buildSheetPaper.selectAll(`[data-type="fakeWhiteBackground"]`)
        fakeWhiteBackgroundElements.forEach(holder => {
            if (holder) {
                holder.remove()
            }
        })

        // let holderElements = buildSheetPaper.selectAll(`[data-type="holder"]`)
        // holderElements.forEach(holder => {
        //     if (holder) {
        //         holder.remove()
        //     }
        // })

        // let holderTexts = buildSheetPaper.selectAll(`[data-type="holderText"]`);
        // holderTexts.forEach(holderText => {
        //     if (holderText) {
        //         holderText.remove()
        //     }
        // })
        // let pageMargins = buildSheetPaper.selectAll(`[data-type="pageMargin"]`);
        // pageMargins.forEach(pageMargin => {
        //     if (pageMargin) {
        //         pageMargin.remove()
        //     }
        // })

        let preparedSvg = buildSheetPaper.toString()
        preparedSvg = preparedSvg.replaceAll('<defs></defs>', '')
        preparedSvg = preparedSvg.replaceAll('style="pointer-events: visiblepainted;"', '')
        buildSheetPaper.clear()
        return preparedSvg
    }

    return (
        <div className="view-saved-sheets">
            {savedSheets.length === 0 ?
                <div className="no-items">
                    {startupCheckDone ?
                        <span>No saved sheets have been found.</span>
                        :
                        <span>Looking up saved sheets.</span>
                    }
                </div>
                : savedSheets.map((sht, index) =>
                    <div className="item" key={index}>

                        <div id={'svg_container_' + sht.hash} className="display">
                            <svg id={'viewSavedSheet_' + sht.hash} />
                        </div>
                        <div className="form-name-container">
                            <div className="name-div">
                                name: <span>{sht.name}</span>
                            </div>
                            <div className="rename-div">
                                <div className="flex">
                                    <div className="input-control">
                                        <span>rename:</span>
                                        <input type="text"
                                            className={sht.newName.length === 0 ?
                                                'warning' :
                                                ''}
                                            id={'key_' + sht.hash}
                                            value={sht.newName}
                                            onChange={changeName}
                                            spellCheck="false"
                                            onFocus={onFocusRename}
                                        />
                                    </div>
                                </div>
                                <div className="message" style={{ color: sht.message.color, textShadow: "0 0 " + sht.message.color }}>{sht.message.message}</div>
                            </div>
                            <div className="submit-div">
                                <div className={signalDeleteSheetHash.value === sht.hash ? 'display-none' : 'actions'}>
                                    <button type="submit"
                                        className={sht.message.message || sht.newName.length === 0 ? 'standard-button disabled' : 'standard-button blue'}
                                        disabled={sht.message.message || sht.newName.length === 0}
                                        onClick={() => submitRename(sht.hash)}>Rename</button>
                                    <button id={`delete_${sht.hash}`} className="standard-button red" onClick={deleteConfirmSavedSheet}>Delete</button>
                                </div>
                                <div className={signalDeleteSheetHash.value === sht.hash ? 'actions' : 'display-none'}>
                                    <div><span>Press Delete again to confirm:</span></div>
                                    <button id={`delete_${sht.hash}`} className={signalDeleteSheetTimer.value ? 'standard-button disabled' : 'standard-button red'} onClick={deleteSavedSheet}>Delete</button>
                                </div>
                                <div className="actions">
                                    <button className="standard-button blue"
                                        onClick={() => loadSheet(sht.hash)}>Load</button>
                                    <button className="standard-button blue" onClick={() => printSheet(sht.hash)}>Print</button>
                                </div>
                            </div>

                        </div>
                        <div className="layer-data">
                            <div className="data-name-value">
                                <span className="data-name">date saved:</span>
                                <span className="data-value">{sht.dateSaved}</span>
                            </div>
                            <div className="data-name-value">
                                <span className="data-name">counter size:</span>
                                <span className="data-value">{sht.sheetSettings.counterSize}</span>
                            </div>
                            <div className="data-name-value">
                                <span className="data-name">layout:</span>
                                <span className="data-value">{sht.sheetSettings.countersColumnsRows[0]} columns, {sht.sheetSettings.countersColumnsRows[1]} rows</span>
                            </div>
                            <div className="data-name-value">
                                <span className="data-name">counter margins:</span>
                                <span className="data-value">left: {sht.sheetSettings.counterMargins[3]},
                                    right: {sht.sheetSettings.counterMargins[1]},
                                    top: {sht.sheetSettings.counterMargins[0]},
                                    bottom: {sht.sheetSettings.counterMargins[2]}
                                </span>
                            </div>
                            <div className="data-name-value">
                                <span className="data-name">page margins:</span>
                                <span className="data-value">left: {sht.sheetSettings.pageMargins[3]},
                                    right: {sht.sheetSettings.pageMargins[1]},
                                    top: {sht.sheetSettings.pageMargins[0]},
                                    bottom: {sht.sheetSettings.pageMargins[2]}
                                </span>
                            </div>
                            <div className="data-name-value">
                                <span className="data-name">printable area:</span>
                                <span className="data-value">width: {sht.sheetSettings.printableArea[0]},
                                    length: {sht.sheetSettings.printableArea[1]}
                                </span>
                            </div>
                            <div className="data-name-value">
                                <span className="data-name">cutting guide type:</span>
                                <span className="data-value">{sht.sheetSettings.guideType}</span>
                            </div>
                            <div className="data-name-value">
                                <span className="data-name">number of counters:</span>
                                <span className="data-value">{sht.slots.length}</span>
                            </div>
                            <div className="data-name-value">
                                <span className="data-name">approximate storage size:</span>
                                <span className="data-value">{sht.approxSize}</span>
                            </div>
                        </div>

                    </div>
                )}

            {/* {state.svgs.filter((svg, index) => svg.uniquePrepend && !svg.svgCode.includes('custom-image')).length === 0 ?
                <div className="no-custom-svgs">No custom svgs have been found. Try installing some.</div> : null} */}

            <div className="temp-sheet">
                <svg id="build_sheet" width="1200" height="1553" />
            </div>
        </div>
    );
}
export default ViewSavedSheets
