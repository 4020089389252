import React, { useEffect, useContext, useState } from 'react'
import { useSignal } from "@preact/signals-react"
import './CounterAreaCoordinatesReadout.scss'
import { StoreContext } from "../../context/StoreContext";
const CounterAreaCoordinatesReadout = ({hide}) => {
    const controller = new AbortController();
    const { signal } = controller;
    const signalmxy = useSignal(null)
    const signalDrawingAreaEle = useSignal(null)
    const { state } = useContext(StoreContext)
    const [timer, setTimer] = useState(null)

    useEffect(() => {
        const update = (e) => {
            if (e) {
                let rect = signalDrawingAreaEle.value.getBoundingClientRect();
                if (e.x >= rect.left && e.x < rect.right &&
                    e.y >= rect.top && e.y < rect.bottom) {
                    let x = e.x - rect.left; //x position within the element.
                    let y = e.y - rect.top;  //y position within the element.

                    let counterDrawWidth = signalDrawingAreaEle.value.offsetWidth;
                    let mul = 240 / counterDrawWidth
                    x *= mul; // need to modify it cause pixels for the mouse are not a direct match to pixels on the svg
                    y *= mul;
                    x -= 120
                    y -= 120
                    x = Math.round(x)
                    y = Math.round(y)
                    signalmxy.value = [x, y]
                }
                else {
                    signalmxy.value = null
                }
            }
        }

        signalDrawingAreaEle.value = document.getElementById('drawingArea')

        function throttle(func, wait) {
            let waiting = false;
            return function () {
                if (waiting) {
                    return;
                }

                waiting = true;
                setTimeout(() => {
                    func.apply(this, arguments);
                    waiting = false;
                }, wait);
            };
        }

        const onMove = throttle(evt => {
            update(evt)
        }, 20);

        window.addEventListener('mousemove', onMove, { signal })
        window.addEventListener('touchmove', onMove, { signal })

        return () => {
            if( timer ) {
                clearTimeout(timer)
            }
            controller.abort();
        }
    }, []) // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        if( timer && state.mouseOutCounterArea === false ) {
            clearTimeout(timer)
            setTimer(null)
            return
        }
        if( timer ) {
            return
        }
        if (state.mouseOutCounterArea === true) {
            // time out needed to out-wait the throttling of x y position feed.
            let _timer = setTimeout(() => {
                    signalmxy.value = null
                    setTimer(null)
            }, 130)
            setTimer(_timer)
        }
    }, [state.mouseOutCounterArea]) // eslint-disable-line react-hooks/exhaustive-deps

    return (
        <div className="counter-area-coordinates-readout">
            {signalmxy.value && !hide ?
                <div>x: <span>{signalmxy.value[0]}</span> y: <span>{signalmxy.value[1]}</span></div>
                : null}
        </div>
    )
}

export default CounterAreaCoordinatesReadout