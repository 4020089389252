import React, { useState, useEffect, useRef, useContext } from 'react'
import { useSignal } from "@preact/signals-react"
import { StoreContext } from "../../context/StoreContext"
import JSZip from 'jszip'
import Utility from "../../objects/Utility"
import CloseX from "../CloseX/CloseX"
import './LoadCounterControl.scss'
const LoadCounterControl = ({ loadCounter, longName }) => {
    const controller = new AbortController();
    const { signal } = controller;
    const signalLastSvgName = useSignal('')
    const { state, actions } = useContext(StoreContext)
    const [savedCounters, setSavedCounters] = useState([])
    const [dialogOpen, setDialogOpen] = useState(false)
    const loadCounterDialogRef = useRef(null)
    const [loadErrorLineItems, setLoadErrorLineItems] = useState([])

    useEffect(() => {
        loadCounterDialogRef.current.addEventListener('mouseup', function (e) {
            e.stopPropagation();
        }, { signal });

        return () => {
            controller.abort();
        };
    }, []) // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        if (state.overlayClick) {
            actions.counterAreaMenuState('')
            setDialogOpen(false)
        }
    }, [state.overlayClick]) // eslint-disable-line react-hooks/exhaustive-deps

    const closeSelf = () => {
        actions.counterAreaMenuState('')
        setDialogOpen(false)
    }

    useEffect(() => {
        createSavedCountersList()
    }, []) // eslint-disable-line react-hooks/exhaustive-deps

    const createSavedCountersList = () => {
        if (Utility.emptyCheck(state.savedCounters) === false) {
            let values = Object.values(state.savedCounters)
            setSavedCounters(values) // for local use
        }
    }

    const close = () => {
        setDialogOpen(false)
    }

    useEffect(() => {
        createSavedCountersList()
    }, [state.savedCounters]) // eslint-disable-line react-hooks/exhaustive-deps

    const toggleDialog = () => {
        setDialogOpen(!dialogOpen)
    }

    useEffect(() => {
        if (dialogOpen) {
            actions.counterAreaMenuState('load counter')
            actions.overlay(true)
        }
        else {
            if (state.counterAreaMenuState === 'load counter') {
                actions.counterAreaMenuState('')
                close()
            }
        }
    }, [dialogOpen]) // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        if (state.counterAreaMenuState !== 'load counter' && dialogOpen) {
            toggleDialog()
        }
        if (state.counterAreaMenuState === '') {
            actions.overlay(false)
        }
    }, [state.counterAreaMenuState]) // eslint-disable-line react-hooks/exhaustive-deps

    const setupCounter = counterState => {
        let sideActive = 'frontPackage'
        let activeLayerValues = JSON.parse(counterState.counterSideActive['front'])
        if (!counterState[sideActive].customImageSvgsData) {  // if from sheet the svgs are protected from deletion in the app.
            counterState[sideActive].customImageSvgsData = []
        }
        if (!counterState[sideActive].customSvgsData) {  // if from sheet the svgs are protected from deletion in the app.
            counterState[sideActive].customSvgsData = []
        }
        if (!counterState[sideActive].fontsData) {  // if from sheet the svgs are protected from deletion in the app.
            counterState[sideActive].fontsData = []
        }

        let incomingSvgs = []

        // add string field to retain number data that seems to get screwed up sometimes after unzip.
        if (counterState[sideActive].customImageSvgsData.length > 0 ||
            counterState[sideActive].customSvgsData.length > 0) {
            counterState[sideActive].customImageSvgsData.forEach(isvg => {
                isvg.combined = isvg.svgKey + ':' + isvg.svgName + ':' + 'svg' // eslint-disable-line no-useless-concat
                isvg.type = 'image'
                incomingSvgs.push(isvg)
            })
            counterState[sideActive].customSvgsData.forEach(isvg => {
                let combinedKey = isvg.svgKey + ':' + isvg.svgName + ':' + 'svg' // eslint-disable-line no-useless-concat
                isvg.combined = combinedKey
                isvg.type = 'svg'
                incomingSvgs.push(isvg)
            })
        }

        // create any dupe layers that dont exist in state but do in the incoming data
        let updatedStateLayers = [...state.layers]

        //  find if theres any svgs that need to be added, and if any svgKey changes need to be made.
        let changeIncomingSvgKeysFromTo = []
        if (incomingSvgs.length > 0) {
            changeIncomingSvgKeysFromTo = installAnyNeededSvgs(incomingSvgs)
        }
        // update incoming activeLayerValues to reflect any svgKey changes
        counterState[sideActive].layers.forEach(ly => {
            if (ly.layerActive) {
                if (Utility.isCustomLayer(ly.layerKey, state.layers) || Utility.isCustomLayer(ly.parentLayerKey, state.layers)) {
                    let svgInputKey = ly.layerActiveRequiredInputKey
                    let currentSvgKey = activeLayerValues[ly.layerKey + '_' + svgInputKey]
                    let foundChange = changeIncomingSvgKeysFromTo.find(ch => ch.fromSvgKey === currentSvgKey)
                    if (foundChange) {
                        activeLayerValues[ly.layerKey + '_' + svgInputKey] = foundChange.toSvgKey
                        // apply same change to the rear activeLayerValues, if the key exists.
                        if (counterState.counterSideActive) {
                            if (counterState.counterSideActive.rear) {
                                let rearAlv = JSON.parse(counterState.counterSideActive.rear)
                                if (rearAlv[ly.layerKey + '_' + svgInputKey]) {
                                    rearAlv[ly.layerKey + '_' + svgInputKey] = foundChange.toSvgKey
                                    counterState.counterSideActive.rear = JSON.stringify(rearAlv)
                                }
                            }
                        }
                    }
                    else {
                        // change not found. But it may still not be pointing at the right one
                        // since a previous load counter may had already installed the svg
                        // so - it won't be installed and so the activeLayerValues wont get updated.
                    }
                }
            }
        })


        if (changeIncomingSvgKeysFromTo.length > 0) {
            updatedStateLayers = updateStateLayersListWithNewSvgKeys(changeIncomingSvgKeysFromTo)
        }

        let { newStateLayers, layerKeyChanges } = createNewStateLayersWithDupes(counterState['frontPackage'].layers, updatedStateLayers)
        // bring in any order changes for the layers
        counterState['frontPackage'].layers.forEach(layer => {
            let found = newStateLayers.find(slr => slr.layerKey === layer.layerKey)
            if (found) {
                found.layerOrder = layer.layerOrder
            }
        })

        let newActiveLayerValues = { activeLayerValues }
        if (layerKeyChanges.length > 0) {
            newActiveLayerValues = updateActiveLayerValues(layerKeyChanges, newActiveLayerValues)
        }
        // activate all state layers that exists in the incoming layers.
        let activeLayerKeys = Utility.activeLayerKeys(newActiveLayerValues)
        newStateLayers.forEach(newLayer => {
            if (activeLayerKeys.includes(newLayer.layerKey)) {
                newLayer.layerActive = 1
                newLayer.layerHidden = 0
            }
        })
        newStateLayers.sort((a, b) => a.layerOrder - b.layerOrder)
        newStateLayers.forEach((ly, index) => {
            ly.layerOrder = index
        })
        newStateLayers.forEach(ly => {
            if (ly.layerActive) {
                if (Utility.isCustomLayer(ly.layerKey, state.layers)) {
                    let svgInputKey = ly.layerActiveRequiredInputKey
                    if (svgInputKey) {
                        let expectingSvgKey = newActiveLayerValues[ly.layerKey + '_' + svgInputKey]
                        let doesSvgExist = state.svgs.find(ss => ss.svgKey === expectingSvgKey)
                        if (!doesSvgExist) {// see if it was newly added
                            doesSvgExist = changeIncomingSvgKeysFromTo.find(cik => cik.toSvgKey === expectingSvgKey)
                        }
                        if (!doesSvgExist) {
                            //newStateLayers = newStateLayers.filter( nsl => nsl.layerKey !== ly.layerKey )
                            newStateLayers.forEach(nsl => {
                                if (nsl.layerKey === ly.layerKey) {
                                    nsl.layerActive = 0
                                    let input = nsl.inputs.find(li => li.named === 'svgKey')
                                    if (input) {
                                        if (input.list && Array.isArray(input.list) && input.list.length === 0) {
                                            nsl.layerHidden = 1
                                        }
                                    }
                                }
                            })
                            newActiveLayerValues = Utility.removeLayerFromActiveLayerValues(ly.layerKey, newActiveLayerValues)
                        }
                 }
                }
            }
        })
        actions.layers(newStateLayers)
        if (JSON.stringify(newActiveLayerValues) !== JSON.stringify(state.activeLayerValues)) {
            actions.activeLayerValuesReset(newActiveLayerValues)
        }
        closeSelf()
    }

    const loadFromApp = evt => {
        if (state.savedCounters) {
            let hash = evt.target.value
            if (hash) {
                hash = Number(hash)
                let counterObj = state.savedCounters[hash]
                if (counterObj) {
                    actions.counterLoadFromApp(counterObj)
                }
            }
        }
    }

    useEffect(() => {
        if (state.counterLoadFromApp) {
            setDialogOpen(false)
        }
    }, [state.counterLoadFromApp])

    useEffect(() => {
        if (state.counterLoadFromFile) {
            setDialogOpen(false)
        }
    }, [state.counterLoadFromFile])

    useEffect(() => {
        if (state.loadCounterProcessing) {
            setupCounter(state.loadCounterProcessing)
            actions.loadCounterProcessing(null)
        }
    }, [state.loadCounterProcessing]) // eslint-disable-line react-hooks/exhaustive-deps

    // when loading from file, we may have haveduplicated layers, fonts, and custom images and svgs that aren't present in the
    // current environment.
    const loadFromFile = evt => {
        if (evt.target.files && evt.target.files.length > 0) {
            let file = evt.target.files[0]
            if (file) {
                JSZip.loadAsync(file).then((zip) => {
                    Object.keys(zip.files).forEach((filename) => {
                        zip.files[filename].async('string').then(async (fileData) => {
                            let data = null
                            try {
                                data = JSON.parse(fileData)
                            }
                            catch (e) {
                                console.warn('error loading file')
                                return
                            }
                            if (!data) {
                                actions.addErrorMessages([{
                                    comment: "File load problem:",
                                    lineItems: ['Not able to extract data']
                                }])
                                return
                            }
                            if (data.slots) {
                                actions.addErrorMessages([{
                                    comment: "File load problem:",                   
                                    lineItems: ['This file is a sheet file, not a counter file.']
                                }])
                                return
                            }
                            else {
                                if (!data.app || !data.counterState || !data.type) {
                                    actions.addErrorMessages([{
                                        comment: "File load problem:",
                                        lineItems: ['This file does not seem to be a counter file.']
                                    }])
                                    return
                                }
                                else {
                                    if (data.type !== 'counter') {
                                        actions.addErrorMessages([{
                                            comment: "File load problem:",
                                            lineItems: ['This file is not a counter file.']
                                        }])
                                        return
                                    }
                                }
                            }
                            if( !data.counterState ) {
                                actions.addErrorMessages([{
                                    comment: "File load problem:",
                                    lineItems: ['This file is incorrectly assembled.']
                                }])
                                return
                            }
                            if( !data.version ) {
                                data.counterState.version = 2
                            }
                            if( data.version === 3 ) {
                                data.counterState.version = 3
                            }
                            actions.counterLoadFromFile(data.counterState)

                        })
                    })
                    document.getElementById("counterFile").value = "";
                })
            }
        }
    }

    useEffect(() => {
        if (state.svgs.length > 0) {
            signalLastSvgName.current = state.svgs[state.svgs.length - 1].svgName
        }
    }, [state.svgs]) // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {

        if (loadErrorLineItems.length > 0) {
            actions.addErrorMessages([{
                comment: "There are missing items in your counter file:",
                lineItems: loadErrorLineItems
            }])
            setLoadErrorLineItems([])
        }
    }, [state.activeLayerValues]) // eslint-disable-line react-hooks/exhaustive-deps

    const updateActiveLayerValues = (layerKeyChanges, newActiveLayerValues) => {
        let updatedNewActiveLayerValues = { ...newActiveLayerValues }
        layerKeyChanges.forEach(lkc => {
            let fromLayerKey = lkc.fromLayerKey
            let toLayerKey = lkc.toLayerKey
            for (const [key, value] of Object.entries(newActiveLayerValues)) {
                if (key.startsWith(fromLayerKey + '_')) {
                    let newKey = key.replace(fromLayerKey + '_', toLayerKey + '_')
                    delete updatedNewActiveLayerValues[key]
                    updatedNewActiveLayerValues[newKey] = value
                }
            }
        })

        return updatedNewActiveLayerValues
    }

    const createNewStateLayersWithDupes = (incomingLayers, updatedStateLayers) => {
        let updatedIncomingLayers = [...incomingLayers]
        let newStateLayers = [...updatedStateLayers]
        let layerKeyChanges = [] // for updating activeLayerValues
        // preparation for adding new layers
        let maxLayerKey = Math.max(...newStateLayers.map(ly => parseInt(ly.layerKey)), 0)
        // if its coming from the sheet, the fonts and custom svgs and images are already in state. We just need to worry about
        // duplicated layers at this point, and updating activeLayerValues if layer keys were changed or added.
        //actions.activeLayerValuesReset(counterState.activeLayerValues)

        // deactivate all state layers, except for base counter
        newStateLayers.forEach(ly => {
            if (ly.layerKey > 0) {
                ly.layerActive = 0
            }
        })

        // we are only concerninstallSvgsed about incoming duped layers.
        let incomingDupeLayers = incomingLayers.filter(cl => cl.parentLayerKey > -1)

        // now we have a list of duped layers that are incoming.
        // see if there are direct matches in current state layers
        let removeDupeLayerKeys = []
        incomingDupeLayers.forEach(idl => {
            let foundMatchingDupeStateLayer = newStateLayers.find(usl => usl.layerKey === idl.layerKey && usl.parentLayerKey === idl.parentLayerKey)
            if (foundMatchingDupeStateLayer) {
                removeDupeLayerKeys.push(idl.layerKey)
            }
        })

        incomingDupeLayers = incomingDupeLayers.filter(idl => removeDupeLayerKeys.includes(idl.layerKey) === false)

        if (incomingDupeLayers.length > 0) {
            incomingDupeLayers.forEach(incomingDupeLayer => {
                let foundMatchingDupeStateLayers = newStateLayers.filter(usl => usl.parentLayerKey === incomingDupeLayer.parentLayerKey)
                if (foundMatchingDupeStateLayers) { // 0, 1, or more can match.
                    // need to find if this matching dupe layer can be used as a substitute.
                    for (let i = 0; i < foundMatchingDupeStateLayers.length; i++) {
                        let possibleMatch = foundMatchingDupeStateLayers[i]
                        let foundUsedByAnotherIncomingLayer = incomingDupeLayers.find(idl => idl.layerKey === possibleMatch.layerKey)
                        if (foundUsedByAnotherIncomingLayer) {
                            continue;
                        }
                        if (!foundUsedByAnotherIncomingLayer) {
                            layerKeyChanges.push({ fromLayerKey: incomingDupeLayer.layerKey, toLayerKey: possibleMatch.layerKey })
                            // dont need to worry about this incoming dupe layer anymore.
                            removeDupeLayerKeys.push(incomingDupeLayer.layerKey)
                            break;
                        }
                    }
                }
            })
            incomingDupeLayers = incomingDupeLayers.filter(idl => removeDupeLayerKeys.includes(idl.layerKey) === false)
        }

        if (incomingDupeLayers.length > 0) {
            // if we still have incoming dupe layers that dont directly match state layers, or that couldnt be reassigned a duped state layer, 
            // we need to create new layer(s).

            incomingDupeLayers.forEach(idl => {
                // get the parent layer we will make a dupe of
                let stateLayerToDupe = newStateLayers.find(nl => nl.layerKey === idl.parentLayerKey)
                stateLayerToDupe = JSON.parse(JSON.stringify(stateLayerToDupe))
                maxLayerKey++
                stateLayerToDupe.parentLayerKey = stateLayerToDupe.layerKey
                stateLayerToDupe.layerKey = maxLayerKey

                let duplicatedName = newStateLayers.find(nsl => nsl.layerName === idl.layerName)
                if (duplicatedName) {
                    let i = 2
                    for (i; i < 100; i++) {
                        let tryName = idl.layerName + i
                        duplicatedName = newStateLayers.find(nsl => nsl.layerName === tryName)
                        if (!duplicatedName) {
                            stateLayerToDupe.layerName = tryName
                            break
                        }
                    }
                    if (i > 99) {
                        stateLayerToDupe.layerName = idl.layerName + '_' + Utility.randomString(5)
                    }
                }

                else {
                    stateLayerToDupe.layerName = idl.layerName
                }

                let oldIncomingDupeLayerKey = idl.layerKey
                layerKeyChanges.push({ fromLayerKey: oldIncomingDupeLayerKey, toLayerKey: maxLayerKey })
                newStateLayers.push(stateLayerToDupe)
            })
        }

        layerKeyChanges.forEach(lkc => {
            let found = updatedIncomingLayers.find(uil => uil.layerKey === lkc.fromLayerKey)
            if (found) {
                found.layerKey = lkc.toLayerKey
            }
        })

        return { updatedIncomingLayers, newStateLayers, layerKeyChanges }
    }

    const installAnyNeededSvgs = (checkSvgs) => {
        if (!checkSvgs) {
            return
        }
        // this will hold any svgs that need to be installed.
        let installSvgs = []
        let changeIncomingSvgKeysFromTo = []

        // this creates a list of currently existing custom svgs.
        let listOfCustomStateSvgsCleanedCode = state.svgs.filter(svg => svg.svgKey >= 1000)
        listOfCustomStateSvgsCleanedCode = JSON.parse(JSON.stringify(listOfCustomStateSvgsCleanedCode))
        // strip the svgs of any prepend strings so we can do a svgCode comparison later
        listOfCustomStateSvgsCleanedCode.forEach(css => {
            if (css.uniquePrepend) {
                css.svgCode = css.svgCode.replaceAll(css.uniquePrepend, '')
            }
        })

        // put the incoming svgs into one array so we can process them together.

        if (checkSvgs.length > 0) {

            // useKey will be the start point from adding new svgKeys
            let useKey = state.svgs.reduce((a, b) => Number(a.svgKey) > Number(b.svgKey) ? a : b).svgKey
            for (let i = 0; i < checkSvgs.length; i++) {
                let incomingSvg = checkSvgs[i]
                let splittedCombined = incomingSvg.combined.split(':')
                let svgKeyFromCombined = Number(splittedCombined[0])
                let svgTypeFromCombined = splittedCombined[2]

                if (svgKeyFromCombined !== incomingSvg.svgKey) {
                    incomingSvg.svgKey = svgKeyFromCombined
                }
                if (svgTypeFromCombined !== incomingSvg.type) {
                    incomingSvg.svgType = svgTypeFromCombined
                }

                let matchCode = incomingSvg.svgCode
                if (incomingSvg.uniquePrepend) {
                    matchCode = incomingSvg.svgCode.replaceAll(incomingSvg.uniquePrepend, '')
                }

                // see if theres a direct match. If so, nothing needs to be done.
                let foundSvg = listOfCustomStateSvgsCleanedCode.find(ssvg => ssvg.svgKey === incomingSvg.svgKey && ssvg.svgCode === matchCode)
                if (!foundSvg) {
                    // see if theres a code match
                    foundSvg = listOfCustomStateSvgsCleanedCode.find(ssvg => ssvg.svgCode === matchCode)
                    if (foundSvg) {
                        changeIncomingSvgKeysFromTo.push({ fromSvgKey: incomingSvg.svgKey, toSvgKey: foundSvg.svgKey, type: incomingSvg.type, new: false, nameIncoming: incomingSvg.svgName, nameExisting: foundSvg.svgName })
                    }
                    else {
                        // insert incoming svg to state as new svg
                        if (useKey < 3000) {
                            useKey = 3000
                        }
                        else {
                            useKey++
                        }
                        changeIncomingSvgKeysFromTo.push({ fromSvgKey: incomingSvg.svgKey, toSvgKey: useKey, type: incomingSvg.type, new: true, nameIncoming: incomingSvg.svgName, nameExisting: null })
                        incomingSvg.svgKey = useKey
                        installSvgs.push(incomingSvg)
                    }
                }
            }

            if (installSvgs.length > 0) {
                // delete the temporarily added keys
                installSvgs.forEach(isvg => {
                    delete isvg.combined
                    delete isvg.type
                })
                let newStateSvgs = [...state.svgs, ...installSvgs]
                actions.svgs(newStateSvgs)
            }
        }

        return changeIncomingSvgKeysFromTo
    }

    const updateStateLayersListWithNewSvgKeys = svgKeyUpdates => {
        // update the state layers of custom svg/image svg types to include the new svg keys
        let stateLayers = JSON.parse(JSON.stringify(state.layers))
        if (!svgKeyUpdates) {
            return stateLayers
        }
        let customImageSvgLayers = Utility.allLayersOfType(stateLayers, 'custom images')
        let customSvgLayers = Utility.allLayersOfType(stateLayers, 'custom svgs')
        svgKeyUpdates.forEach(svgSet => {
            let type = svgSet.type
            let newSvgKey = svgSet.toSvgKey
            if (type === 'image') {
                customImageSvgLayers.forEach(cil => {
                    let input = cil.inputs.find(li => li.named === 'svgKey')
                    if (input) {
                        if (input.list && Array.isArray(input.list)) { // make sure theres nothing funky going on. Better to do nothing than crash.
                            if (input.list.includes(newSvgKey) === false) {
                                input.list.push(newSvgKey)
                                cil.layerHidden = 0 // not sure if I should do this. But, I guess I will so the user knows svgs were loaded.
                                cil = { ...cil }
                            }
                        }
                    }
                })

            }
            if (type === 'svg') {
                customSvgLayers.forEach(csl => {
                    let input = csl.inputs.find(li => li.named === 'svgKey')
                    if (input) {
                        if (input.list && Array.isArray(input.list)) { // make sure theres nothing funky going on. Better to do nothing than crash.
                            if (input.list.includes(newSvgKey) === false) {
                                input.list.push(newSvgKey)
                                csl.layerHidden = 0 // not sure if I should do this. But, I guess I will so the user knows svgs were loaded.
                                csl = { ...csl }
                            }
                        }
                    }
                })

            }
        })

        return stateLayers
    }




    useEffect(() => {
        // if (state.counterLoadFromSheet) {
        //     setupCounter(state.counterLoadFromSheet)
        //     actions.counterLoadFromSheet(null)
        // }
    }, [state.counterLoadFromSheet])

    useEffect(() => {
        // if (state.counterLoadFromApp) {
        //     actions.loadCounterProcessing(state.counterLoadFromApp)
        //     actions.counterLoadFromApp(null)
        // }
    }, [state.counterLoadFromApp])

    useEffect(() => {
        // if (state.counterLoadFromFile) {
        //     actions.loadCounterProcessing(state.counterLoadFromFile)
        //     actions.counterLoadFromFile(null)
        // }
    }, [state.counterLoadFromFile])

    return (
        <div className="load-counter-control">
            <button className="standard-button blue" onClick={toggleDialog}><div>Load{longName ? ' Counter' : ''}</div></button>
            <div ref={loadCounterDialogRef} id="dialog" className={dialogOpen ? 'dialog' : 'display-none'}>
                <div className="title">
                    <div></div>
                    <div>Load Counter</div>
                    <CloseX close={toggleDialog} />
                </div>
                <div className="select-input">

                    <div className="load-section">
                        <div className="load-section-title">LOAD FROM APP</div>
                        <div className="load-input-inline">
                            <div>load counter:</div>
                            <select id="savedCounter" value="" onChange={loadFromApp}>
                                <option key="none" value="">choose counter</option>
                                {
                                    savedCounters.map((sc, index) => <option key={index} value={sc.hash}>{sc.name}</option>)
                                }
                            </select>
                        </div>
                    </div>

                    <div className="load-section load-from-file">
                        <div className="load-section-title">LOAD FROM FILE</div>
                        <input type="file" id="counterFile" accept=".scz" onChange={loadFromFile} />
                    </div>
                    <svg id="extractorSvg" />
                </div>
            </div>
        </div>




    )

}
export default LoadCounterControl
/*
<svg id="vm4sgjl_czk87bzto_svg2483" viewBox="0 0 469 497.6" version="1.0">
  <g id="vm4sgjl_czk87bzto_layer8" transform="translate(-86.225 -49.534)">
    <g id="vm4sgjl_czk87bzto_g7275" clip-path="url(#vm4sgjl_czk87bzto_clipPath7279)">





<svg id="p3cc6t_tiger" viewBox="0 0 469 497.59" version="1.0" > 
<defs id="p3cc6t_defs2486" > <linearGradient id="p3cc6t_linearGradient7303" > <stop id="p3cc6t_stop7305" stop-color="#808080" offset="0" /> 
<g id="p3cc6t_layer8" transform="translate(-86.225 -49.534)" >
 <g id="p3cc6t_g7275" clip-path="url(#p3cc6t_clipPath7279)" >
  <rect id="p3cc6t_rect7271" style="color:#000000" height="138.59" width="128.69" y="43.243" x="121.27" fill="#c1782b" /> <path id="p3cc6t_path7354" d="m190.57 126.33c-10.253-20.86-15.556-28.284-14.849-45.255 0.7071-16.971 8.1317-29.345-5.6569-25.809-13.789 3.5355-35.709 31.466-30.406 45.962 5.3033 14.496 25.809 39.952 34.295 46.669 8.4853 6.7175 24.042 6.7175 16.617-21.567z" fill="url(#p3cc6t_radialGradient7362)" /> <path id="p3cc6t_path7364" d="m218.14 96.629c0.00001 9.8995-3.8891 20.506-9.5459 14.849-5.6568-5.6569-18.031-22.274-19.092-29.698-1.0607-7.4246-4.2426-17.678-10.96-17.324-6.7175 0.35355-22.792 10.997-26.516 25.456-3.6365 14.117 1.7678 20.153 5.3033 27.931 3.5355 7.7782 27.931 32.173 31.113 37.83 3.182 5.6569 5.6568 21.92-3.182 24.749-8.8388 2.8284-21.92-7.0711-27.577-18.031-5.6568-10.96-25.456-62.225-25.102-76.014 0.35355-13.789 15.91-33.234 29.345-39.598s31.113-11.314 40.659 0.35355c14.226 10.489 24.009 44.81 15.556 49.497z" /> </g > <g id="p3cc6t_g7287" clip-path="url(#p3cc6t_clipPath7291)" > <rect id="p3cc6t_rect7285" style="color:#000000" height="183.85" width="141.42" y="32.636" x="377.6" fill="#c1782b" /> <path id="p3cc6t_path7300" style="color:#000000" fill="url(#p3cc6t_radialGradient7352)" d="m432.02 155.45c5.732 5.139 12.953-16.574 16.088-29.452 6.0923-25.027-2.6806-41.486 8.9471-54.592 10.813-12.187 23.736-6.4851 34.941 5.9174 5.4923 6.0794 6.5024 14.777 4.6848 22.609-2.71 11.677-8.7254 22.091-13.182 33.057-2.9515 7.2626-3.3031 10.381-1.9111 17.968 4.1534 22.638-13.233 30.449-31.403 24.964l-18.165-20.471z" /> <path id="p3cc6t_path7313" d="m404 119c22.5-13 30.5-29 37-38.5s14-21.5 24.5-19 26 12.5 28.5 20-1.5 26-2.5 29.5-9.5 7.5-12 16 4 25 3.5 30.5-9.5 32-13 37-1 17.5 12.5 10.5 26-63 32.5-89 23-53.5-7-65.5-46.5-18.5-66-7-59 61.5-38 75.5z" /> </g > </g > <g id="p3cc6t_layer10" transform="translate(-86.225 -49.534)" > <path id="p3cc6t_path7065" style="color:#000000" 
*/