import React, { useState, useEffect, useContext } from 'react'
import { useSignal } from "@preact/signals-react"
import Snap from 'snapsvg-cjs'
import { StoreContext } from "../../context/StoreContext"
import Utility from "../../objects/Utility"
import './ViewSavedCounters.scss'

const ViewSavedCounters = ({ mode, close, optionalInfo }) => {
    const { state, actions } = useContext(StoreContext)
    const [savedCounters, setSavedCounters] = useState([])
    const [successMessage, setSuccessMessage] = useState({ hash: null, message: { message: '', color: '' } })
    const signalDeleteCounterHash = useSignal(null)
    const signalDeleteCounterTimer = useSignal(null)
    const signalCancelTimer = useSignal(null)

    useEffect(() => {
        return () => {
            if (signalDeleteCounterTimer.value) {
                clearTimeout(signalDeleteCounterTimer.value)
            }
            if (signalCancelTimer.value) {
                clearTimeout(signalCancelTimer.value)
            }
        }
    }, []) // eslint-disable-line react-hooks/exhaustive-deps

    const changeName = evt => {
        let hash = evt.target.id
        hash = hash.replace('key_', '')
        hash = Number(hash)
        let savedCounter = savedCounters.find(sc => sc.hash === hash)
        savedCounter.newName = evt.target.value
        savedCounter.message = { message: "", color: "" }
        setSavedCounters([...savedCounters])
    }

    const submitRename = hash => {
        let savedCounter = savedCounters.find(sc => sc.hash === hash)
        let newName = savedCounter.newName
        let origName = savedCounter.name

        let testNewName = newName.toLowerCase().replace(/\s\s+/g, ' ')
        let testOrigName = origName.toLowerCase().replace(/\s\s+/g, ' ')
        if (testNewName === testOrigName) {
            savedCounter.message = { message: "The new name should be different", color: "red" }
            setSavedCounters([...savedCounters])
            return
        }

        let regexResult = testNewName.match(/[-_)( a-zA-Z0-9]+$/)
        if (regexResult === null || (regexResult && regexResult.index > 0)) {
            savedCounter.message = { message: "Only alpha-numeric, spaces, and - _ ) ('", color: "red" }
            setSavedCounters([...savedCounters])
            return
        }

        let _stateSavedCounter = state.savedCounters[hash]
        _stateSavedCounter.name = newName.replace(/\s\s+/g, ' ')
        if (_stateSavedCounter.name.length > 1) {
            setSuccessMessage({ hash, message: { message: "name has been updated", color: "green" } })
            actions.savedCounters({ ...state.savedCounters })
        }
    }

    useEffect(() => {
        if (!Utility.emptyCheck(state.savedCounters)) {
            let _savedCounters = []
            // convert to array
            for (const [key, value] of Object.entries(state.savedCounters)) { // eslint-disable-line no-unused-vars
                _savedCounters.push(value)
            }
            let savedCountersAddedFields = _savedCounters.map(sc => {
                let message = { message: "", color: "" }
                if (savedCounters.length > 0) {
                    let hash = sc.hash
                    // retrieve any success message
                    if (successMessage.hash === hash) {
                        message = successMessage.message
                    }
                }

                let imageSvgNames = []
                if (sc.customImageSvgKeys && sc.customImageSvgKeys.length > 0) {
                    sc.customImageSvgKeys.forEach(imageKey => {

                        let svgFound = state.svgs.find(ss => ss.svgKey === imageKey)
                        if (svgFound) {
                            if (imageSvgNames.includes(svgFound.svgName) === false) {
                                imageSvgNames.push(svgFound.svgName)
                            }
                        }

                    })
                }
                let svgNames = []
                if (sc.customSvgKeys && sc.customSvgKeys.length > 0) {
                    sc.customSvgKeys.forEach(svgKey => {

                        let svgFound = state.svgs.find(ss => ss.svgKey === svgKey)
                        if (svgFound) {
                            if (svgNames.includes(svgFound.svgName) === false) {
                                svgNames.push(svgFound.svgName)
                            }
                        }

                    })
                }
                return { ...sc, message, newName: sc.name, imageSvgNames, svgNames }
            })
            setSavedCounters(savedCountersAddedFields)
        }
        else {
            setSavedCounters([])
        }

    }, [state.savedCounters]) // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        if (savedCounters.length === 1) {
            optionalInfo('There is 1 saved counter')
        }
        else {
            optionalInfo('There are ' + savedCounters.length + ' saved counters')
        }

        savedCounters.forEach(sc => {
            if (sc.hash && sc.layerSvgs) {
                let { hash, layerSvgs } = sc
                const svgId = 'viewSavedCounter_' + hash
                const paperTarget = Snap("#" + svgId);
                paperTarget.clear()
                layerSvgs.forEach(layerSvg => {
                    let parsed = Snap.parse(layerSvg)
                    if (parsed) {
                        paperTarget.append(parsed)
                    }
                })
            }
        })
    }, [savedCounters]) // eslint-disable-line react-hooks/exhaustive-deps

    const deleteConfirmSavedCounter = evt => {
        if (signalDeleteCounterTimer.value === null) {
            let hash = Number(evt.target.id.replace('delete_', ''))
            signalDeleteCounterHash.value = hash
            let timerId = setTimeout(() => {
                signalDeleteCounterTimer.value = null
            }, 200)
            signalDeleteCounterTimer.value = timerId

            if (signalCancelTimer.value === null) {
                let cancelTimerId = setTimeout(() => {
                    clearTimeout(signalDeleteCounterTimer.value)
                    signalDeleteCounterTimer.value = null
                    signalDeleteCounterHash.value = null
                    signalCancelTimer.value = null
                }, 3000)
                signalCancelTimer.value = cancelTimerId
            }
        }
    }

    const deleteSavedCounter = evt => {
        if (signalDeleteCounterTimer.value) {
            return
        }
        if (!signalDeleteCounterHash.value) {
            return
        }
        actions.savedCountersRemove(signalDeleteCounterHash.value)
        signalDeleteCounterHash.value = null
        if (signalCancelTimer.value) {
            clearTimeout(signalCancelTimer.value)
            signalCancelTimer.value = null
        }
    }

    const layerClick = evt => {
        if (evt && evt.target && evt.target.id && evt.target.id) {
            let splitted = evt.target.id.split('_')
            if (splitted.length === 2) {
                let nums = splitted.map(sp => sp.replace('h', '').replace('k', ''))
                nums = nums.map(nm => Number(nm))
                let [hash, layerKey] = [...nums]
                toggleLayerVisibility(hash, layerKey, evt.target)
            }
        }
    }

    const toggleLayerVisibility = (hash, layerKey, divTarget) => {
        let svgEle = document.getElementById('viewSavedCounter_' + hash)
        let layerSvgEles = svgEle.querySelectorAll("[id$=svgLayer" + layerKey + "]")
        if (layerSvgEles.length > 0) {
            let targetSvgLayer = layerSvgEles[0]
            const list = targetSvgLayer.classList;
            if (list.length > 0) {
                list.remove("display-none")
                divTarget.classList.remove("hidden")
            }
            else {
                list.add("display-none");
                divTarget.classList.add("hidden")
            }
        }
    }

    const loadSavedCounter = evt => {
        if (evt.target.id) {
            let hash = evt.target.id.replace('load_', '')
            if (hash.length > 0) {
                if (Utility.isNumeric(hash)) {
                    hash = Number(hash)
                    let counterObj = savedCounters.find(sc => sc.hash === hash)
                    if (counterObj) {
                        actions.counterLoadFromApp(JSON.parse(JSON.stringify(counterObj)))
                        close()
                    }
                }
            }
        }
    }

    return (
        <div className="view-saved-counters">
            {savedCounters.length === 0 ?
                <div className="no-items">No saved counters have been found.</div>
                :


                savedCounters.map((cnt, index) =>
                    <div className="display-container" key={index}>
                        <div className="display">
                            <svg id={'viewSavedCounter_' + cnt.hash} width="100%" height="100%" viewBox="0 0 260 260" />
                        </div>
                        <div className="form-name-container">
                            <div className="name-div">
                                name: <span>{cnt.name}</span>
                            </div>
                            <div className="rename-div">
                                <div className="flex">
                                    <div className="input-control">
                                        <span>rename:</span>
                                        <input type="text"
                                            className={cnt.newName.length === 0 ?
                                                'warning' :
                                                ''}
                                            id={'key_' + cnt.hash}
                                            value={cnt.newName}
                                            onChange={changeName}
                                        />
                                    </div>
                                </div>
                                <div className="message" style={{ color: cnt.message.color, textShadow: "0 0 " + cnt.message.color }}>{cnt.message.message}</div>
                            </div>
                            <div className="submit-div">
                                <div className={signalDeleteCounterHash.value === cnt.hash ? 'display-none' : 'actions'}>
                                    <button type="submit"
                                        className={cnt.message.message || cnt.newName.length === 0 ? 'standard-button disabled' : 'standard-button blue'}
                                        disabled={cnt.message.message || cnt.newName.length === 0}
                                        onClick={() => submitRename(cnt.hash)}>rename</button>
                                    <button id={`delete_${cnt.hash}`} className="standard-button blue" onClick={deleteConfirmSavedCounter}>Delete</button>
                                </div>
                                <div className={signalDeleteCounterHash.value === cnt.hash ? 'actions' : 'display-none'}>
                                    <div><span>Press Delete again to confirm:</span></div>
                                    <button id={`delete_${cnt.hash}`} className={signalDeleteCounterTimer.value ? 'standard-button disabled' : 'standard-button red'} onClick={deleteSavedCounter}>Delete</button>
                                </div>
                            </div>
                            <div className="submit-div second-row">
                                <div className={signalDeleteCounterHash.value === cnt.hash ? 'display-none' : 'actions'}>
                                    <button id={`load_${cnt.hash}`} className="standard-button blue" onClick={loadSavedCounter}>Load</button>
                                </div>
                            </div>

                        </div>
                        <div className="layer-data">

                            <div className="date-saved">date saved: <span>{cnt.dateSaved}</span></div>
                            <div>{cnt.layers.length} layers used:
                                <div className="layers-list">
                                    {cnt.layers.map(ly => {
                                        return (
                                            <div className="layer-background" id={`h${cnt.hash}_k${ly.layerKey}`} key={ly.layerKey} onClick={layerClick}>
                                                <span className="layer-name">{ly.layerName}</span>
                                            </div>
                                        )
                                    })}
                                </div>
                                {cnt.fonts.length > 0 ?
                                    <div className="extras-list">{cnt.fonts.length} font{cnt.fonts.length > 1 ? 's' : ''} used:
                                        <span>{cnt.fonts.join(', ')}</span>
                                    </div>
                                    : ''}
                                {cnt.imageSvgNames.length > 0 ?
                                    <div className="extras-list">{cnt.imageSvgNames.length} custom image{cnt.imageSvgNames.length > 1 ? 's' : ''} used:
                                        <span>{cnt.imageSvgNames.join(', ')}</span>
                                    </div>
                                    : ''}
                                {cnt.svgNames.length > 0 ?
                                    <div className="extras-list">{cnt.svgNames.length} custom svg{cnt.svgNames.length > 1 ? 's' : ''} used:
                                        <span>{cnt.svgNames.join(', ')}</span>
                                    </div>
                                    : ''}
                            </div>
                        </div>
                    </div>

                )}

            {/* {state.svgs.filter((svg, index) => svg.uniquePrepend && !svg.svgCode.includes('custom-image')).length === 0 ?
                <div className="no-custom-svgs">No custom svgs have been found. Try installing some.</div> : null} */}
        </div>
    );
}
export default ViewSavedCounters
