import React, { useEffect, useContext } from 'react'
import { StoreContext } from "../../context/StoreContext"
import './Overlay.scss'

const Overlay = () => {
    const controller = new AbortController();
    const { signal } = controller;
    const { actions } = useContext(StoreContext)

    useEffect(() => {
        const handleMouseUp = evt => {
            evt.stopPropagation()
            if (evt) {
                actions.overlayClick(evt)
                actions.overlay(false)
            }
        }
        document.addEventListener("mouseup", handleMouseUp, { signal });
        document.addEventListener("touchstart", handleMouseUp, { signal });

        return () => {
            controller.abort();
        };

    }, []) // eslint-disable-line react-hooks/exhaustive-deps

    return (
        <div className="overlay" />
    );
}
export default Overlay