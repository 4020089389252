import Utility from './Utility';
let API = null;
let ApiConnector = {

    call: async (method, resource, params, token) => {
        if (!method || !resource) {
            return Promise.reject('Method and resource are required');
        }

        token = token || '';

        if (!API) {
            let config = Utility.getConfig();
            let environment = config.environment;

            API = environment === "development" ? "http://localhost:4999/" : "https://snapcounter.app/wargalleyservice/";
        }

        let uri = API + resource;

        if (method.toLowerCase() === 'get' && params && Utility.isObjectAndNotEmpty(params)) {
            const queryString = Object.keys(params).map(key => `${encodeURIComponent(key)}=${encodeURIComponent(params[key])}`).join('&');
            uri += uri.includes('?') ? `&${queryString}` : `?${queryString}`;
        }

        uri = uri.replaceAll('//', '/').replace('https:/', 'https://');

        const fetchOptions = {
            method: method,
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
                ...(token && { 'Authorization': token })
            },
            ...(method.toLowerCase() === 'post' && { body: JSON.stringify(params) })
        };

        return fetch(uri, fetchOptions)
            .then(response => {
                if (!response.ok) {
                    return Promise.reject(`API did not accept request, status: ${response.status}, statusText: ${response.statusText}`);
                }
                return response.json();
            })
            .catch(error => {
                console.warn('Caught error: ', error);
                return Promise.reject(error);
            });
    }
};

export default ApiConnector;