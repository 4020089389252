import React, { useContext, useEffect, useRef, useState } from 'react'
import Snap from 'snapsvg-cjs'
import { useSignal } from "@preact/signals-react"
import { StoreContext } from "../../context/StoreContext"
import Utility from "../../objects/Utility"
import DrawLayer from "../DrawLayer/DrawLayer"
import GridDisplay from "../GridDisplay/GridDisplay"
import CounterAreaCoordinatesReadout from "../CounterAreaCoordinatesReadout/CounterAreaCoordinatesReadout"
import CounterFrontRearControl from "../CounterFrontRearControl/CounterFrontRearControl"
import CounterAreaMenu from "../CounterAreaMenu/CounterAreaMenu"
import OutOfBoundsMask from "../OutOfBoundsMask/OutOfBoundsMask"
import ThreeDeeViewButton from "../ThreeDeeViewButton/ThreeDeeViewButton"
import ThreeDeeViewDraggers from "../ThreeDeeViewDraggers/ThreeDeeViewDraggers"
import PinControl from "../PinControl/PinControl"
import HistoryControl from "../HistoryControl/HistoryControl"
import './CounterArea.scss'

const CounterArea = ({ dragUpdate, historyEvent }) => {
    const { state, actions } = useContext(StoreContext)
    const signalGridState = useSignal(false)
    const signalGridColor = useSignal('light')
    const signalOrigTop = useSignal(0)
    const signalMenuHeight = useSignal(0)
    const signalSvgReferences = useSignal([])
    const sgUtime = useSignal(null)
    const counterDrawingAreaRef = useRef(null)
    const [threeDeeActive, setThreeDeeActive] = useState(false)
    const [threeDeeValues, setThreeDeeValues] = useState(null)
    const [pinned, setPinned] = useState(false)
    const [flipping, setFlipping] = useState(null)
    const [counterDrawn, setCounterDrawn] = useState(null)
    const [drawnTimer, setDrawnTimer] = useState(null)
    const [sortedLayers, setSortedLayers] = useState([])
    const [loadingCounterAlv, setLoadingCounterAlv] = useState(null)
    const [triggerAlvUpdate, setTriggerAlvUpdate] = useState(null)

    useEffect(() => {
        computeInitialCoords()
    }, [])  // eslint-disable-line react-hooks/exhaustive-deps


    useEffect(() => {
        if (state.counterLoadFromApp) {
            let loadData = JSON.parse(JSON.stringify(state.counterLoadFromApp))
            actions.counterLoadFromApp(null)
            let alv = null
            loadData.counterSideActive.active = state.counterSideActive.active
            if (state.counterSideActive.active === 'front') {
                alv = JSON.parse(loadData.counterSideActive.front)
            }
            if (state.counterSideActive.active === 'rear') {
                alv = JSON.parse(loadData.counterSideActive.rear)
            }
            let layers = loadData.layers
            let _stateLayers = [...state.layers]
            _stateLayers.forEach(sl => {
                let loadedLayer = layers.find(ly => ly.layerKey === sl.layerKey)
                if (loadedLayer) {
                    if (loadedLayer) {
                        if (loadedLayer.layerOrder !== sl.layerOrder) {
                            sl.layerOrder = loadedLayer.layerOrder + 0.5
                        }
                    }
                }
            })
            // reorder and reindex
            _stateLayers.sort((a, b) => a.layerOrder - b.layerOrder)
            _stateLayers.forEach((ly, index) => {
                ly.layerOrder = index
            })
            setLoadingCounterAlv(alv)
            actions.counterSideActive(loadData.counterSideActive) // has no downstream effects
            actions.layers(_stateLayers)
            if (loadData.layersEffects && Array.isArray(loadData.layersEffects) && loadData.layersEffects.length > 0) {
                actions.layersEffects(loadData.layersEffects)
            }
            else {
                actions.layersEffects([])
            }
        }
    }, [state.counterLoadFromApp]) // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        if (state.counterLoadFromSheet) {
            let loadData = JSON.parse(JSON.stringify(state.counterLoadFromSheet))
            actions.counterLoadFromSheet(null)
            let alv = null
            loadData.counterSideActive.active = state.counterSideActive.active
            if (state.counterSideActive.active === 'front') {
                alv = JSON.parse(loadData.counterSideActive.front)
            }
            if (state.counterSideActive.active === 'rear') {
                alv = JSON.parse(loadData.counterSideActive.rear)
            }
            let layers = loadData.layers
            let _stateLayers = [...state.layers]
            _stateLayers.forEach(sl => {
                let loadedLayer = layers.find(ly => ly.layerKey === sl.layerKey)
                if (loadedLayer) {
                    if (loadedLayer) {
                        if (loadedLayer.layerOrder !== sl.layerOrder) {
                            sl.layerOrder = loadedLayer.layerOrder + 0.5
                        }
                    }
                }
            })
            // reorder and reindex
            _stateLayers.sort((a, b) => a.layerOrder - b.layerOrder)
            _stateLayers.forEach((ly, index) => {
                ly.layerOrder = index
            })
            setLoadingCounterAlv(alv)
            actions.counterSideActive(loadData.counterSideActive) // has no downstream effects
            actions.layers(_stateLayers)
            if (loadData.layersEffects && Array.isArray(loadData.layersEffects) && loadData.layersEffects.length > 0) {
                actions.layersEffects(loadData.layersEffects)
            }
            else {
                actions.layersEffects([])
            }
        }

    }, [state.counterLoadFromSheet]) // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        if (state.counterLoadFromFile) {
            let loadData = state.counterLoadFromFile
            if (loadData.version === 2) {
                loadCounterFromFileV2(loadData)
            }
            if (loadData.version === 3) {
                loadCounterFromFileV3(loadData)
            }
        }
    }, [state.counterLoadFromFile]) // eslint-disable-line react-hooks/exhaustive-deps

    const loadCounterFromFileV2 = data => {
        let newSvgs = []
        let stateLayers = JSON.parse(JSON.stringify(state.layers))

        // get the spacers out
        let newAlv = data.activeLayerValues
        for (const [key] of Object.entries(newAlv)) {
            if (key.endsWith('_5') || key.endsWith('_59') || key.endsWith('_72')) {
                delete newAlv[key]
            }
        }

        // get the state images and svgs lists
        let stateImagesLayer = state.layers.find(sl => sl.layerName === 'custom images')
        let stateSvgsLayer = state.layers.find(sl => sl.layerName === 'custom svgs')
        let input = stateImagesLayer.inputs.find(li => li.named === 'svgKey')
        let stateImagesLayerList = [...input.list]
        input = stateSvgsLayer.inputs.find(li => li.named === 'svgKey')
        let stateSvgsLayerList = [...input.list]

        if (!Array.isArray(stateImagesLayerList)) {
            console.error('failure on getting images layer list')
            return
        }
        if (!Array.isArray(stateSvgsLayerList)) {
            console.error('failure on getting svgs layer list')
            return
        }

        // look for incoming fonts. The font data isn't preserved in v2 counter files, so we just
        // need to tell the user "hey, these fonts from old snap counter? You gotta install them again.

        let incomingFontsUsed = []
        for (let i = 0; i < data.layers.length; i++) {
            let layer = data.layers[i]
            for (let n = 0; n < layer.inputs.length; n++) {
                if (layer.layerActive === 1) {
                    let input = layer.inputs[n]
                    if (input.type === 'font') {
                        if (data.activeLayerValues[layer.layerKey + '_' + input.inputKey]) {
                            let fontNameUsed = data.activeLayerValues[layer.layerKey + '_' + input.inputKey]
                            if (fontNameUsed) {
                                if (!incomingFontsUsed.includes(fontNameUsed)) {
                                    incomingFontsUsed.push(fontNameUsed)
                                }
                            }
                        }
                    }
                }
            }
        }
        let incomingFontsToInstall = []
        incomingFontsUsed.forEach(fontFamily => {
            let found = state.fonts.find(sf => sf.fontFamily === fontFamily)
            if (!found) {
                incomingFontsToInstall.push(fontFamily)
            }
        })
        if (incomingFontsToInstall.length > 0) {
            setTimeout(() => {
                actions.addErrorMessages([{
                    comment: "Missing fonts:",
                    lineItems: ['You need to install these fonts: ' + incomingFontsToInstall.join("', ")]
                }])
            }, 100)
        }

        //////////////////////////////////////////////////////////////////////
        // extract any custom images and svgs
        //////////////////////////////////////////////////////////////////////
        let extractedSvgs = []
        data.layers.forEach(ily => {
            if (ily.layerActive === 1) {
                let incomingLayerType = Utility.originalLayerNameByLayer(state.layers, ily)

                if (incomingLayerType === 'custom images') {
                    let svgName = extractNativeId(ily.svg)
                    let extractedPrepend = extractPrepend(ily.svg)
                    const prependText = 'pv2' + Utility.randomString(5) + '_'
                    ily.svg = ily.svg.replaceAll(extractedPrepend, prependText) // clean up the duplicated prepends coming from old file.
                    let extractedSvg = extractSvg(ily.layerKey, ily.svg, prependText)
                    if (extractedSvg) {
                        let requiredKey = ily.layerActiveRequiredInputKey
                        let svgKey = data.activeLayerValues[ily.layerKey + '_' + requiredKey]
                        extractedSvgs.push({ svg: extractedSvg, svgName, uniquePrepend: prependText, layerKey: ily.layerKey, type: 'image', svgKey: svgKey })
                    }

                }
                if (incomingLayerType === 'custom svgs') {
                    let svgName = extractNativeId(ily.svg)
                    let extractedPrepend = extractPrepend(ily.svg)
                    const prependText = 'pv2' + Utility.randomString(5) + '_'
                    ily.svg = ily.svg.replaceAll(extractedPrepend, prependText) // clean up the duplicated prepends coming from old file.
                    let extractedSvg = extractSvg(ily.layerKey, ily.svg, prependText)
                    if (extractedSvg) {
                        let requiredKey = ily.layerActiveRequiredInputKey
                        let svgKey = data.activeLayerValues[ily.layerKey + '_' + requiredKey]
                        extractedSvgs.push({ svg: extractedSvg, svgName, uniquePrepend: prependText, layerKey: ily.layerKey, type: 'svg', svgKey: svgKey })
                    }
                }
            }
        })

        let changedSvgKeys = []
        if (extractedSvgs.length > 0) {
            // just try to match by name
            extractedSvgs.forEach(esvg => {
                let found = state.svgs.find(ssvg => esvg.svgName === ssvg.svgName)
                if (found) {
                    esvg.removeThis = true
                    if (esvg.svgKey !== found.svgKey) {
                        changedSvgKeys.push({ from: esvg.svgKey, to: found.svgKey })
                    }
                }
                else {
                    esvg.removeThis = false
                }
            })
            extractedSvgs = extractedSvgs.filter(esvg => esvg.removeThis === false)
            let nextSvgKey = state.svgs.reduce((a, b) => Number(a.svgKey) > Number(b.svgKey) ? a : b).svgKey
            if (nextSvgKey < 3000) {
                nextSvgKey = 3000
            }
            else {
                nextSvgKey++
            }
            extractedSvgs.forEach(installSvg => {
                delete installSvg.removeThis
                changedSvgKeys.push({ from: installSvg.svgKey, to: nextSvgKey })
                installSvg.svgKey = nextSvgKey
                nextSvgKey++
            })

            // add new svgKeys to the lists
            extractedSvgs.forEach(esvg => {
                if (esvg.type === 'image') {
                    if (stateImagesLayerList.includes(esvg.svgKey) === false) {
                        stateImagesLayerList.push(esvg.svgKey)
                    }
                }
                if (esvg.type === 'svg') {
                    if (stateSvgsLayerList.includes(esvg.svgKey) === false) {
                        stateSvgsLayerList.push(esvg.svgKey)
                    }
                }
            })
            // set the custom images and custom svgs layers in state with the new lists
            stateLayers.forEach(sLayer => {
                if (Utility.originalLayerNameByLayer(state.layers, sLayer) === 'custom images') {
                    let input = sLayer.inputs.find(li => li.named === 'svgKey')
                    if (input) {
                        if (!Utility.equalSets(new Set(input.list), new Set(stateImagesLayerList))) {
                            input.list = stateImagesLayerList
                            sLayer.layerHidden = 0
                        }
                    }
                }
                if (Utility.originalLayerNameByLayer(state.layers, sLayer) === 'custom svgs') {
                    let input = sLayer.inputs.find(li => li.named === 'svgKey')
                    if (input) {
                        if (!Utility.equalSets(new Set(input.list), new Set(stateSvgsLayerList))) {
                            input.list = stateSvgsLayerList
                            sLayer.layerHidden = 0
                        }
                    }
                }
            })

            // some modification needed from custom svgs in the old version to the new v3 version.
            newSvgs = extractedSvgs.map(esvg => {
                esvg.svg = esvg.svg.replace(/\bwidth="(\d+(\.\d*)?|\.\d+)"/, 'width="100%"');
                esvg.svg = esvg.svg.replace(/\bheight="(\d+(\.\d*)?|\.\d+)"/, 'height="100%"');

                return { svgCode: esvg.svg, svgKey: esvg.svgKey, svgName: esvg.svgName, uniquePrepend: esvg.uniquePrepend }
            })

        }

        let stateLayersAvailable = stateLayers.map(sl => {
            return {
                layerType: Utility.originalLayerNameLayerKey(stateLayers, sl.layerKey),
                layerKey: sl.layerKey,
                parentLayerKey: sl.parentLayerKey,
                assigned: false
            }
        })
        let incomingLayers = data.layers.filter(ly => ly.layerActive === 1)

        // setup our special matching structure
        let incomingLayersToAssign = incomingLayers.map(sl => {
            let useKey = sl.parentLayerKey === -1 ? sl.layerKey : sl.parentLayerKey
            return {
                layerType: Utility.originalLayerNameLayerKey(stateLayers, useKey),
                layerKey: sl.layerKey,
                parentLayerKey: sl.parentLayerKey,
                layerOrder: sl.layerOrder, // extra info we need to help setup state sorta the way the imported file's state was
                layerName: sl.layerName, // extra info
                assigned: false
            }
        })

        let changedLayerKeys = []
        incomingLayersToAssign.forEach(ily => {
            let found = false
            if (ily.parentLayerKey === -1) { // try to match to a parent
                found = stateLayersAvailable.find(sla => sla.layerType === ily.layerType && sla.parentLayerKey === -1 && sla.assigned === false)
            }
            else {
                found = stateLayersAvailable.find(sla => sla.layerType === ily.layerType && sla.parentLayerKey > -1 && sla.assigned === false)
            }
            if (found) {
                // setting assigned to true for both
                ily.assigned = true
                found.assigned = true
                if (ily.layerKey !== found.layerKey) {
                    changedLayerKeys.push({ from: ily.layerKey, to: found.layerKey, type: found.layerType, created: false })
                }
            }
        })

        let layersToCreate = incomingLayersToAssign.filter(ily => ily.assigned === false)
        let { newLayers, finalChangedLayerKeys } = createDuplicateLayers(layersToCreate, changedLayerKeys)

        newAlv = processAlvLayerKeyReplacements(finalChangedLayerKeys, newAlv)

        // combine any new layers into the copy of state layers we have been manipulating.
        stateLayers = [...stateLayers, ...newLayers]

        stateLayers.forEach(ly => {
            if (ly.layerActiveRequiredInputKey) {
                let svgInputKey = ly.layerActiveRequiredInputKey
                if (newAlv[ly.layerKey + '_' + svgInputKey]) {
                    let currentSvgKey = newAlv[ly.layerKey + '_' + svgInputKey]
                    let found = changedSvgKeys.find(csk => csk.from === currentSvgKey)
                    if (found) {
                        newAlv[ly.layerKey + '_' + svgInputKey] = found.to
                    }
                }
            }
        })

        // commit the updates to the system
        actions.svgs([...state.svgs, ...newSvgs])
        actions.layers(stateLayers)

        //actions.counterSideActive
        let alvString = JSON.stringify(newAlv)
        let counterSideActive = { active: "front", front: alvString, rear: alvString }
        setTriggerAlvUpdate(newAlv) // activeLayerValues need to be updated after the layers update is settled in state.
        actions.counterSideActive(counterSideActive)
        actions.layersEffects([]) // there are no layersEffects from v2 files.
    }

    useEffect(() => {
        if (triggerAlvUpdate) {
            actions.activeLayerValuesReset(triggerAlvUpdate)
            setTriggerAlvUpdate(false)
        }
    }, [state.counterSideActive]) // eslint-disable-line react-hooks/exhaustive-deps

    const processAlvLayerKeyReplacements = (fromTos, alv) => {
        let newAlv = JSON.parse(JSON.stringify(alv))
        fromTos.forEach(fromTo => {
            let from = fromTo.from
            let to = fromTo.to
            for (const [key, value] of Object.entries(alv)) {
                if (key.startsWith(from + '_')) {
                    let inputKey = key.split('_')[1]
                    newAlv[to + 'new_' + inputKey] = value
                    delete newAlv[key]
                }
            }
        })
        for (const [key, value] of Object.entries(newAlv)) {
            if (key.includes('new')) {
                let fixedKey = key.replace('new', '')
                newAlv[fixedKey] = value
                delete newAlv[key]
            }
        }

        return newAlv
    }

    const createDuplicateLayers = (dupeTheseLayers, changedLayerKeys) => {
        let _changedLayerKeys = JSON.parse(JSON.stringify(changedLayerKeys))
        let newLayers = []
        let stateLayersWithType = JSON.parse(JSON.stringify(state.layers))
        stateLayersWithType.forEach(sl => {
            let useKey = sl.parentLayerKey === -1 ? sl.layerKey : sl.parentLayerKey
            sl.layerType = Utility.originalLayerNameLayerKey(state.layers, useKey)
        })
        let nextLayerKey = Math.max(...state.layers.map(ly => parseInt(ly.layerKey)), 0)
        nextLayerKey++
        // { layerType: 'common symbols', layerKey: 25, parentLayerKey: 5, layerOrder: 10, assigned: false }
        dupeTheseLayers.forEach(dLayer => {
            let parentTemplate = stateLayersWithType.find(sl => sl.layerType === dLayer.layerType)
            if (parentTemplate) {
                let newDupeLayer = JSON.parse(JSON.stringify(parentTemplate))
                delete newDupeLayer.layerType
                newDupeLayer.parentLayerKey = newDupeLayer.layerKey
                newDupeLayer.layerKey = nextLayerKey
                nextLayerKey++
                newDupeLayer.layerOrder = dLayer.layerOrder + 0.5

                // check name to avoid dupe names
                let incNum = 2
                let dupeName = dLayer.layerName
                const lookForDupeName = dupeName => {
                   return state.layers.find(sl => sl.layerName === dupeName)
                }
                while (lookForDupeName(dupeName)) {
                    dupeName = dLayer.layerName + incNum
                    incNum++
                    if (incNum > 100) {
                        break
                    }
                }
                newDupeLayer.layerName = dupeName
                if (dLayer.layerKey !== newDupeLayer.layerKey) {
                    _changedLayerKeys.push({ from: dLayer.layerKey, to: newDupeLayer.layerKey, type: dLayer.layerType, created: true })
                }
                newLayers.push(newDupeLayer)
            }
        })

        return { newLayers, finalChangedLayerKeys: _changedLayerKeys }
    }

    const extractSvg = (layerId, svg, prepend) => {
        let layerGroupStart = svg.indexOf('_layerGroup_' + layerId)
        let embeddedSvgStart = svg.indexOf('<svg ', layerGroupStart)
        let workSvg = svg.substring(embeddedSvgStart)
        let lastEndSvg = workSvg.lastIndexOf('</svg>')
        workSvg = workSvg.substring(0, lastEndSvg)
        let secondLastEndSvg = workSvg.lastIndexOf('</svg>')

        workSvg = workSvg.substring(0, secondLastEndSvg + 6)
        workSvg = workSvg.replace(prepend, '')

        return workSvg
    }

    const extractTopSvgTag = str => {
        let startSvgTag = str.indexOf('<svg')
        startSvgTag = str.indexOf('<svg', startSvgTag + 1)
        if (startSvgTag > -1) {
            let endSvgTag = str.indexOf('>', startSvgTag + 1)
            if ((startSvgTag > -1 && endSvgTag > -1) && (endSvgTag > startSvgTag)) {
                return str.substring(startSvgTag, endSvgTag)
            }
        }
        return null
    }

    const extractNativeId = str => {
        let idResult = ''
        let svgTag = extractTopSvgTag(str)
        if (svgTag) {
            let splitted = Utility.strSplitOnNonEnclosedSpaces(svgTag)
            for (let i = 0; i < splitted.length; i++) {
                let testStr = splitted[i]
                if (testStr) {
                    if (testStr.startsWith('id=') || testStr.startsWith('ID=')) {
                        let splittedId = testStr.split('=')
                        idResult = splittedId[1].replaceAll('"', '')
                        let idResultSplit = idResult.split('_')
                        if (idResultSplit.length > 0) {
                            idResult = idResultSplit[idResultSplit.length - 1]
                        }
                        break
                    }
                }
            }
        }

        return idResult
    }

    const extractPrepend = str => {
        let idResult = ''
        let svgTag = extractTopSvgTag(str)
        if (svgTag) {
            let splitted = Utility.strSplitOnNonEnclosedSpaces(svgTag)
            for (let i = 0; i < splitted.length; i++) {
                let testStr = splitted[i]
                if (testStr) {
                    if (testStr.startsWith('id=') || testStr.startsWith('ID=')) {
                        let splittedId = testStr.split('=')
                        idResult = splittedId[1].replaceAll('"', '')
                        idResult = idResult.replace('_custom_image', '')
                        let idResultSplit = idResult.split('_')
                        if (idResultSplit.length > 1) {
                            idResultSplit.pop()
                            return idResultSplit.join('_')
                        }
                        return null
                    }
                }
            }
        }

        return idResult
    }

    const loadCounterFromFileV3 = data => {
        let stateLayers = JSON.parse(JSON.stringify(state.layers))
        // get the state images and svgs lists
        let stateImagesLayer = state.layers.find(sl => sl.layerName === 'custom images')
        let stateSvgsLayer = state.layers.find(sl => sl.layerName === 'custom svgs')
        let input = stateImagesLayer.inputs.find(li => li.named === 'svgKey')
        let stateImagesLayerList = [...input.list]
        input = stateSvgsLayer.inputs.find(li => li.named === 'svgKey')
        let stateSvgsLayerList = [...input.list]

        let unknownFonts = []
        data.fontsData.forEach(df => {
            let found = state.fonts.find(sf => sf.fontFamily === df.fontFamily)
            if (!found) {
                unknownFonts.push(df)
            }
        })
        if (unknownFonts.length > 0) {
            actions.fonts([...state.fonts, ...unknownFonts])
        }

        // see if we got new custom images/svgs coming in.
        let svgsToInstall = []
        let changeSvgKeys = []
        data.customImageSvgsData.forEach(cis => {
            let found = state.svgs.find(ss => ss.svgName === cis.svgName)
            if (found) {
                if (found.svgKey !== cis.svgKey) {
                    changeSvgKeys.push({ from: cis.svgKey, to: found.svgKey, type: 'image' })
                }
            }
            else {
                cis.type = 'image'
                svgsToInstall.push(cis)
            }
        })
        data.customSvgsData.forEach(css => {
            let found = state.svgs.find(ss => ss.svgName === css.svgName)
            if (found) {
                if (found.svgKey !== css.svgKey) {
                    changeSvgKeys.push({ from: css.svgKey, to: found.svgKey, type: 'svg' })
                }
            }
            else {
                css.type = 'svg'
                svgsToInstall.push(css)
            }
        })

        // lets get the unknown svgs installed and generate their svgKeys
        let nextSvgKey = state.svgs.reduce((a, b) => Number(a.svgKey) > Number(b.svgKey) ? a : b).svgKey
        if (nextSvgKey < 3000) {
            nextSvgKey = 3000
        }
        else {
            nextSvgKey++
        }
        svgsToInstall.forEach(installSvg => {
            if (installSvg.type === 'image') {
                stateImagesLayerList.push(nextSvgKey)
            }
            if (installSvg.type === 'svg') {
                stateSvgsLayerList.push(nextSvgKey)
            }
            changeSvgKeys.push({ from: installSvg.svgKey, to: nextSvgKey, type: installSvg.type })
            installSvg.svgKey = nextSvgKey
            nextSvgKey++
        })
        // update the custom image and svgs layers in stateLayers with the new lists
        stateLayers.forEach(sLayer => {
            if (stateImagesLayerList.length > 0) {
                if (Utility.originalLayerNameByLayer(state.layers, sLayer) === 'custom images') {
                    let input = sLayer.inputs.find(li => li.named === 'svgKey')
                    if (input) {
                        input.list = stateImagesLayerList
                        sLayer.layerHidden = 0
                    }
                }
            }
            if (stateSvgsLayerList.length > 0) {
                if (Utility.originalLayerNameByLayer(state.layers, sLayer) === 'custom svgs') {
                    let input = sLayer.inputs.find(li => li.named === 'svgKey')
                    if (input) {
                        input.list = stateSvgsLayerList
                        sLayer.layerHidden = 0
                    }
                }
            }
        })

        let stateLayersAvailable = stateLayers.map(sl => {
            return {
                layerType: Utility.originalLayerNameLayerKey(stateLayers, sl.layerKey),
                layerKey: sl.layerKey,
                parentLayerKey: sl.parentLayerKey,
                assigned: false
            }
        })

        let incomingLayers = data.layers.filter(ly => ly.layerActive === 1)

        // setup our special matching structure
        let incomingLayersToAssign = incomingLayers.map(sl => {
            let useKey = sl.parentLayerKey === -1 ? sl.layerKey : sl.parentLayerKey
            return {
                layerType: Utility.originalLayerNameLayerKey(stateLayers, useKey),
                layerKey: sl.layerKey,
                parentLayerKey: sl.parentLayerKey,
                layerOrder: sl.layerOrder, // extra info we need to help setup state sorta the way the imported file's state was
                layerName: sl.layerName, // extra info
                assigned: false
            }
        })

        let changedLayerKeys = []
        incomingLayersToAssign.forEach(ily => {
            let found = false
            if (ily.parentLayerKey === -1) { // try to match to a parent
                found = stateLayersAvailable.find(sla => sla.layerType === ily.layerType && sla.parentLayerKey === -1 && sla.assigned === false)
            }
            else {
                found = stateLayersAvailable.find(sla => sla.layerType === ily.layerType && sla.parentLayerKey > -1 && sla.assigned === false)
            }
            if (found) {
                // setting assigned to true for both
                ily.assigned = true
                found.assigned = true
                if (ily.layerKey !== found.layerKey) {
                    changedLayerKeys.push({ from: ily.layerKey, to: found.layerKey, type: found.layerType, created: false })
                }
            }
        })

        let layersToCreate = incomingLayersToAssign.filter(ily => ily.assigned === false)

        let { newLayers, finalChangedLayerKeys } = createDuplicateLayers(layersToCreate, changedLayerKeys)

        let newFrontAlv = JSON.parse(data.counterSideActive.front)
        let newRearAlv = JSON.parse(data.counterSideActive.rear)
        newFrontAlv = processAlvLayerKeyReplacements(finalChangedLayerKeys, newFrontAlv)
        newRearAlv = processAlvLayerKeyReplacements(finalChangedLayerKeys, newRearAlv)

        // alv have been updated with any changed/new layer keys. Now we need to update
        // the svgKeys used in the front and rear alvs.
        stateLayers = [...stateLayers, ...newLayers]

        stateLayers.forEach(ly => {
            if (ly.layerActiveRequiredInputKey) {
                let svgInputKey = ly.layerActiveRequiredInputKey
                if (newFrontAlv[ly.layerKey + '_' + svgInputKey]) {
                    let currentSvgKey = newFrontAlv[ly.layerKey + '_' + svgInputKey]
                    let found = changeSvgKeys.find(csk => csk.from === currentSvgKey)
                    if (found) {
                        newFrontAlv[ly.layerKey + '_' + svgInputKey] = found.to
                    }
                }
                if (newRearAlv[ly.layerKey + '_' + svgInputKey]) {
                    let currentSvgKey = newRearAlv[ly.layerKey + '_' + svgInputKey]
                    let found = changeSvgKeys.find(csk => csk.from === currentSvgKey)
                    if (found) {
                        newRearAlv[ly.layerKey + '_' + svgInputKey] = found.to
                    }
                }
            }
        })
        svgsToInstall.forEach(sti => delete sti.type)
        actions.svgs([...state.svgs, ...svgsToInstall])
        actions.layers(stateLayers)

        // //actions.counterSideActive
        // let alvString = JSON.stringify(newAlv)
        let counterSideActive = { active: "front", front: JSON.stringify(newFrontAlv), rear: JSON.stringify(newRearAlv) }
        setTriggerAlvUpdate(newFrontAlv)
        actions.counterSideActive(counterSideActive)

        // gotta handle layersEffects
        let layersEffects = JSON.parse(JSON.stringify(data.layersEffects))
        layersEffects.forEach(le => {
            let found = finalChangedLayerKeys.find(clk => clk.layerKey === le.from)
            if (found) {
                found.layerKey = le.to
            }
        })
        if (layersEffects && layersEffects.length > 0) {
            actions.layersEffects(layersEffects)
        }
        else {
            actions.layersEffects([])
        }
    }



    useEffect(() => {
        computeInitialCoords()
    }, [state.windowWidthHeight]) // eslint-disable-line react-hooks/exhaustive-deps

    const computeInitialCoords = () => {
        let bbox = null
        const counterEl = document.getElementById('counter')
        if (counterEl) {
            bbox = counterEl.getBoundingClientRect()
            signalOrigTop.value = bbox.top
        }

        const counterMenuEl = document.getElementsByClassName('counter-area-menu')[0]
        if (counterMenuEl) {
            bbox = counterMenuEl.getBoundingClientRect()
            signalMenuHeight.value = bbox.height
        }
    }

    const showGrid = onOff => {
        signalGridState.value = onOff
    }

    const gridColor = lightDark => {
        signalGridColor.value = lightDark
    }

    const clearCounter = () => {
        let clearedActiveLayerValues = { ...state.activeLayerValues }
        for (const [key] of Object.entries(state.activeLayerValues)) {
            if (key.startsWith('1_') === false) {
                delete clearedActiveLayerValues[key]
            }
        }
        actions.activeLayerValuesReset(clearedActiveLayerValues)
        actions.counterClear(true)
    }

    useEffect(() => {
        if (pinned) {
            return
        }
        const counterEl = document.getElementById('counter')
        let counterElBox = counterEl.getBoundingClientRect()
        let trLeft = document.getElementsByClassName('tr-left')[0]
        let trLeftBox = trLeft.getBoundingClientRect()

        let topSetting = window.scrollY  //state.scrollTop
        if (window.scrollY + counterElBox.height > trLeftBox.height) {
            let byHowFar = trLeftBox.height - (window.scrollY + counterElBox.height) - 43
            topSetting += byHowFar
        }
        if (topSetting < 0) {
            topSetting = 0
        }

        counterEl.style.top = topSetting + 'px';

    }, [state.scrollTop, state.windowResize, pinned])

    const mouseOutHandler = e => {
        actions.mouseOutCounterArea(true)
    }

    const mouseInHandler = e => {
        actions.mouseOutCounterArea(false)
    }

    useEffect(() => {
        if (state.moveCounterToSheet) {
            if (counterDrawingAreaRef.current) {
                counterDrawingAreaRef.current.className = state.layoutInline ? "drawing-area move-to-sheet-right" : "drawing-area move-to-sheet-down"
                setTimeout(() => {
                    counterDrawingAreaRef.current.className = "drawing-area";
                }, 1000)
            }
        }
    }, [state.moveCounterToSheet]) // eslint-disable-line react-hooks/exhaustive-deps

    const onChangeThreeDeeActive = active => {
        setThreeDeeActive(active)
    }

    const onChangeThreeDeeValues = values => {
        setThreeDeeValues(values)
    }

    useEffect(() => {
        if (threeDeeActive) {
            distribute3dTransforms(threeDeeValues)
        }
        else {
            distribute3dTransforms(false)
        }
    }, [threeDeeActive, threeDeeValues]) // eslint-disable-line react-hooks/exhaustive-deps

    const distribute3dTransforms = threeDeeValues => {
        let layers = Utility.activeLayers(state.activeLayerValues, state.layers)
        let drawnLayerElements = layers.map(ly => document.getElementById('drawLayer_' + ly.layerKey))
        drawnLayerElements = drawnLayerElements.filter(dleid => dleid)
        if (drawnLayerElements.length === 0) {
            return
        }

        if (threeDeeValues) {

            let middle = drawnLayerElements.length / 2

            let { explodeDistance, viewAngle, viewRotation } = threeDeeValues
            let perspective = 20000
            let scaleX = 0.9
            let scaleY = (viewAngle * 0.0125) // view angle
            let rotation = viewRotation + 'deg'
            let ln = Math.log(100 - viewAngle)
            perspective -= ln * 3400
            if (!perspective || perspective >= 20000) {
                perspective = 130000
            }
            drawnLayerElements.forEach((ele, index) => {
                let num = index + 1

                let position = (num - 1) - middle
                position += 0.5
                let breakup100by = 100 / drawnLayerElements.length
                let reversedViewAngle = (101 - viewAngle) / 100
                let distanceToElevate = (position * breakup100by) / (100 / explodeDistance)
                if (explodeDistance === 0) {
                    distanceToElevate = 0
                }
                let heightAdjust = -distanceToElevate
                heightAdjust *= reversedViewAngle
                if (reversedViewAngle === 0.01) {
                    heightAdjust = 0
                }
                ele.style.transform = `
                        translateY(${heightAdjust}%)
                        scaleY(${scaleY})
                        rotateY(${rotation})
                        scaleX(${scaleX})
                        perspective(${perspective}px) 
                        rotateX(45deg)
                        `;
            })

        }
        else {
            drawnLayerElements.forEach(ele => {
                ele.style.transform = 'none'
            })
        }
    }

    const newShade = (hexColor, magnitude) => {
        hexColor = hexColor.replace(`#`, ``);
        if (hexColor.length === 6) {
            const decimalColor = parseInt(hexColor, 16);
            let r = (decimalColor >> 16) + magnitude;
            r > 255 && (r = 255);
            r < 0 && (r = 0);
            let g = (decimalColor & 0x0000ff) + magnitude;
            g > 255 && (g = 255);
            g < 0 && (g = 0);
            let b = ((decimalColor >> 8) & 0x00ff) + magnitude;
            b > 255 && (b = 255);
            b < 0 && (b = 0);
            return `#${(g | (b << 8) | (r << 16)).toString(16)}`;
        } else {
            return hexColor;
        }
    };

    // all this does is return the current front and rear values, except if the rear is blank, we take the
    // current background color and make a lighter version of the color, as an indicator to the user that
    // something happened (like the counter was flipped to a weaker side).
    const manageCounterFrontRearValues = side => {
        let frontValues = state.counterSideActive.front
        let rearValues = state.counterSideActive.rear

        // if we are changing from front to rear view
        // if (side === 'rear') {
        // if rear values are blank, create a blank version of the front (i.e. same background)
        if (Utility.emptyCheck(JSON.parse(rearValues))) {
            // create rear side of counter with just the background color.
            let justBackground = {}
            for (const [key, value] of Object.entries(state.activeLayerValues)) {
                if (key.startsWith('1_')) {
                    justBackground[key] = value // copy in current values
                }
            }
            let colorValue = JSON.parse(justBackground['1_181'])
            if (colorValue.fillType === 'solid') {
                let fillColor = colorValue.fillColor
                let newColor = newShade(fillColor, 80) // lighter
                colorValue.fillColor = newColor
                justBackground['1_181'] = JSON.stringify(colorValue)
                justBackground['1_35'] = 0 // square
            }

            // put this into rear values
            rearValues = JSON.stringify(justBackground) // will overwrite whatever may had been there.
            // and since we are making the rear view the visible side
        }

        return { front: frontValues, rear: rearValues }
    }

    useEffect(() => {
        if (flipping) {
            let newAlv = state.counterSideActive.active === 'front' ? state.counterSideActive.front : state.counterSideActive.rear
            actions.activeLayerValuesReset(JSON.parse(newAlv))
            setFlipping(false)
        }
    }, [state.counterSideActive]) // eslint-disable-line react-hooks/exhaustive-deps

    // side here means side we are going to.
    const flipCounter = side => {

        createCloneCounter(side) // paints a svg copy over the counter, so we dont see the activeLayerValues changing.
        let { front, rear } = manageCounterFrontRearValues(side)

        setFlipping(true)
        // // //when switching to the other side, we want to copy in the current activeLayerValues into the side being switched away from.
        actions.counterSideActive({ active: side, front, rear })
        setTimeout(() => {
            setTimeout(() => {
                doFlipAnimation(side)
            }, 100)

            setTimeout(() => {
                // remove the svg combined dupe
                let ele = document.getElementById('svgCopy')
                ele.remove()
            }, 400)
        }, 300)
    }

    const copyFrom = fromSide => {
        let toSide = fromSide === 'front' ? 'rear' : 'front'
        let fromSideAlvString = state.counterSideActive[fromSide]
        let fromSideAlvObj = JSON.parse(fromSideAlvString)
        if (toSide === 'front') {
            //actions.counterSideActive({ ...state.counterSideActive, front: fromSideAlvString })
        }
        else {
            //actions.counterSideActive({ ...state.counterSideActive, rear: fromSideAlvString })
        }
        actions.activeLayerValuesReset(fromSideAlvObj)
    }

    const createCloneCounter = () => {
        let counterSvg = combineSvgsOnCounter()
        createCloneSVG(counterSvg)
    }

    const combineSvgsOnCounter = () => {
        let builtUpSvg = '<svg id="combined" xmlns="http://www.w3.org/2000/svg" width="100%" height="100%">'
        // get the active layers 
        let layersOrder = state.layers.map(ly => ly.layerActive ? { layerKey: ly.layerKey, layerOrder: ly.layerOrder } : null)
        layersOrder = layersOrder.filter(lo => lo)
        layersOrder = layersOrder.sort((a, b) => a.layerOrder - b.layerOrder)
        layersOrder.forEach(alk => {
            let id = 'drawLayer_' + alk.layerKey
            builtUpSvg += document.getElementById(id).innerHTML + "\n\n"
        })
        builtUpSvg += '</svg>'

        return builtUpSvg
    }

    const createCloneSVG = (svg) => {
        var xmlns = "http://www.w3.org/2000/svg";
        var svgElem = document.createElementNS(xmlns, "svg");
        svgElem.setAttributeNS(null, "viewBox", "0, 0, 240, 240");
        svgElem.setAttributeNS(null, "width", "100%");
        svgElem.setAttributeNS(null, "height", "100%");
        svgElem.setAttributeNS(null, "id", "svgCopy")
        svgElem.setAttributeNS(null, "class", "draw-layer svg-copy")
        var svgContainer = document.getElementById("drawingArea");
        svgContainer.append(svgElem)
        let _paper = Snap('#svgCopy');
        let parsed = Snap.parse(svg)
        _paper.append(parsed)
        svgElem.style.position = "absolute";
        svgElem.style.top = "0px";
        svgElem.style.left = "0px";
        svgElem.style.opacity = 1;
        svgElem.style.zIndex = 1000;
    }

    const doFlipAnimation = (direction) => {
        var list = document.getElementsByClassName("draw-layer");
        for (var i = 0; i < list.length; i++) {
            list[i].classList.add(`flip-${direction}`);
        }
        setTimeout(() => {
            for (var i = 0; i < list.length; i++) {
                list[i].classList.remove(`flip-${direction}`);
            }
        }, 600)
    }

    const svgReference = svgRef => {
        let layerKey = svgRef.layerKey
        //signalSvgReferences.value.filter(sr => sr.layerKey !== layerKey)
        let found = signalSvgReferences.value.find(sr => sr.layerKey === layerKey)
        if (!found) {
            signalSvgReferences.value.push(svgRef)
        }

        let utime = new Date().getTime()
        //throttleUpdate(setCounterDrawn, utime, 10000)
        sgUtime.v = utime
        let tid = setTimeout(() => {
            setCounterDrawn(sgUtime.v)
            setDrawnTimer(null)
        }, 100)
        if (drawnTimer) {
            clearTimeout(drawnTimer)
        }
        setDrawnTimer(tid)
    }

    useEffect(() => {
        let utime = new Date().getTime()
        sgUtime.v = utime
        let tid = setTimeout(() => {
            setCounterDrawn(sgUtime.v)
            setDrawnTimer(null)
        }, 100)
        if (drawnTimer) {
            clearTimeout(drawnTimer)
        }
        setDrawnTimer(tid)

        let _sortedLayers = state.layers.sort((a, b) => a.layerOrder - b.layerOrder)
        setSortedLayers(_sortedLayers)

        if (loadingCounterAlv) {
            actions.activeLayerValuesReset(loadingCounterAlv)
            setLoadingCounterAlv(null)
        }

    }, [state.layers]) // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        actions.counterRedrawn(counterDrawn)
    }, [counterDrawn]) // eslint-disable-line react-hooks/exhaustive-deps

    const changePinned = pinned => {
        setPinned(pinned)
    }

    return (
        <div className="counter-area" id="counter">
            <CounterAreaMenu showGrid={showGrid}
                gridColor={gridColor}
                clearCounter={clearCounter}
                gridState={signalGridState.value} />
            <div ref={counterDrawingAreaRef} id="drawingArea" className={threeDeeActive ? 'drawing-area three-dee' : 'drawing-area'} onMouseOver={mouseInHandler} onMouseOut={mouseOutHandler}>
                {sortedLayers.map((layer, index) => {
                    if (layer.layerActive === 1) {
                        return <DrawLayer key={index} layer={layer} dragUpdate={dragUpdate} svgReference={svgReference} />
                    }
                    return ''
                })}

                <OutOfBoundsMask />
                <PinControl changePinned={changePinned} hide={threeDeeActive} />
                <GridDisplay showingGrid={signalGridState.value} usingGridColor={signalGridColor.value} threeDeeActive={threeDeeActive} />
                <CounterFrontRearControl flipCounter={flipCounter} copyFrom={copyFrom} hide={threeDeeActive} />
                <CounterAreaCoordinatesReadout hide={threeDeeActive} />
                <ThreeDeeViewButton reportThreeDeeActive={onChangeThreeDeeActive} />
                <ThreeDeeViewDraggers threeDeeActive={threeDeeActive} reportThreeDeeValues={onChangeThreeDeeValues} />
                <HistoryControl historyEvent={historyEvent} hide={threeDeeActive} />

            </div>

        </div>
    )
}

export default CounterArea