import { types } from "./reducers";
export const useActions = (state, dispatch) => {

    function customImageSvgKeys(evt) {
        dispatch({ type: types.CUSTOM_IMAGE_SVG_KEYS, payload: evt })
    }

    function customSvgSvgKeys(evt) {
        dispatch({ type: types.CUSTOM_SVG_SVG_KEYS, payload: evt })
    }

    function lastSheetDisplayWidth(evt) {
        dispatch({ type: types.LAST_SHEET_DISPLAY_WIDTH, payload: evt })
    }

    function storageUsage(evt) {
        dispatch({ type: types.STORAGE_USAGE, payload: evt })
    }
    
    function counterRedrawn(evt) {
        dispatch({ type: types.COUNTER_REDRAWN, payload: evt })
    }

    function counterSideActive(evt) {
        dispatch({ type: types.COUNTER_SIDE_ACTIVE, payload: evt })
    }

    function counterSideActiveHistoryFront(evt) {
        dispatch({ type: types.COUNTER_SIDE_ACTIVE_HISTORY_FRONT, payload: evt })
    }

    function counterSideActiveHistoryRear(evt) {
        dispatch({ type: types.COUNTER_SIDE_ACTIVE_HISTORY_REAR, payload: evt })
    }

    function sheetSideActive(evt) {
        dispatch({ type: types.SHEET_SIDE_ACTIVE, payload: evt })
    }

    function threeDeeViewActive(evt) {
        dispatch({ type: types.THREE_DEE_VIEW_ACTIVE, payload: evt })
    }

    function counterLoadFromSheet(evt) {
        dispatch({ type: types.COUNTER_LOAD_FROM_SHEET, payload: evt })
    }

    function counterLoadFromApp(evt) {
        dispatch({ type: types.COUNTER_LOAD_FROM_APP, payload: evt })
    }

    function counterLoadFromFile(evt) {
        dispatch({ type: types.COUNTER_LOAD_FROM_FILE, payload: evt })
    }

    function loadSheet(evt) {
        dispatch({ type: types.LOAD_SHEET, payload: evt })
    }

    function windowResize(evt) {
        dispatch({ type: types.WINDOW_RESIZE, payload: evt })
    }

    function counterAreaMenuState(evt) {
        dispatch({ type: types.COUNTER_AREA_MENU_STATE, payload: evt })
    }

    function layers(evt) {
        dispatch({ type: types.LAYERS, payload: evt })
    }

    function duplicatedLayerValuesSetup(message) {
        dispatch({ type: types.DUPLICATED_LAYER_VALUES_SETUP, payload: message })
    }

    function topMenuView(message) {
        dispatch({ type: types.TOP_MENU_VIEW, payload: message })
    }

    function scrollTop(message) {
        dispatch({ type: types.SCROLL_TOP, payload: message })
    }

    function scrollLeft(message) {
        dispatch({ type: types.SCROLL_LEFT, payload: message })
    }

    function layersOpen(message) {
        dispatch({ type: types.LAYERS_OPEN, payload: message })
    }

    function layersEffects(message) {
        dispatch({ type: types.LAYERS_EFFECTS, payload: message })
    }

    function layoutInline(message) {
        dispatch({ type: types.LAYOUT_INLINE, payload: message })
    }

    function restoreCounterState(message) {
        dispatch({ type: types.RESTORE_COUNTER_STATE, payload: message })
    }

    function browser(message) {
        dispatch({ type: types.BROWSER, payload: message })
    }
    function os(message) {
        dispatch({ type: types.OS, payload: message })
    }

    function help(message) {
        dispatch({ type: types.HELP, payload: message })
    }

    function popupInfoActive(message) {
        dispatch({ type: types.POPUP_INFO_ACTIVE, payload: message })
    }

    function restoreDatabase(message) {
        dispatch({ type: types.RESTORE_DATABASE, payload: message })
    }

    function importDatabase(message) {
        dispatch({ type: types.IMPORT_DATABASE, payload: message })
    }

    function processImportDatabase(message) {
        dispatch({ type: types.PROCESS_IMPORT_DATABASE, payload: message })
    }

    function restoreWorkspace(message) {
        dispatch({ type: types.RESTORE_WORKSPACE, payload: message })
    }

    function sheetPaper(message) {
        dispatch({ type: types.SHEET_PAPER, payload: message })
    }

    function loadCounter(message) {
        dispatch({ type: types.LOAD_COUNTER, payload: message })
    }

    function loadCounterProcessing(message) {
        dispatch({ type: types.LOAD_COUNTER_PROCESSING, payload: message })
    }

    function blur(message) {
        dispatch({ type: types.BLUR, payload: message })
    }
    
    function focus(message) {
        dispatch({ type: types.FOCUS, payload: message })
    }
    
    function mouseDown(evt) {
        dispatch({ type: types.MOUSE_DOWN, payload: evt });
    }

    function mouseOutCounterArea(evt) {
        dispatch({ type: types.MOUSE_OUT_COUNTER_AREA, payload: evt });
    }

    function mouseUp(evt) {
        dispatch({ type: types.MOUSE_UP, payload: evt });
    }

    function mouseClick(evt) {
        dispatch({ type: types.MOUSE_CLICK, payload: evt });
    }

    function mouseDblClick(evt) {
        dispatch({ type: types.MOUSE_DOUBLE_CLICK, payload: evt });
    }

    function returnKey(evt) {
        dispatch({ type: types.RETURN_KEY, payload: evt });
    }

    function keyUp(evt) {
        dispatch({ type: types.KEY_UP, payload: evt });
    }

    function windowWidthHeight(evt) {
        dispatch({ type: types.WINDOW_WIDTH_HEIGHT, payload: evt })
    }

    function counterDrawWidth(evt) {
        dispatch({ type: types.COUNTER_DRAW_WIDTH, payload: evt })
    }

    function addErrorMessages(message) {
        dispatch({ type: types.ERROR_MESSAGES, payload: message })
    }

    function clearErrorMessages() {
        dispatch({ type: types.CLEAR_ERROR_MESSAGES, payload: [] })
    }

    function colorPicker(evt) {
        dispatch({ type: types.COLOR_PICKER, payload: evt })
    }

    function overlay(evt) {
        dispatch({ type: types.OVERLAY, payload: evt })
    }

    function overlayClick(evt) {
        dispatch({ type: types.OVERLAY_CLICK, payload: evt })
    }

    function dataDisplay(evt) {
        dispatch({ type: types.DATA_DISPLAY, payload: evt })
    }

    function installView(evt) {
        dispatch({ type: types.INSTALL_VIEW, payload: evt })
    }

    function addLayer(evt) {
        dispatch({ type: types.ADD_LAYER, payload: evt })
    }

    function layerUpdate(evt) {
        dispatch({ type: types.LAYER_UPDATE, payload: evt })
    }

    function savedCounters(evt) {
        dispatch({ type: types.SAVED_COUNTERS, payload: evt })
    }

    function savedCountersRemove(evt) {
        dispatch({ type: types.SAVED_COUNTERS_REMOVE, payload: evt })
    }

    function savedSheets(evt) {
        dispatch({ type: types.SAVED_SHEETS, payload: evt })
    }

    function savedSheetsRemove(evt) {
        dispatch({ type: types.SAVED_SHEETS_REMOVE, payload: evt })
    }

    function activeLayerValues(evt) {
        dispatch({ type: types.ACTIVE_LAYER_VALUES, payload: evt })
    }

    function activeLayerValuesFront(evt) {
        dispatch({ type: types.ACTIVE_LAYER_VALUES_FRONT, payload: evt })
    }

    function activeLayerValuesRear(evt) {
        dispatch({ type: types.ACTIVE_LAYER_VALUES_REAR, payload: evt })
    }

    function activeLayerValuesRemove(evt) {
        dispatch({ type: types.ACTIVE_LAYER_VALUES_REMOVE, payload: evt })
    }

    function activeLayerValuesReset(evt) {
        dispatch({ type: types.ACTIVE_LAYER_VALUES_RESET, payload: evt })
    }

    function svgs(evt) {
        dispatch({ type: types.SVGS, payload: evt })
    }

    function svgsAdd(evt) {
        dispatch({ type: types.SVGS_ADD, payload: evt })
    }

    function svgsRemove(evt) {
        dispatch({ type: types.SVGS_REMOVE, payload: evt })
    }

    function fonts(evt) {
        dispatch({ type: types.FONTS, payload: evt })
    }

    function dexie(message) {
        dispatch({ type: types.DEXIE, payload: message })
    }

    function draggingLayer(message) {
        dispatch({ type: types.DRAGGING_LAYER, payload: message })
    }

    function moveCounterToSheet(message) {
        dispatch({ type: types.MOVE_COUNTER_TO_SHEET, payload: message })
    }

    function cloneCounterToSlot(message) {
        dispatch({ type: types.CLONE_COUNTER_TO_SLOT, payload: message })
    }

    function sheetSettings(message) {
        dispatch({ type: types.SHEET_SETTINGS, payload: message })
    }

    function slots(evt) {
        dispatch({ type: types.SLOTS, payload: evt })
    }

    function counterClear(evt) {
        dispatch({ type: types.COUNTER_CLEAR, payload: evt })
    }

    function sheetSave(evt) {
        dispatch({ type: types.SHEET_SAVE, payload: evt })
    }

    function sheetSaved(evt) {
        dispatch({ type: types.SHEET_SAVED, payload: evt })
    }

    function activateLayer(evt) {
        dispatch({ type: types.ACTIVATE_LAYER, payload: evt })
    }

    return {
        customImageSvgKeys,
        customSvgSvgKeys,
        lastSheetDisplayWidth,
        storageUsage,
        counterRedrawn,
        counterSideActive,
        counterSideActiveHistoryFront,
        counterSideActiveHistoryRear,
        sheetSideActive,
        threeDeeViewActive,
        counterLoadFromSheet,
        counterLoadFromApp,
        counterLoadFromFile,
        loadSheet,
        windowResize,
        counterAreaMenuState,
        duplicatedLayerValuesSetup,
        topMenuView,
        scrollTop,
        scrollLeft,
        layersOpen,
        layersEffects,
        layoutInline,
        restoreCounterState,
        browser,
        os,
        help,
        popupInfoActive,
        restoreDatabase,
        importDatabase,
        processImportDatabase,
        restoreWorkspace,
        sheetPaper,
        loadCounter,
        loadCounterProcessing,
        blur,
        focus,
        mouseDown,
        mouseOutCounterArea,
        mouseUp,
        mouseClick,
        mouseDblClick,
        returnKey,
        keyUp,
        windowWidthHeight,
        counterDrawWidth,
        addErrorMessages,
        clearErrorMessages,
        colorPicker,
        overlay,
        overlayClick,
        dataDisplay,
        installView,
        layers,
        addLayer,
        layerUpdate,
        savedCounters,
        savedCountersRemove,
        savedSheets,
        savedSheetsRemove,
        activeLayerValues,
        activeLayerValuesFront,
        activeLayerValuesRear,
        activeLayerValuesRemove,
        activeLayerValuesReset,
        svgs,
        svgsAdd,
        svgsRemove,
        fonts,
        dexie,
        draggingLayer,
        moveCounterToSheet,
        cloneCounterToSlot,
        sheetSettings,
        slots,
        counterClear,
        sheetSave,
        sheetSaved,
        activateLayer,
    };
};