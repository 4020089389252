import React, { useState, useEffect } from 'react'
import './StorageUsage.scss'

const StorageUsage = ({storageUsage, dexieActivity}) => {
    const [showUsage, setShowUsage] = useState(false)
    const [timer, setTimer] = useState(-1)

    useEffect(() => {
        return () => {
            if (timer) {
                clearTimeout(timer)
            }
        };
    }, []) // eslint-disable-line react-hooks/exhaustive-deps


    useEffect(() => {
        if (!dexieActivity) {
            if (timer) {
                clearTimeout(timer)
            }
            let _timer = setTimeout(() => {
                setShowUsage(true)
                setTimer(null)
            }, 400)
            setTimer(_timer)
        } else {
            if (timer) {
                clearTimeout(timer)
                setTimer(null)
            }
            setShowUsage(false)
        }
    }, [dexieActivity]) // eslint-disable-line react-hooks/exhaustive-deps

    return <div className={!showUsage ? 'storage-usage hide' : 'storage-usage show'}>
              <div>storage usage <span>{storageUsage.percentage}</span></div>
            </div>
}
export default StorageUsage;

