import React from 'react'
import Menu from '../Menu/Menu'
import StorageUsage from '../StorageUsage/StorageUsage'
import DexieActivityIcon from '../DexieActivityIcon/DexieActivityIcon'
import './TopBar.scss'

const TopBar = ({storageUsage, dexieActivity}) => {
    return <div className="top-bar">
              <Menu />
              <StorageUsage storageUsage={storageUsage} dexieActivity={dexieActivity} />
              <DexieActivityIcon dexieActivity={dexieActivity} />
            </div>
}
export default TopBar;