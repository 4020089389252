import React, { useState, useEffect, useContext } from 'react'
import Snap from 'snapsvg-cjs'
import { useSignal } from "@preact/signals-react"
import { StoreContext } from "../../context/StoreContext"
import Utility from "../../objects/Utility"
import './ViewCustoms.scss'

const ViewCustoms = ({ mode, optionalInfo }) => {
    const { state, actions } = useContext(StoreContext)
    const [localCustomSvgs, setLocalCustomSvgs] = useState([])
    const [reload, setReload] = useState(false)
    const [previousMode, setPreviousMode] = useState(null)
    const signalSvgsCount = useSignal(0)
    const [optionalInfoTimer, setOptionalInfoTimer] = useState(null)

    useEffect(() => {
        return (() => {
            if (optionalInfoTimer) {
                clearTimeout(optionalInfoTimer)
            }
        })
    }, [optionalInfoTimer])

    const createLocalSvgs = () => {
        let customSvgsLayer = null
        if (mode === 'images') {
            customSvgsLayer = state.layers.find(csl => csl.layerName === 'custom images')
        }
        if (mode === 'svgs') {
            customSvgsLayer = state.layers.find(csl => csl.layerName === 'custom svgs')
        }
        if (customSvgsLayer) {
            let input = customSvgsLayer.inputs.find(csl => csl.named === 'svgKey')
            let _svgs = state.svgs.filter(svg => input.list.includes(svg.svgKey))

            let generatedCustomSvgs = generateCustomSvgs(_svgs)
            setLocalCustomSvgs(generatedCustomSvgs)
        }
    }

    const generateCustomSvgs = svgs => {
        return svgs.map(cs => {
            let hashes = Object.keys(state.savedCounters)
            let usedInCountersArr = []
            let usedInCounters
            let svgKeys = []
            hashes.forEach(hash => {
                if (mode === 'svgs') {
                    svgKeys = state.savedCounters[hash]['customSvgKeys']
                }
                if (mode === 'images') {
                    svgKeys = state.savedCounters[hash]['customImageSvgKeys']
                }
                if (svgKeys.includes(cs.svgKey)) {
                    usedInCountersArr.push(state.savedCounters[hash].name)
                }
            })
            if (usedInCountersArr.length > 0) {
                usedInCounters = usedInCountersArr.join(', ')
            }

            // check for slots
            let checkSlots = state.slots.filter(sl => sl.counterState !== null)
            let usedInSlotsArr = []
            let usedInSlots = ''
            checkSlots.forEach(slot => {
                let cpackage = slot.counterState.package
                if (cpackage) {
                    if (cpackage.customImageSvgKeys.includes(cs.svgKey)) {
                        usedInSlotsArr.push(slot.number + 1)
                    }
                    if (cpackage.customSvgKeys.includes(cs.svgKey)) {
                        usedInSlotsArr.push(slot.number + 1)
                    }

                }
            })
            if (usedInSlotsArr.length > 0) {
                usedInSlots = usedInSlotsArr.join(', ')
            }

            return { ...cs, message: { text: '', color: '' }, svgOrigName: cs.svgName, usedInCounters: usedInCounters, usedInSlots }
        })
    }


    useEffect(() => {
        if (mode === 'images' || mode === 'svgs') {

            if (previousMode === null) {
                createLocalSvgs()
            }
            else {
                if (previousMode !== mode) {
                    setLocalCustomSvgs([])
                    setReload(true)
                }
            }

            setPreviousMode(mode)
        }

    }, [mode])  // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        if (reload) {
            setReload(false)
            createLocalSvgs()
        }
    }, [reload]) // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        if (localCustomSvgs) {
            localCustomSvgs.forEach(svg => {
                const svgId = 'viewSvg_' + svg.svgKey
                const paperTarget = Snap("#" + svgId);
                paperTarget.clear()
                let svgCode = svg.svgCode

                // is there a viewBox setting?
                if (mode === 'svgs') {
                    if (svg.viewBox) {
                        paperTarget.attr({ viewBox: svg.viewBox })
                    }
                    // change the ids to avoid clashes
                    //const prependText = Utility.randomString(5)
                    let { alteredString } = alterIds(svgCode)
                    svgCode = alteredString
                }
                let parsed = Snap.parse(svgCode)

                paperTarget.append(parsed)
            })

            signalSvgsCount.value = localCustomSvgs.length

            if (optionalInfoTimer) {
                clearTimeout(optionalInfoTimer)
            }
            let _optionalInfoTimer = setTimeout(() => {
                if (signalSvgsCount.value === 0) {
                    optionalInfo('There are no ' + mode)
                }
                if (signalSvgsCount.value === 1) {
                    const singular = mode === 'images' ? 'image' : 'svg'
                    optionalInfo('There is 1 ' + singular)
                }
                if (signalSvgsCount.value > 1) {
                    optionalInfo('There are ' + signalSvgsCount.value + ' ' + mode)
                }
                setOptionalInfoTimer(null)
            }, 200)
            setOptionalInfoTimer(_optionalInfoTimer)


        }
    }, [localCustomSvgs])  // eslint-disable-line react-hooks/exhaustive-deps

    const alterIds = str => {
        let idsArray = []
        let alteredStr = str
        let randomStr = Utility.randomString(5) + '_'
        function findIds(str) {
            str = str.replace(/\s\s+/g, ' ');
            str = str.replace(/id =/ig, 'id=');
            str = str.replace(/id= \"/ig, 'id=\"'); // eslint-disable-line no-useless-escape
            const strPattern = "id\=\"\\S+\""; // eslint-disable-line no-useless-escape
            const pattern = new RegExp(strPattern, 'g');
            let matches;
            while ((matches = pattern.exec(str)) !== null) {
                idsArray.push(matches[0])
            }
        }
        findIds(alteredStr)
        idsArray = idsArray.map(id => id.replaceAll('"', ''))
        idsArray = idsArray.map(hr => hr.replace('id=', ''))
        idsArray = idsArray.map(hr => hr.replace('>', ''))
        let uniqueIds = [...new Set(idsArray)]
        uniqueIds.forEach(id => {
            alteredStr = alteredStr.replaceAll(id, randomStr + id)
        })

        return { alteredString: alteredStr, prepend: randomStr }
    }

    const changeName = evt => {
        let svgKey = evt.target.id
        svgKey = Number(svgKey.replace('key_', ''))
        const localSvgObj = localCustomSvgs.find(lcs => lcs.svgKey === svgKey)
        if (localSvgObj) {
            localSvgObj.svgName = evt.target.value
            setLocalCustomSvgs([...localCustomSvgs])
        }
    }

    const submitRename = svgKey => {
        let localSvgName = ''
        let stateSvgName = ''
        const localSvgObj = localCustomSvgs.find(lcs => lcs.svgKey === svgKey)
        if (localSvgObj) {  // eslint-disable-line react-hooks/exhaustive-deps
            localSvgName = localSvgObj.svgName
            stateSvgName = localSvgObj.svgOrigName
        }

        if (localSvgName.trim().toLowerCase() === stateSvgName.trim().toLowerCase()) {
            localSvgObj.message = { text: 'new name can\'t be same', color: 'red' }
            setLocalCustomSvgs([...localCustomSvgs])
            return
        }

        let localSvgNameTest1 = localSvgName.trim()
        let stateSvgNameTest2 = stateSvgName.trim()
        localSvgNameTest1 = localSvgNameTest1.toLowerCase().replace(/\s\s+/g, ' ')
        stateSvgNameTest2 = stateSvgNameTest2.toLowerCase().replace(/\s\s+/g, ' ')
        if (localSvgNameTest1 === stateSvgNameTest2) {
            localSvgObj.message = { text: 'new name can\'t be same', color: 'red' }
            setLocalCustomSvgs([...localCustomSvgs])
            return
        }

        if (localSvgName.trim().toLowerCase() === stateSvgName.trim().toLowerCase()) {
            localSvgObj.message = { text: 'new name can\'t be same', color: 'red' }
            setLocalCustomSvgs([...localCustomSvgs])
            return
        }

        if (checkSubmittedNameIsOk(localSvgObj)) {

            let layers = [...state.layers]

            let dupeSvgs = [...state.svgs]
            let stateSvgObj = dupeSvgs.find(ssvg => ssvg.svgKey === svgKey)
            if (stateSvgObj) {
                stateSvgObj.svgName = localSvgName
                localSvgName = localSvgName.trim().replace(/\s\s+/g, ' ')
                localSvgName = localSvgName.replaceAll(' ', '_')
                if (mode === 'svgs') {
                    stateSvgObj.svgCode = stateSvgObj.svgCode.replace(' id="' + localSvgObj.svgCodeId + '"', ' id="' + localSvgObj.uniquePrepend + localSvgName + '"')
                }
                if (mode === 'images') {
                    localSvgName = Utility.safeSvgId(localSvgName)
                    stateSvgObj.svgCode = stateSvgObj.svgCode.replace(' id="' + localSvgObj.svgCodeId + '"', ' id="' + localSvgName + '"')
                }
                actions.svgs(dupeSvgs)
                actions.layers(JSON.parse(JSON.stringify(layers))) // to trigger the layers menu to show the new svg name
            }
        }
    }

    const checkSubmittedNameIsOk = localSvgObj => {
        // check if name is okey-dokey
        let localSvgName = localSvgObj.svgName.trim()  // eslint-disable-line react-hooks/exhaustive-deps

        let regexResult = localSvgName.match(/[-_)( a-zA-Z0-9]+$/)
        if (regexResult === null) {
            localSvgObj.message = { text: 'name has invalid characters.', color: 'red' }
            setLocalCustomSvgs([...localCustomSvgs])
            return false
        }

        if (regexResult && regexResult.index > 0) {
            localSvgObj.message = { text: 'name has invalid characters.', color: 'red' }
            setLocalCustomSvgs([...localCustomSvgs])
            return false
        }

        if (!Utility.checkNameIsAvailable(state.svgs, localSvgName)) {
            localSvgObj.message = { text: 'That name is already taken.', color: 'red' }
            setLocalCustomSvgs([...localCustomSvgs])
            return false
        }


        if (localSvgName.length < 2) {
            localSvgObj.message = { text: 'name is too short.', color: 'red' }
            setLocalCustomSvgs([...localCustomSvgs])
            return false
        }

        if (localSvgName.length > 32) {
            localSvgObj.message = { text: 'name is too long.', color: 'red' }
            setLocalCustomSvgs([...localCustomSvgs])
            return false
        }

        // if we get down here then it seems to be ok
        return true
    }

    useEffect(() => {
        let customSvgsLayer = null
        if (mode === 'images') {
            customSvgsLayer = state.layers.find(csl => csl.layerName === 'custom images')
        }
        if (mode === 'svgs') {
            customSvgsLayer = state.layers.find(csl => csl.layerName === 'custom svgs')
        }
        if (customSvgsLayer) {
            let input = customSvgsLayer.inputs.find(csl => csl.named === 'svgKey')
            let _svgs = state.svgs.filter(svg => input.list.includes(svg.svgKey))
            let generatedCustomSvgs = generateCustomSvgs(_svgs)
            setLocalCustomSvgs(generatedCustomSvgs)
        }
    }, [state.svgs, state.layers]) // eslint-disable-line react-hooks/exhaustive-deps

    const deleteSvg = evt => {
        let svgKey = Number(evt.target.id.replace('delete_', ''))

        // see if the svg is currently used in a saved counter

        let savedCounters = state.savedCounters
        let hashes = Object.keys(savedCounters)
        let usedInCounterNames = []
        hashes.forEach(hash => {
            let svgKeys = savedCounters[hash]['customSvgKeys']
            if (svgKeys.includes(svgKey)) {
                usedInCounterNames.push(savedCounters[hash].name)
            }
        })
        if (usedInCounterNames.length > 0) {
            setLocalCustomSvgs([...localCustomSvgs])
        }

        let checkSlots = state.slots.filter( ss => ss.counterState !== null )
        if (checkSlots.length > 0) {
            checkSlots.forEach( cs => {
                let imageKeys = cs.counterState.package.customImageSvgKeys
                let svgKeys = cs.counterState.package.customSvgKeys
                if( imageKeys.includes( svgKey )) {
                   return
                }
                if( svgKeys.includes( svgKey )) {
                    return
                 }
            })
        }

        let stateSvgs = state.svgs.filter(ssvg => ssvg.svgKey !== svgKey)
        if (mode === 'images') {
            let newList = state.customImageSvgKeys.filter(csvg => csvg.svgKey !== svgKey)
            actions.customImageSvgKeys(newList)
        }
        if (mode === 'svgs') {
            let newList = state.customSvgSvgKeys.filter(csvg => csvg.svgKey !== svgKey)
            actions.customSvgSvgKeys(newList)
        }
        actions.svgs([...stateSvgs])

        // remove the svgKeys from the input.list of custom layers and activeLayerValues
        let layers = [...state.layers]
        let layerKeysToRemoveFromActiveLayerValues = []
        layers.forEach(lr => {
            let inputs = lr.inputs
            let customSvgsInput = inputs.find(input => input.named === 'svgKey')
            if (customSvgsInput && customSvgsInput.list.length > 0) {
                // return the same list, except for where the key equals the key we are deleting.
                customSvgsInput.list = customSvgsInput.list.filter(_svgKey => Number(_svgKey) !== Number(svgKey))
                if (customSvgsInput.list.length === 0) {
                    // if the list is now empty, we need to ensure the layer is hidden
                    lr.layerHidden = 1  // eslint-disable-line react-hooks/exhaustive-deps
                    lr.layerActive = 0
                }
            }

            // ok. We removed the svgKeyToDelete from the list. Now - did this layer have this svgKey set and was it active?
            let requiredSvgKey = lr.layerActiveRequiredInputKey
            // did it point to the svgKeyToDelete?
            if (state.activeLayerValues[lr.layerKey + '_' + requiredSvgKey] === svgKey) {
                // remove references to this layer from activeLayerValues
                layerKeysToRemoveFromActiveLayerValues.push(lr.layerKey)
                lr.layerActive = 0
            }
        })
        const paperTarget = Snap('#viewSvg_' + svgKey) // the svg seems to stay in the DOM, even after the original svg tag gets removed.
        if (paperTarget) {
            paperTarget.clear()
        }
        actions.activeLayerValuesRemove(layerKeysToRemoveFromActiveLayerValues)
        actions.layers(JSON.parse(JSON.stringify(layers)))
    }

    const focusName = evt => {
        let id = evt.target.id
        let svgKey = Number(id.replace('key_', ''))

        const localSvgObj = localCustomSvgs.find(lcs => lcs.svgKey === svgKey)
        if (localSvgObj) {
            localSvgObj.message = { text: '', color: '' }
            setLocalCustomSvgs([...localCustomSvgs])
        }
    }
    const onTextAreaChange = evt => {
        ;
    }

    //return ( installMode ? <InstallCustomImage /> :
    return (
        <div className="view-customs">
            {localCustomSvgs.length === 0 ?
                <div className="no-custom">No custom {mode} have been found. Try installing one.
                </div>
                : ''
            }

            {localCustomSvgs.map((svg, index) =>
                <div className="custom-item" key={index}>

                    <div className="display">
                        <svg id={'viewSvg_' + svg.svgKey} width="100%" height="100%" />
                    </div>
                    <div className="form-name-container">
                        <div className="name-div">
                            name: <span>{svg.svgOrigName}</span>
                        </div>
                        <div className="message">

                            <div className={svg.message.text ? 'message' : 'display-none'} style={{ color: svg.message.color }}>{svg.message.text}</div>

                        </div>
                        <div className="rename-div">

                            <div className="input-control">
                                <span>rename:</span>
                                <input type="text"
                                    className={svg.message && (svg.message.color === 'red' || svg.svgName.length === 0) ?
                                        'warning' :
                                        ''}
                                    id={'key_' + svg.svgKey}
                                    value={svg.svgName}
                                    onChange={changeName}
                                    onFocus={focusName}
                                />
                            </div>
                        </div>
                        <div className="submit-div">
                            <div className="actions">
                                <button type="submit"
                                    className={svg.message.color === 'red' || svg.svgName.length === 0 ? 'standard-button disabled' : 'standard-button blue'}
                                    disabled={svg.message.color === 'red' || svg.svgName.length === 0}
                                    onClick={() => submitRename(svg.svgKey)}>rename</button>
                                {svg.usedInCounters || svg.usedInSlots ?
                                    <button id={`delete_${svg.svgKey}`} className="standard-button disabled" onClick={null}>can not delete</button>
                                    :
                                    <button id={`delete_${svg.svgKey}`} className="standard-button red" onClick={deleteSvg}>Delete</button>
                                }
                            </div>
                        </div>
                    </div>

                    <div className="metadata">
                        <div className="size">svg key: <span>{svg.svgKey}</span> svg size: <span>{Utility.getBytes(svg.svgCode.length)}</span></div>

                        <div className={svg.usedInCounters ? 'used-in' : 'display-none'}>Used in counters:<span>{svg.usedInCounters}</span></div>
                        <div className={svg.usedInSlots ? 'used-in' : 'display-none'}>Used in sheet numbers:<span>{svg.usedInSlots}</span></div>

                        {svg.svgCode.length > 1000000 ? <div className="size-warning">
                            This svg file is very large. You may want to compress
                            it so your database doesn't get overfull.
                            A good online resource to reduce svg filesize
                            is <a href="https://www.iloveimg.com/" target="_blank" rel="noopener noreferrer">ILoveImg.com</a>
                        </div>
                            : null}

                        <div className="code-display">
                            <div>code:</div>
                            <textarea id={`codeDisplay${svg.hash}`} spellCheck="false" onChange={onTextAreaChange} value={svg.svgCode}></textarea>
                        </div>
                    </div>
                </div>
            )}

            {/* {state.svgs.filter((svg, index) => svg.uniquePrepend && !svg.svgCode.includes('custom-image')).length === 0 ?
                <div className="no-custom-svgs">No custom svgs have been found. Try installing some.</div> : null} */}
        </div>
    );

}
export default ViewCustoms
