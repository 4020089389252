import React, { useState, useEffect, useContext, useRef } from 'react'
import { StoreContext } from "../../context/StoreContext"
import CloseX from "../CloseX/CloseX"
import './PopupInfo.scss'

const PopupInfo = () => {
    const controller = new AbortController();
    const { signal } = controller;
    const { state, actions } = useContext(StoreContext)
    const [selfActivatedOverlay, setSelfActivatedOverlay] = useState(false)
    const [position, setPosition] = useState([-999, -999])
    const [zIndex, setZIndex] = useState(0)
    const [title, setTitle] = useState('')
    const popupInfoRef = useRef(null)
    const dragRef = useRef(null)
    const [checkOffscreen, setCheckOffscreen] = useState(false)

    const conversionChart1 = [
        { mm: 6, inch: '', num: 1, den: 4 },
        { mm: 8, inch: '', num: 5, den: 16 },
        { mm: 10, inch: '', num: 3, den: 8 },
        { mm: 11, inch: '', num: 7, den: 16 },
        { mm: 13, inch: '', num: 1, den: 2 },
        { mm: 14, inch: '', num: 9, den: 16 },
        { mm: 16, inch: '', num: 5, den: 8 }]

    const conversionChart2 = [
        { mm: 19, inch: '', num: 3, den: 4 },
        { mm: 21, inch: '', num: 13, den: 16 },
        { mm: 22, inch: '', num: 7, den: 8 },
        { mm: 24, inch: '', num: 15, den: 16 },
        { mm: 25, inch: 1, num: '', den: '' },
        { mm: 29, inch: 1, num: 1, den: 8 },
        { mm: 32, inch: 1, num: 1, den: 4 }]

    useEffect(() => {
        popupInfoRef.current.addEventListener('mouseup', function (e) {
            e.stopPropagation();
        }, { signal });
    }, []) // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        if (state.popupInfoActive) {
            let title = state.popupInfoActive.target.getAttribute("data-popup")
            setTitle(title)
            actions.overlay(true)
            setSelfActivatedOverlay(true)
            let x = state.popupInfoActive.pageX
            let y = state.popupInfoActive.pageY // 27
            let positionAtX = x + 0 //state.scrollLeft
            let positionAtY = y + 0 //state.scrollTop
            setPosition([positionAtX, positionAtY])
            setZIndex(9999)
            setCheckOffscreen(true)
        }
    }, [state.popupInfoActive]) // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {

        function dragElement(elmnt) {
            var pos1 = 0, pos2 = 0, pos3 = 0, pos4 = 0;
            elmnt.onmousedown = dragMouseDown;
            elmnt.onmouseup = closeDragElement;


            function dragMouseDown(e) {
                let dragger = e.target.getAttribute('data-dragger')
                if (!dragger) {
                    return
                }
                e = e || window.event;
                e.preventDefault();
                // get the mouse cursor position at startup:
                pos3 = e.clientX;
                pos4 = e.clientY;
                document.onmouseup = closeDragElement;
                // call a function whenever the cursor moves:
                document.onmousemove = elementDrag;
            }

            function elementDrag(e) {
                e = e || window.event;
                e.preventDefault();
                // calculate the new cursor position:
                pos1 = pos3 - e.clientX;
                pos2 = pos4 - e.clientY;
                pos3 = e.clientX;
                pos4 = e.clientY;
                // set the element's new position:
                elmnt.style.top = (elmnt.offsetTop - pos2) + "px";
                elmnt.style.left = (elmnt.offsetLeft - pos1) + "px";
            }

            function closeDragElement() {
                /* stop moving when mouse button is released:*/
                document.onmouseup = null;
                document.onmousemove = null;
            }
        }

        if (checkOffscreen) {
            let targetBbox = popupInfoRef.current.getBoundingClientRect()
            let rightSide = targetBbox.right + 20
            if (rightSide > window.innerWidth) {
                let adjustX = window.innerWidth - rightSide
                setPosition([position[0] + adjustX, position[1]])
            }

            dragElement(document.getElementById("popupInfo"))
        }

    }, [checkOffscreen]) // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        if (selfActivatedOverlay) {
            setSelfActivatedOverlay(false)
            close()
        }
    }, [state.windowWidthHeight]) // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        if (state.overlay === false && selfActivatedOverlay) {
            setSelfActivatedOverlay(false)
            close()
        }
    }, [state.overlay]) // eslint-disable-line react-hooks/exhaustive-deps

    const close = () => {
        actions.popupInfoActive(null)
        actions.overlay(false)
    }

    return (
        <div ref={popupInfoRef} id="popupInfo" className="popup-info" style={{ left: position[0] + 'px', top: position[1] + 'px', zIndex: zIndex }}>
            <div className="container">
                <div className="top">
                    <div>{title}</div>
                    <div ref={dragRef} data-dragger="drag" />
                    <div className="close"><CloseX close={close} /></div>
                </div>


                {title === 'Counters across and down' ?
                    <div className="content-area counters-across-and-down">
                        <p>You can set the number of counters you want in each row and column.
                        </p>
                        <p>The maximum amount you can set are limited by the counter size,
                            counter margins, page margins, and printable area settings.
                        </p>
                        <p>You can set the app to automatically fit as many as it can across and down by clicking on
                            the "maximum that fits" checkbox. If you manually set the counters across or down, or
                            load in a sheet, the "maximum that fits" checkbox may get turned off automtically.
                        </p>
                        <p>
                            If you set the number of counters and rows so that it can only fit a subset of your counters,
                            then those counters that no longer fit on the sheet will not be displayed. They will still
                            exist though, if you reduce the counters across/down settings, or any of the other counter
                            and page and printable area settings enough, those "off sheet" counters may be able to get
                            onto the sheet.
                        </p>
                    </div>
                    : null}


                {title === 'Counter size' ?
                    <div className="content-area counter-size">
                        <p>You can select the size of counters that you want to be printed out on your sheet.
                            In this version of the app, you can only choose one size for all the counters
                            on the sheet.
                        </p>
                        <p>The app uses metric mm measurements, so you'll need to do mm to inch conversions
                            to know what the "Imperial" measurement (inches) are.
                        </p>
                        <p>The select drop down sheet will give you a range of common counter sizes you can
                            select from (and also provide the inches value).
                        </p>
                        <p>You can select a custom value, if you don't want any options in the drop down
                            selection. You can select from 6mm to 100mm.
                        </p>
                        <p>If your counters do not come out to the right size when you print them out, you'll
                            need to check the "printable area" settings. They need to be correct, in order
                            for the counters to print out at the correct size.</p>
                        <p>Some conversions (approximate):

                            <table className="table-main">
                                <tbody>
                                    <tr>
                                        <td>
                                            <table className="table-sub">
                                                <tbody>
                                                    <tr>
                                                        <th>mm</th><th>inches</th>
                                                    </tr>
                                                    {conversionChart1.map((cn, index) => <tr key={index}><td><span className="td-span">{cn.mm}</span><span className="mm">mm</span></td>
                                                        <td className="inch"><span className="inch-value">{cn.inch}</span>
                                                            {cn.num ? <div className="fraction"><span>{cn.num}</span><span>/</span><span>{cn.den}</span></div> : ''
                                                            }
                                                            <span className="dq">"</span>
                                                        </td></tr>)}
                                                </tbody>
                                            </table>
                                        </td>
                                        <td>
                                            <table className="table-sub">
                                                <tbody>
                                                    <tr>
                                                        <th>mm</th><th>inches</th>
                                                    </tr>
                                                    {conversionChart2.map((cn, index) => <tr key={index}><td><span className="td-span">{cn.mm}</span><span className="mm">mm</span></td>
                                                        <td className="inch"><span className="inch-value">{cn.inch}</span>
                                                            {cn.num ? <div className="fraction"><span>{cn.num}</span><span>/</span><span>{cn.den}</span></div> : ''
                                                            }
                                                            <span className="dq">"</span>
                                                        </td></tr>)}
                                                </tbody>
                                            </table>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>

                        </p>
                    </div>
                    : null}

                {title === 'Sheet side' ?
                    <div className="content-area">
                        <p>The sheet has two sides. 
                            When you save a sheet, both sides are saved.
                            Choose which side you want active
                            by clicking front or rear.
                        </p>
                    </div>
                    : null}

                {title === 'Counter margins' ?
                    <div className="content-area counter-margins">
                        <p>You can set the margins that will apply to each side of each counter.
                        </p>
                        <img src="/counter_margins.png" width="450" height="450" alt="counter margins" />
                    </div>
                    : null}


                {title === 'Page margins' ?
                    <div className="content-area page-margins">
                        <p>You can set the margins that will apply around the printable area of the sheet.
                        </p>
                        <img src="/page_margins.png" width="450" height="452" alt="page margins" />
                    </div>
                    : null}


                {title === 'Save sheet' ?
                    <div className="content-area">
                        <p>You can save or export your sheet in a few ways.
                        </p>
                        <p><span>In app:</span> the data needed to store, and restore, your sheet will be stored inside
                            your browser's database. This storage space is not unlimited, so try to store only
                            those sheets you want immediately available for viewing and reloading. Both sides of the
                            sheet are saved when saved in the app.
                        </p>
                        <p><span>To file:</span> the data needed to store, and restore, your sheet will be saved to
                            your computer or device's storage space. You can store many sheets this way,
                            depending on how much free space you have on your device. Data such as fonts
                            used and custom svgs used will be saved with it. You will be able to restore the
                            sheet by loading the file back in. Both sides of the sheet are saved when saved in a file.
                        </p>
                        <p><span>To svg:</span> the sheet will be exported as a svg file. This svg file cannot be used to
                            load the sheet back. Its a one way process. You could open the svg file in most
                            graphics applications, and make any custom changes you may want to do, such as
                            changes that were not possible inside the Snap Counter app. The font data used in
                            your sheet will be included in the svg file, although many svg graphics applications
                            will not read font data due to security/copyright concerns. If this is the case,
                            all the text used in the sheet will be replaced by a default font, which may
                            ruin the look of your counters. Only the side being shown will be exported to the svg.</p>
                        <p><span>To png:</span> the sheet will be exported to a popular graphics format called "png". This
                            png file cannot be used to restore the sheet. As like with the svg export format,
                            you can load the png file into most graphics applications and make any changes you
                            wish. Unlike the svg export, in a png export the fonts used should display correctly.
                            Only the sheet side active will be exported to the png.</p>
                    </div>
                    : null}


                {title === 'Load sheet' ?
                    <div className="content-area">
                        <p>You can load sheets that you have saved previously in the app, or as a file.
                        </p>
                        <p>Sheets that were exported to an image or to an svg file cannot be imported.</p>
                        <p>Sheets that are loaded will come with the sheet settings they had when they
                            were originally saved.</p>
                    </div>
                    : null}






                {title === 'Cutting guides' ?
                    <div className="content-area cutting-guides">
                        <p>You can optionally have cutting guides printed on your sheet.
                        </p>
                        <p>The guides make it clear where you should be cutting to get the counters out.</p>
                        <p>You can have no guides (the default) if you don't need cutting guides to help you.</p>
                        <div className="guides-graphic">
                            <span>corners</span><span>edges</span><span>lines</span>
                            <img src="/cutting_guides.png" width="600" height="221" alt="cutting guides" />
                        </div>
                    </div>
                    : null}

















                {title === 'Printable area' ?
                    <div className="content-area">
                        <p>Most printers do not print from edge to edge. They print
                            on the area inside certain margins along the edges.
                        </p>
                        <p>The size of these margins can vary alot between printers.</p>
                        <div className="float-container">
                            <div className="svg-printable-area">
                                <svg width="320" height="440" viewBox="0 0 160 220" xmlns="http://www.w3.org/2000/svg">
                                    <rect x="0" y="0" width="160" height="220" fill="white" stroke="black" />
                                    <rect x="1" y="1" width="158" height="218" fill="white" />
                                    <rect x="11" y="11" width="138" height="198" fill="#bdd" />
                                    <text x="-20" y="140" className="small"
                                        transform="rotate(-45)"
                                        textAnchor="middle"
                                        fontSize="18px"
                                        fontFamily="ABeeZee, sans-serif" >PRINTABLE AREA</text>
                                    <text x="40" y="8"
                                        fontSize="8px"
                                        fontFamily="ABeeZee, sans-serif"
                                    >non-printable margin</text>

                                    <text x="69" y="-152"
                                        transform="rotate(90)"
                                        fontSize="8px"
                                        fontFamily="ABeeZee, sans-serif"
                                    >non-printable margin</text>


                                    <text x="-150" y="8"
                                        transform="rotate(-90)"
                                        fontSize="8px"
                                        fontFamily="ABeeZee, sans-serif"
                                    >non-printable margin</text>

                                    <text x="40" y="216"
                                        fontSize="8px"
                                        fontFamily="ABeeZee, sans-serif"
                                    >non-printable margin</text>

                                </svg>

                            </div>
                            <span className="wrap-text">
                                <p>It's important to have the correct printable area
                                    set in order for your counters to print out at
                                    the correct size.</p>
                                <p>For example, if you are using
                                    1 inch counters, you want them to print out at 1 inch - but
                                    if you don't have the correct printable area settings, then
                                    your 1" counters may come out at perhaps 1 1/18" or
                                    9/16" inch.
                                </p>
                                <p>To find out your printer's printable area size, you'll
                                    need to refer to the technical documents to your printer,
                                    although many printer manufacturers do not provide that
                                    information in their manuals.</p>
                                <p>You could try to find the info on the manufacturer's
                                    support website. If you find the info, it will either provide the margins that are "not printable",
                                    or the size of the printable area. It may be in inches or metric measurements.
                                </p>
                                <p>If in inches, convert it to mm. If you only have the margins info, then add the top and bottom margins together,
                                    and subtract that from the mm size of the height of the paper or cardstock you are printing
                                    on. And same for the left and right for the width of the paper. For standard printer paper,
                                    the full size is 215.9mm wide and 274.4mm long.
                                </p>
                                <p>If you have a HP printer, you can go to this url to look up the data for your printer:<br />
                                    <a href="https://developers.hp.com/hp-linux-imaging-and-printing/tech_docs/printable_areas"
                                        target="_blank" rel="noopener noreferrer">https://developers.hp.com/hp-linux-imaging-and-printing/tech_docs/printable_areas</a>
                                </p>
                                <p>If you don't have the printable area data, you can try just using the default settings for
                                    printable area, and print some 1 inch counters, then measure them and see if they come out at 1 inch or
                                    not. If not, then you can try adjusting the printable area settings until you get to 1" print outs for
                                    1" counters. The default settings are for my Brother HL-321 printer, with 4.23mm margins all around.</p>
                                <p>This app exports sheets to svg and png assuming you will be using the "print to fit" setting on your printer, which is the default
                                    for most printers. If you want to set the print size yourself, you will need to
                                    load the svg or png image into a graphics application, change the print size, and then be sure your printer
                                    isn't set to "print to fit".</p>
                            </span>

                        </div>
                    </div>
                    : null}

            </div>
        </div>
    );
}
export default PopupInfo

// /*
//                                 <div className="wrap-text">
//                     <p>It's important to have the correct printable area
//                         set in order for your counters to print out at
//                         the correct size.</p>
//                     <p>For example, if you are using
//                         1 inch counters, you want them to print out at 1 inch - but
//                         if you don't have the correct printable area settings, then
//                         your 1" counters may come out at perhaps 1 1/18" or
//                         9/16" inch.
//                     </p>
//                     <p>To find out your printer's printable area size, you'll
//                         need to refer to the technical documents to your printer,
//                         although many printer manufacturers do not provide that
//                         information in their manual.</p>
//                     <p>You could try to find the info on the manufacturer's
//                         support website. If you find the info, it will probably
//                         give you the margins that are not printable. That may
//                         be in inches or metric measurements
//                     </p>

//                 </div>/*