import ApiConnector from './ApiConnector';

const handleApiResponse = (response, resolve, reject) => {
    if (response.hasOwnProperty('error')) {
        reject(response.error);
    } else if (response.hasOwnProperty('success') && response.success.hasOwnProperty('data')) {
        resolve(response.success.data);
    } else {
        reject({ message: 'unknown error' });
    }
};

const handleApiError = (error, reject) => {
    console.warn('got error: ', error);
    reject(error);
};

let ApiHelper = {
    contact: (params) => {
        return new Promise((resolve, reject) => {
            ApiConnector.call('get', '', '', null, null).then(
                (response) => handleApiResponse(response, resolve, reject),
                (error) => handleApiError(error, reject)
            );
        });
    },

    getStore: (params) => {
        return new Promise((resolve, reject) => {
            ApiConnector.call('post', '' + params.uri, params, null).then(
                (response) => handleApiResponse(response, resolve, reject),
                (error) => handleApiError(error, reject)
            );
        });
    },

    getSheetSettings: (params) => {
        return new Promise((resolve, reject) => {
            ApiConnector.call('post', 'wargalleyservice/sheetSettings/get', params, null).then(
                (response) => handleApiResponse(response, resolve, reject),
                (error) => handleApiError(error, reject)
            );
        });
    },

    getLayerKeys: (params) => {
        return new Promise((resolve, reject) => {
            ApiConnector.call('post', 'wargalleyservice/layer/get/keys', params, null).then(
                (response) => handleApiResponse(response, resolve, reject),
                (error) => handleApiError(error, reject)
            );
        });
    },

    getLayers: (params) => {
        return new Promise((resolve, reject) => {
            ApiConnector.call('post', 'wargalleyservice/layer/cache2', params, null).then(
                (response) => handleApiResponse(response, resolve, reject),
                (error) => handleApiError(error, reject)
            );
        });
    },

    getInputTypes: (params) => {
        return new Promise((resolve, reject) => {
            ApiConnector.call('post', 'wargalleyservice/inputType/get', params, null).then(
                (response) => handleApiResponse(response, resolve, reject),
                (error) => handleApiError(error, reject)
            );
        });
    },

    getSvg: (params) => {
        return new Promise((resolve, reject) => {
            ApiConnector.call('post', 'wargalleyservice/svg/get', params, null).then(
                (response) => handleApiResponse(response, resolve, reject),
                (error) => handleApiError(error, reject)
            );
        });
    },

    getSvgs: () => {
        return new Promise((resolve, reject) => {
            ApiConnector.call('post', 'wargalleyservice/svg/get', {}, null).then(
                (response) => handleApiResponse(response, resolve, reject),
                (error) => handleApiError(error, reject)
            );
        });
    },

    getFonts: () => {
        return new Promise((resolve, reject) => {
            ApiConnector.call('post', 'wargalleyservice/font/get', {}, null).then(
                (response) => handleApiResponse(response, resolve, reject),
                (error) => handleApiError(error, reject)
            );
        });
    },

    getFontSrc: (params) => {
        return new Promise((resolve, reject) => {
            ApiConnector.call('post', 'wargalleyservice/font/src/get', params, null).then(
                (response) => handleApiResponse(response, resolve, reject),
                (error) => handleApiError(error, reject)
            );
        });
    },

    fontPost: (params) => {
        return new Promise((resolve, reject) => {
            ApiConnector.call('post', 'wargalleyservice/font/post', params, null).then(
                (response) => handleApiResponse(response, resolve, reject),
                (error) => handleApiError(error, reject)
            );
        });
    },

    checkForUpdate: (params) => {
        return new Promise((resolve, reject) => {
            ApiConnector.call('post', 'wargalleyservice/update/post', params, null).then(
                (response) => {
                    if (response.hasOwnProperty('error')) {
                        reject(response.error);
                    } else if (response.hasOwnProperty('success')) {
                        resolve(response.success);
                    } else {
                        reject({ message: 'unknown error' });
                    }
                },
                (error) => handleApiError(error, reject)
            );
        });
    },
};

export default ApiHelper;