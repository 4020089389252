import React, { useEffect, useContext } from 'react'
import { StoreContext } from "../../context/StoreContext";
import { useSignal } from "@preact/signals-react"
import GridControl from '../GridControl/GridControl'
import GridColorControl from '../GridColorControl/GridColorControl'
import SaveCounterControl from '../SaveCounterControl/SaveCounterControl'
import LoadCounterControl from '../LoadCounterControl/LoadCounterControl'
import MoveToSheetControl from '../MoveToSheetControl/MoveToSheetControl'
import ClearControl from '../ClearControl/ClearControl'
import './CounterAreaMenu.scss'
const CounterAreaMenu = ({ showGrid, gridColor, saveCounter, loadCounter,
    moveToSheet, clearCounter, gridState }) => {
    const { state, actions } = useContext(StoreContext)
    const signalLongname = useSignal(true)
    const signalMoveLongname = useSignal(true)

    useEffect(() => {
        let ele = document.getElementById('counter')
        if (ele) {
            actions.counterDrawWidth(ele.offsetWidth);
        }
    }, []) // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        if (state.counterDrawWidth < 700) {
            signalLongname.value = false
        }
        else {
            signalLongname.value = true
        }
        if (state.counterDrawWidth < 600) {
            signalMoveLongname.value = false
        }
        else {
            signalMoveLongname.value = true
        }
    }, [state.counterDrawWidth]) // eslint-disable-line react-hooks/exhaustive-deps

    return (
        <div className="counter-area-menu">
            <div className="controls">
                <div className="grid-controls">
                    <GridControl showGrid={showGrid} />
                    <GridColorControl gridColor={gridColor} gridState={gridState} />
                </div>
                <div className="control-buttons">
                    <SaveCounterControl saveCounter={saveCounter} longName={signalLongname.value} />
                    <LoadCounterControl loadCounter={loadCounter} longName={signalLongname.value} />
                    <MoveToSheetControl moveToSheet={moveToSheet} longName={signalMoveLongname.value} />
                    <ClearControl clearCounter={clearCounter} />
                </div>
            </div>
        </div>
    )
}

export default CounterAreaMenu